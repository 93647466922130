import React, { useState, useEffect } from "react";
import Category from "./Category";
import CategorySelectorStyles from "../../styles/CategorySelectorStyles";

const CategorySelector = ({
  categories,
  activeCategories,
  setActiveCategories,
  defaults,
}) => {

  const [showAllState, setShowAllState] = useState(false);

  // useEffect(() => {



    
  // },[])
  const handleSelectAll = () => {


    const currentState = showAllState;
    setShowAllState(!currentState);

    if (categories.length === activeCategories.length) {
      setActiveCategories(defaults);
    } else {
      setActiveCategories(categories);
    }
  };

  const CategoryClickHandler = (cat) => {
    if (activeCategories.indexOf(cat) !== -1) {
      const newCats = activeCategories.filter((cur) => cur !== cat);
      setActiveCategories(newCats);
    } else {
      setActiveCategories([...activeCategories, cat]);
    }
  };

  return (
    <CategorySelectorStyles id="cat-filter">
      <h2>FILTER</h2>
      <button className={showAllState ? 'selectall' : 'selectallOff'} tabindex="0" onClick={handleSelectAll} >
        Show All {categories.length === activeCategories.length ? "" : ""}
      </button>
      {categories.map((cat) => (
        <Category
          cat={cat}
          key={cat}
          isActive={activeCategories.indexOf(cat) !== -1}
          clickEvent={CategoryClickHandler}
        />
      ))}
    </CategorySelectorStyles>
  );
};

export default CategorySelector;
