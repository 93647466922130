import styled from "styled-components";

const ListStyles = styled.div`
font-family: "proxima-nova",sans-serif;
position: fixed;
font-size: 16px;
line-height:18px;
color: #e5007d;
width: 230px;
height: 100%;
background-color:${(props) => props.theme.colours.secondary};
left: 0;
top: 0;
text-transform: capitalize;
margin-top: 0px;
padding-top: 220px;
margin-bottom: 100px;
font-weight:600;
filter: drop-shadow(0 0 0.5rem #00000050);
transition: left 0.5s;
.search-tab-open {
	display:none;
}

&.closed {
	left:-232px;

	.search-tab-close {
		display:none;
	}
	.search-tab-open {
		display:block;
	}
}

.search-tab-open{
	position:relative;
	width:180px;
	height:41px;
	text-align:left;
	background-color:${(props) => props.theme.colours.secondary};
	color: #e5007d;
	top: -50%;
    right: -160px;
	transform:rotate(270deg);
	text-transform:uppercase;
	border-radius 0 0 5px 5px;
	cursor: pointer;

	div {
		padding:0px 12px;
		line-height:44px;
		font-size:24px;
		position: absolute;
		transform: translateX(9%);
	}
	img.search-icon {
		height: 20px;
		transform:rotate(90deg);
		color: #e5007d;
		/* padding-left:5px; */
	}
	img.search-close-icon {
		height:14px;
		transform:rotate(90deg);
		padding-left:5px;
	}
}



.search-tab-close {
	position:relative;
	width:47px;
	height:36px;
	text-align:center;
	background-color:${(props) => props.theme.colours.secondary};
	color:white;
	top: -50%;
	line-height:33px;
    right: -224px;
	transform:rotate(270deg);
	text-transform:uppercase;
	border-radius 0 0 5px 5px;
	cursor: pointer;

	img.search-close-icon {
		height:16px;
		transform:rotate(90deg);
		padding-left:5px;
	}
}

.clear-button{ 
	cursor: pointer;
	font-size: 14px;
	text-decoration:underline;
	:hover {
		color: #FFEB00;
    		}
}

.search{

	display:flex;

	justify-content:space-between;
	flex-direction:row;
	margin: 0 auto;
	marginTop: 10%; 
	padding: 0 20px 0 15px;
	text-transform: uppercase;
	font-size: 16px;
	margin-bottom: 5px;
}

.search-bar {
	input {
		border: none;
		border-radius:3px;
		height:24px;
		width:190px;
		margin: 0 20px 0 15px;
	}
}

.filtered-list {
	padding: 15px;
	overflow-y: scroll;
	height: calc(100% - 263px);
	margin-top: 10px;

	&::-webkit-scrollbar {
		width: 7px;
		@media only screen and (max-width: 600px) {
		  display: none;
		}
	  }
	&::-webkit-scrollbar-track {
		background: ${(props) => props.theme.colours.secondary};
		border-radius: 7px;
	  }
	&::-webkit-scrollbar-thumb {
		background: ${(props) => props.theme.colours.primary};
		border-radius: 7px;
	  }

	.business-name { 
		/* display: inline; */
		width: 100%;
		/* height: 50px; */
		margin-bottom: 12px;

		span {
			cursor: pointer;
		:hover {
		/* background-color: white; */
		color: #EF66B1;
    		}
		}
	}
}

@media screen and (max-width:425px){
	width:270px;
	padding-top:150px;	
	
	&.closed {
		left:-271px
	}

	.search-tab-open{
		right: -200px;
	}

	.search-tab-close{
		right: -262px;
	}

	.search-bar {
		input {
			width:235px;
		}
	}
}

`;

export default ListStyles;
