import { createGlobalStyle } from "styled-components";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  html {
    width: 100%;
    height: 100%;
    overscroll-behavior: none;
  }

  body {
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.colours.background};
    overflow-y: hidden;
  }

  html, body {
    overscroll-behavior-y: contain;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p {
    color: white;
  }

  .ReactModal__Content {
    background-color: #e5007d;
  }

  .resetButton:hover {
    background-color: rgb(255, 230, 4);
    }

  .zoomButtons {
    width: 42px;
    height: 84px;
    /* border-radius: 2px; */

      .zoomButton {
        height: 40px;
        /* background-color: #fff; */
        /* margin-bottom: 2px; */
        /* border-radius: 5px; */
        &:hover {
          background-color: rgb(255, 230, 4);
          /* border:1px solid ${(props) =>
            props.theme.colours.primary}!important; */

          i {
            color: ${(props) => props.theme.colours.primary};
          }
        }

        i {
          width: 100%;
          text-align: center;
          line-height: 1.6;
          cursor: pointer;
        }
      }
  }

  @media only screen and (max-width: 600px) {
    .zoomButtons {
      transform: rotate(90deg);
      position: fixed;
      bottom: -3px;
      right: 39px;
      height: 84px;
      display: none;

      .zoomButton {
        height: 40px;

        i {
          transform: rotate(90deg) translateX(2px);
        }
      }
    }

    .resetButton {
      transform: rotate(90deg);
      position: fixed;
      bottom: 17px;
      right: 120px;
      border-radius: 5px;
      display: none;
    }
  }

  @media only screen and (max-width: 750px) {
    .ReactModal__Content {
      width:85%!important;
      background-color: #e5007d !important;
    }

    /* .brand {
      width:50px!important;
      height:50px!important;
    } */
  }

img.BIDLogo {
  width: 180px;
  @media only screen and (max-width: 600px) {
    width: 120px;
  }
}

`;

export default GlobalStyle;
