import * as React from "react";
import Button from "./Button";
import theme from "../../styles/theme";

const ResetControllerUI = ({ center, reset }) => {
  return (
    <div
      className="resetButton"
      style={{
        borderRadius: 4,
        overflow: "hidden",
        backgroundColor: "#ffff",
        // backgroundColor: theme.colours.secondary,
        color: "#e5007d",
      }}
    >
      <Button
        onClick={center}
        style={{
          transition: ".4s",
          // border: `1px solid ${theme.colours.primary}`,
        }}
      >
        <i className="material-icons">center_focus_weak</i>
      </Button>
      {/*  <Button onClick={reset}>
        <i className="material-icons">autorenew</i>
      </Button> */}
    </div>
  );
};

export default ResetControllerUI;
