import styled from "styled-components";

const HeaderStyles = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: auto;
  width: 170px;
  background-color: #e5007d;
  /* border-left: 2px solid black; */
  z-index: 10;

  .logo {
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
    z-index: 1;
    background: #e5007d;
    /* margin-bottom: 5px; */
  }
  img {
    position: absolute;
    width: 120px;
    padding-left: 4px;
    bottom: 15px;
    cursor: pointer;
  }

  .mobileMenu {
    display: none;
    background: transparent;
    border: none;
    color: white;
  }

  @media only screen and (max-width: 600px) {
    /* border-top: 2px solid black; */
    background-color: #e5007d;
    top: ${(p) => (p.isHamburgerOpen ? 0 : "calc(100% - 80px)")};
    transition: 0.3s ease-in-out top;
    /* top: calc(100% - 80px); */
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;

    & > div {
      opacity: ${(p) => (p.isHamburgerOpen ? 1 : 0)};
      transition-delay: 0.3s;
      transition: 0.3s ease-in-out opacity;
    }

    .mobileMenu {
      display: block;
      /* margin-left: 20px; */
      position: absolute;
      right: 20px;
      margin-top: 20px;
      z-index: 1;

      .material-icons {
        font-size: 40px;
        /* position: absolute;
        right: 30px;
        margin-top: 20px; */
      }
    }

    .logo {
      padding-left: 20px;
      background-color: #e5007d;
      width: 30%;
      top: 12px;
    }

    img {
      padding-left: 20px;
    }
  }
`;

export default HeaderStyles;
