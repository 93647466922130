export default [
  /**************************************/
  /**************************************/
  /************ Intro Modal** ***********/
  /**************************************/
  /**************************************/
  {
    name: "Welcome to Leicester!",
    zIndex: 999,
    active: false,
    position: {
      x: 100,
      y: 100,
    },
    transform: {
      width: 0,
      height: 0,
    },
    content: `
      <div class="intro">
      <p>Whatever your reason for visiting this great city, a friendly welcome is sure to await you. Leicester is packed with great things to do and places to visit - you might not know where to start. Well fear not, because this guide gives you the low-down on everything you’ll need to know to make the most of your time in the city. From shops, cafés, restaurants, bars and nightclubs to theatres, museums and galleries, whoever you are and whatever you plan to do, you can find the best of Leicester right here.</p>

      <p>Simply use the handy filtering system to select what you’re looking for and you’ll find some great recommendations pinned on the city map. Once you’ve made your choice, you can click on the address for directions to take you there - it’s that easy!</p>

      <p>Want to explore the city’s heritage? Fancy a weekend of retail therapy? A quick lunch, a fine-dining experience, a night out with your friends, a fun, family activity? You won’t miss a thing if you follow the tips in this guide.</p> 

      <p>You’ll also find information on some of the city’s top events throughout the year, and look out for the yellow Independent Leicester logo which means you’re supporting a local business.</p>

      </div>
    `,
    icon: "bid-icon-white.svg",
    brand: "bid-icon-white.svg",
    category: null,
    carousel: [
      {
        type: "image",
        content: "leicester-night.jpg",
      },
    ],
  },
  /**************************************/
  /**************************************/
  /*********** Category Tests ***********/
  /**************************************/
  /**************************************/
  // {
  //   name: "Christmas Test",
  //   zIndex: 999,
  //active: false,//   
  //position: {
  //     x: -8000,
  //     y: -8000,
  //   },
  //   transform: {
  //     width: 50,
  //     height: 50,
  //   },
  //   icon: "christmas.svg",
  //   // category: ["cafe",
  //   category: ["christmas"],    
  // },



  
  {
    name: "Café Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://google.com",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com",
      },
      {
        name: "instagram",
        url: "https://google.com",
      },
      {
        name: "twitter",
        url: "https://google.com",
      },
    ],
    content: `
    <div class="intro">
      <p> Behind a discreet green door you’ll find this cosy, friendly, restaurant serving traditional Italian food. It’s one of the city’s hidden gems and even has a small rooftop terrace where you can dine al fresco on a sunny day.</p>
    </div>
    `,
    icon: "cafe.svg",
    // category: ["cafe",
    category: ["cafe", "lunch", "dinner"],
    carousel: [
      {
        type: "image",
        content: "cafe/la_foret_cafe.jpg",
      },
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "in_subdirectory/mountains.jpg",
      },
      {
        type: "video",
        content:
          '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    ],
    link: {
      text: "5 Albion Street, Leicester LE1 6GD",
      url: "http://maps.google.com/?q= 5 Albion Street, Leicester LE1 6GD",
    },
  },

  {
    name: "Lunch Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    content: `
    <div class="intro">
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>    </div>
    `,
    icon: "lunch.svg",
    category: ["lunch"],
    carousel: [
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "in_subdirectory/mountains.jpg",
      },
      {
        type: "video",
        content:
          '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    ],
    link: {
      text: "click now",
      url: "https://www.google.com",
    },
  },

  {
    name: "Dinner Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    content: `
    <div class="intro">
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>    </div>
    `,
    icon: "dinner.svg",
    category: ["dinner"],
    carousel: [
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "in_subdirectory/mountains.jpg",
      },
      {
        type: "video",
        content:
          '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    ],
    link: {
      text: "click now",
      url: "https://www.google.com",
    },
  },

  {
    name: "Sweet Treat Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    content: `
    <div class="intro">
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>    </div>
    `,
    icon: "sweet_treat.svg",
    category: ["sweet treat"],
    carousel: [
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "in_subdirectory/mountains.jpg",
      },
      {
        type: "video",
        content:
          '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    ],
    link: {
      text: "click now",
      url: "https://www.google.com",
    },
  },

  {
    name: "Pubs & Bars Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    content: `
    <div class="intro">
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>    </div>
    `,
    icon: "pubs_bars.svg",
    category: ["pubs bars"],
    carousel: [
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "in_subdirectory/mountains.jpg",
      },
      {
        type: "video",
        content:
          '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    ],
    link: {
      text: "click now",
      url: "https://www.google.com",
    },
  },

  {
    name: "Nightclubs Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    content: `
    <div class="intro">
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>    </div>
    `,
    icon: "nightclubs.svg",
    category: ["nightclubs"],
    carousel: [
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "in_subdirectory/mountains.jpg",
      },
      {
        type: "video",
        content:
          '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    ],
    link: {
      text: "click now",
      url: "https://www.google.com",
    },
  },

  {
    name: "Entertainment Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    content: `
    <div class="intro">
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>    </div>
    `,
    icon: "entertainment.svg",
    category: ["entertainment"],
    carousel: [
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "in_subdirectory/mountains.jpg",
      },
      {
        type: "video",
        content:
          '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    ],
    link: {
      text: "click now",
      url: "https://www.google.com",
    },
  },

  {
    name: "Activities Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    content: `
    <div class="intro">
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>    </div>
    `,
    icon: "activities.svg",
    category: ["activities"],
    carousel: [
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "in_subdirectory/mountains.jpg",
      },
      {
        type: "video",
        content:
          '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    ],
    link: {
      text: "click now",
      url: "https://www.google.com",
    },
  },

  {
    name: "Family Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    content: `
    <div class="intro">
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>    </div>
    `,
    icon: "family.svg",
    category: ["family"],
    carousel: [
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "in_subdirectory/mountains.jpg",
      },
      {
        type: "video",
        content:
          '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    ],
    link: {
      text: "click now",
      url: "https://www.google.com",
    },
  },

  {
    name: "Shopping Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    content: `
    <div class="intro">
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>
    <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>    </div>
    `,
    icon: "shopping.svg",
    category: ["shopping"],
    carousel: [
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "beach1.jpg",
      },
      {
        type: "image",
        content: "in_subdirectory/mountains.jpg",
      },
      {
        type: "video",
        content:
          '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      },
    ],
    link: {
      text: "click now",
      url: "https://www.google.com",
    },
  },

  // {
  //   name: "Events Test",
  //active: false,//   
  //position: {
  //     x: -8000,
  //     y: -8000,
  //   },
  //   transform: {
  //     width: 50,
  //     height: 50,
  //   },
  //   content: `
  //   <div class="intro">
  //   <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>
  //   <p> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus ad corrupti perspiciatis repudiandae, veritatis voluptatibus deserunt incidunt ipsum aut voluptates exercitationem accusantium velit cumque minus. Commodi consequuntur harum nobis quis!</p>    </div>
  //   `,
  //   icon: "events.svg",
  //   category: ["events",
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "beach1.jpg",
  //     },
  //     {
  //       type: "image",
  //       content: "beach1.jpg",
  //     },
  //     {
  //       type: "image",
  //       content: "in_subdirectory/mountains.jpg",
  //     },
  //     {
  //       type: "video",
  //       content:
  //         '<iframe width="560" height="315" src="https://www.youtube.com/embed/hfjHJneVonE?start=15" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  //     },
  //   ],
  //   link: {
  //     text: "click now",
  //     url: "https://www.google.com",
  //   },
  // },

  {
    name: "independant Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    icon: "independant.svg",
    // category: ["cafe",
    category: ["independant"],    
  },

  {
    name: "Gift Card Test",
    active: false,
    position: {
      x: -8000,
      y: -8000,
    },
    transform: {
      width: 50,
      height: 50,
    },
    icon: "gift_card.svg",
    // category: ["cafe",
    category: ["gift card"],    
  },

  /**************************************/
  /**************************************/
  /******** Category: Christmas ********/
  /**************************************/
  /**************************************/


  // {
  //   name: "Ice Rink",
  //   zIndex: 999,
  //active: false,//   
  //position: {
  //     x: 1773,
  //     y: 2379,
  //   },
  //   transform: {
  //     width: 200,
  //     height: 200,
  //   },
  //       website: "https://www.christmasinleicester.co.uk/ice-rink/",
  //   content: `
  //   <div class="intro">
  //   <p class="date">Thursday 2 December – Monday 3 January</p>
  //   <p>Due to popular demand the real ice rink will be back in Jubilee Square this Christmas!
  //   No need to worry about the weather – come rain or shine you can glide happily under a clear roof.
  //   Customers are required to wear face coverings when not on the ice rink.</p>

  //   <p>Tickets can be purchased online and also in person from the Jubilee Square Ice Rink ticket office, located at the front of the ice rink in Jubilee Square. The ticket office is open from 9.30am each day until 8.30pm, except for Christmas Eve and New Year’s Eve when it will close at 4.30pm. The rink and the ticket office will be closed on Christmas Day and New Year’s Day.</p> 

  //   <p>Tickets can also be purchased in person at the Visit Leicester Information Centre located at the KRIII Visitor Centre, 4A St. Martins, Leicester, LE1 5DB.</p>

  //   <p>Skate hire and a 45-minute session on the ice will cost £7 at off-peak times. Tickets for all other times will cost £10 for adults and £8.50 for children/concessions.</p>

  //   <p><strong>Off Peak:</strong><br>
  //   Monday 6 December, Friday 10, Monday 13 and Friday 17 December: 11am – 4pm<br>
  //   Tuesday 7, Wednesday 8, Thursday 9, Tuesday 14, Wednesday 15 and Thursday 16 December: 12pm session & 2pm – 4pm</p>

  //   <p><strong>Quiet Sessions:</strong><br>
  //   Monday 20, Monday 27 and Wednesday 29 December: 1pm</p>

  //   </div>
  //   `,
  //   icon: "iceRink.svg",
  //   categoryname: "christmas",
  //   category: ["christmas",
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "christmas/iceRink.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "Jubilee Square",
  //     url: "https://www.google.com/maps?q=jubilee+square+leicester&source=lmns&bih=1018&biw=1792&hl=en&sa=X&ved=2ahUKEwiAtvec8s70AhVHYBoKHZ6pD8QQ_AUoAXoECAEQAQ",
  //   },
  // },

  // {
  //   name: "Wheel of Light",
  //active: false,//   
  //position: {
  //     x: 1929,
  //     y: 2359,
  //   },
  //   transform: {
  //     width: 200,
  //     height: 200,
  //   },
  //   website: "https://www.christmasinleicester.co.uk/whats-on/",
  //   content: `
  //   <div class="intro">
  //   <p class="date">FRIDAY 12 NOVEMBER – SUNDAY 2 JANUARY</p>

  //   <p>A 22m-high ‘Happy Wheel’ will illuminate Jubilee Square with its state-of-the-art LED lighting, creating a stunning light show while taking passengers for a spin above the square. The brand new Happy Wheel will be a feature of the square until the end of November, when it will be replaced by a more familiar festive attraction – the 35m-high Wheel of Light.</p>

  //   <p>Open 12noon – 8pm Sunday – Thursday / 12noon – 10pm Friday and Saturday<p>

  //   <p>Closed Christmas Day and New Year’s Day</p>

  //   <p><strong>Happy Wheel ticket prices:</strong><br>
  //   Standard: £4.50<br>
  //   Child (under 1.4m): £4<br>
  //   Family 2 adults + 2 children or 1 adult + 3 children: £14</p>
    
  //   </div>
  //   `,
  //   icon: "wheelOfLight.svg",
  //   categoryname: "christmas",
  //   category: ["christmas",
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "christmas/wheelOfLight.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "Jubilee Square",
  //     url: "https://www.google.com/maps?q=jubilee+square+leicester&source=lmns&bih=1018&biw=1792&hl=en&sa=X&ved=2ahUKEwiAtvec8s70AhVHYBoKHZ6pD8QQ_AUoAXoECAEQAQ",
  //   },
  // },

  // {
  //   name: "Santa in the Square",
  //active: false,//   
  //position: {
  //     x: 2812,
  //     y: 2629,
  //   },
  //   transform: {
  //     width: 200,
  //     height: 200,
  //   },
  //   website: "https://www.christmasinleicester.co.uk/whats-on/",
  //   content: `
  //   <div class="intro">

  //   <p class="date">SATURDAY 4 – THURSDAY 23 DECEMBER</p>

  //   <p>Visit our enchanting snow-covered Christmas grotto in Green Dragon Square and create some magical memories. Children of all ages can visit Santa and his elf for free, to make this truly a memorable experience. And those on the nice list will also receive a wonderful free gift!</p>

  //   <p>Adults are required to wear a face-covering within Santa’s grotto. </p>

  //   <p><strong>Free</strong></p>

  //   <p>Monday to Friday 12noon – 6pm / Saturday 11am – 6pm / Sunday 11am – 4pm</p>


  //   </div>
  //   `,
  //   icon: "santaSquare.svg",
  //   categoryname: "christmas",
  //   category: ["christmas",
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "christmas/santaSquare.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "GREEN DRAGON SQUARE",
  //     url: "https://www.google.com/maps/place/Green+Dragon+Square/@52.6342928,-1.1359579,17z/data=!3m1!4b1!4m5!3m4!1s0x4877615bad020f6d:0x3bbba551b90f74c9!8m2!3d52.6342711!4d-1.1337408?hl=en",
  //   },
  // },

  // {
  //   name: "Movie Nights",
  //active: false,//   
  //position: {
  //     x: 2977,
  //     y: 2615,
  //   },
  //   transform: {
  //     width: 200,
  //     height: 200,
  //   },
  //   website: "https://www.christmasinleicester.co.uk/whats-on/",
  //   content: `
  //   <div class="intro">

  //   <p class="date">EVERY SATURDAY UNTIL CHRISTMAS</p>

  //   <p>What better way to get in the festive mood than with a Christmas movie!</p>

  //   <p>The following films will also be shown on Saturday evening’s at 5pm so if you’re in town stop by!</p>
    
  //   <p><strong>Free</strong></p>
    
  //   <p>27 November Christopher Robin<br>
  //   4 December The Polar Express<br>
  //   11 December Miracle on 34th Street (1994)<br>
  //   18 December The Santa Clause</p>


  //   </div>
  //   `,
  //   icon: "movieNights.svg",
  //   categoryname: "christmas",
  //   category: ["christmas",
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "christmas/movieNights.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "GREEN DRAGON SQUARE",
  //     url: "https://www.google.com/maps/place/Green+Dragon+Square/@52.6342928,-1.1359579,17z/data=!3m1!4b1!4m5!3m4!1s0x4877615bad020f6d:0x3bbba551b90f74c9!8m2!3d52.6342711!4d-1.1337408?hl=en",
  //   },
  // },

  // {
  //   name: "Nativity Scene",
  //active: false,//   
  //position: {
  //     x: 3188,
  //     y: 2802,
  //   },
  //   transform: {
  //     width: 200,
  //     height: 200,
  //   },
  //   website: "https://www.christmasinleicester.co.uk/whats-on/",
  //   content: `
  //   <div class="intro">

  //   <p class="date">THURSDAY 18 NOVEMBER – SUNDAY 2 JANUARY</p>

  //   <p>Experience the traditional nativity scene with an audio story telling from Bishop Street Methodist Church Sunday school children and accompanying illustrations.</p>

  //   <p><strong>Free</strong></p> 


  //   </div>
  //   `,
  //   icon: "nativityScene.svg",
  //   categoryname: "christmas",
  //   category: ["christmas",
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "christmas/nativity.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "TOWN HALL SQUARE",
  //     url: "https://www.google.com/maps/place/Town+Hall+Square+Fountain/@52.6339234,-1.1334923,18.21z/data=!4m5!3m4!1s0x4877611f64be4d11:0x5db0fd52b63f28c9!8m2!3d52.6336219!4d-1.1322094?hl=en",
  //   },
  // },

  // {
  //   name: "Interactive Adventures!",
  //active: false,//   
  //position: {
  //     x: 3380,
  //     y: 2840,
  //   },
  //   transform: {
  //     width: 200,
  //     height: 200,
  //   },
  //   website: "https://www.christmasinleicester.co.uk/interactive-adventure/",
  //   content: `
  //   <div class="intro">

  //   <p class="date">THURSDAY 18 NOVEMBER – THURSDAY 6 JANUARY</p>

  //   <p>Brand new for Christmas 2021 is a free and interactive family adventure using augmented reality (AR) to bring fictional characters to life in the city centre. Enjoy all of the activities below!</p>

  //   <p>Download the free app ‘Christmas in Leicester Interactive Adventure’ on the App Store for Apple devices and on the Google Play store for Android users.</p>

  //   <p><strong>Free</strong></p>


  //   </div>
  //   `,
  //   icon: "interactiveAdventure.svg",
  //   categoryname: "christmas",
  //   category: ["christmas",
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "christmas/interactiveAdventures.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "TOWN HALL SQUARE",
  //     url: "https://www.google.com/maps/place/Town+Hall+Square+Fountain/@52.6339234,-1.1334923,18.21z/data=!4m5!3m4!1s0x4877611f64be4d11:0x5db0fd52b63f28c9!8m2!3d52.6336219!4d-1.1322094?hl=en",
  //   },
  // },

  // {
  //   name: "Billy Bates Funfair",
  //active: false,//   
  //position: {
  //     x: 3633,
  //     y: 1988,
  //   },
  //   transform: {
  //     width: 200,
  //     height: 200,
  //   },
  //   website: "https://www.christmasinleicester.co.uk/whats-on/",
  //   content: `
  //   <div class="intro">

  //   <p class="date">THURSDAY 18 NOVEMBER – THURSDAY 6 JANUARY</p>

  //   <p>Experience all the fun of the fair in the heart of the city this Christmas!</p>

  //   </div>
  //   `,
  //   icon: "billyBatesFunfair.svg",
  //   categoryname: "christmas",
  //   category: ["christmas",
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "christmas/billyBatesFunfair.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "HUMBERSTONE GATE",
  //     url: "https://www.google.com/maps/place/Humberstone+Gate,+Leicester/@52.636405,-1.132017,17z/data=!3m1!4b1!4m5!3m4!1s0x487761192d054569:0x8b869ac8db4c7a4!8m2!3d52.636405!4d-1.1298283?hl=en",
  //   },
  // },

  // {
  //   name: "Rhizome Light Installation",
  //active: false,//   
  //position: {
  //     x: 4218,
  //     y: 2029,
  //   },
  //   transform: {
  //     width: 200,
  //     height: 200,
  //   },
  //   website: "https://bidleicester.co.uk/bid-leicester-news/rhizome-leicester/",
  //   categoryname: "Christmas",
  //   content: `
  //   <div class="intro">
  //   <p class="date">THURSDAY 18 NOVEMBER – WEDNESDAY 5 JAN 2022</p>

  //   <p>Experience the bright world of Rhizome this Christmas, a glowing series of tall structures, gentle colour changing neon ropes and accompanying soundscape by renowned visual artists Tom and Lien Dekyvere. Exploring the boundaries between nature and technology Rhizome has travelled the globe from Sydney to Baltimore and many more places in-between. Feel a sense of wonder in this powerful visual spectacle that signifies new life hope and positivity… and one of the most ‘Instagramable’ shots in the city!</p>

  //   <p><strong>Free</strong></p>

  //   <p>Illuminated daily from 4.30pm – 10.30pm</p>
              
  //   </div>
  //   `,
  //   icon: "rhizome.svg",
  //   // brand: "in-logo-black.png",
  //   category: ["christmas",
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "events/rhizome.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "Orton Square",
  //     url: "https://www.google.com/maps/place/Orton+Square,+Leicester/@52.6346003,-1.1276792,18.63z/data=!4m5!3m4!1s0x487761186136c667:0x717a4098c153d8b4!8m2!3d52.6341652!4d-1.1266143",
  //   },
  // },

  /**************************************/
  /**************************************/
  /****** Category: Lunch/dinner ********/
  /**************************************/
  /**************************************/
  {
    name: "Casa Romana",
    active: false,
    position: {
      x: 3530,
      y: 3440,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.casaromana.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/buonappetitograziealtrettanto/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/casaromanaleicester/",
      },
    ],
    content: `
    <div class="intro">
      <p> Behind a discreet green door you’ll find this cosy, friendly, restaurant serving traditional Italian food. It’s one of the city’s hidden gems and even has a small rooftop terrace where you can dine al fresco on a sunny day.</p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    // category: ["cafe",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/casaromana_1.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/casaromana_2.jpg",
      },
    ],
    link: {
      text: "5 Albion Street, Leicester LE1 6GD",
      url: "http://maps.google.com/?q=CasaRomana 5 Albion Street, Leicester LE1 6GD",
    },
  },

  {
    name: "Chinese Snack Box Ltd",
    active: false,
    position: {
      x: 2259,
      y: 2358,
    },
    transform: {
      width: 50,
      height: 50,
    },
    // website: "https://www.casaromana.co.uk/",
    // socials: [
    //   {
    //     name: "facebook",
    //     url: "https://www.facebook.com/buonappetitograziealtrettanto/",
    //   },
    //   {
    //     name: "instagram",
    //     url: "https://www.instagram.com/casaromanaleicester/",
    //   },
    // ],
    content: `
    <div class="intro">
      <p>An imaginative fusion of modern and traditional freshly home cooked Chinese food.</p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    // category: ["cafe",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner","independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/chinese-snackbox.jpg",
      },
    ],
    link: {
      text: "80 High Street, Leicester LE1 5YP",
      url: "https://www.google.com/maps/place/Chinese+Snack+Box+Ltd/@52.6353416,-1.1373709,15z/data=!4m5!3m4!1s0x0:0xae329c31d92a9407!8m2!3d52.6353416!4d-1.1373709?shorturl=1",
    },
  },

  {
    name: "Desi Street Food",
    active: false,
    position: {
      x: 2932,
      y: 3248,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://desisf.co.uk/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/buonappetitograziealtrettanto/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/desisf_le1",
      },
    ],
    content: `
    <div class="intro">
      <p>Desi Street Food is an Indian Cafe concept created to experience the street markets of India. From the northern region of Punjab, though Delhi, down to Mumbai, Desi Street food brings a sense of that culture – drinking Chai or Limca, eating Chaats or a healthy Salad.</p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch",
    category: ["lunch", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/desi.jpg",
      },
    ],
    link: {
      text: "8 Pocklingtons Walk, Leicester LE1 6BU",
      url: "https://www.google.com/maps/place/Desi+Street+Food/@52.6327923,-1.1342905,15z/data=!4m5!3m4!1s0x0:0x68488f692cbae53c!8m2!3d52.6327742!4d-1.1343379?sa=X&ved=2ahUKEwiP1u6MrPnxAhVDhlwKHb0VAjAQ_BIwFHoECFMQBQ&shorturl=1",
    },
  },

  {
    name: "Chutney Ivy",
    active: false,
    position: {
      x: 4150,
      y: 2520,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://chutneyivy.com/home",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/chutneyivy",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/chutneyivy/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/chutneyivy",
      },
    ],
    content: `
    <div class="intro">
      <p>Looking for traditional Indian cuisine with a
      modern twist? Then look no further than Michelin Guide-listed Chutney Ivy, where all the things you adore about Indian food
      have been brought up to date.</p>
      <p> The carefully created menu takes the discerning diner on a culinary trip around the Indian subcontinent. The restaurant is also well known for its pre and post-theatre banquets - perfect for when you’re seeing a show at the Curve theatre which is just across the road.
      </p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner","independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/chutney_ivy.jpg",
      },
    ],
    link: {
      text: "41 Halford Street, Leicester LE1 1TR",
      url: "http://maps.google.com/?q=ChutneyIvy 41 Halford Street, Leicester LE1 1TR",
    },
  },

  {
    name: "Mowgli Street Food",
    active: false,
    position: {
      x: 2710,
      y: 2427,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.mowglistreetfood.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/mowglistreetfood",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/mowglistreetfood/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/Mowglistfood",
      },
    ],
    content: `
    <div class="intro">
      <p>Festooned with fairy lights, this Indian street food restaurant has a lovely, cosy atmosphere and also features the brand’s signature swing seats and a huge tree made from driftwood. Mowgli focuses on serving the kind of food that Indians eat at home and on their streets - food
      that is healthy, often vegan and always packed with fresh flavour.</p>
      </p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner","independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/mowgli.jpg",
      },
    ],
    link: {
      text: "12-13 St Martins, Leicester LE1 5DF",
      url: "http://maps.google.com/?q=Mowgli 12-13 St Martins, Leicester LE1 5DF",
    },
  },

  {
    name: "Fifteen Seventy Three Bar & Grill",
    active: false,
    position: {
      x: 2000,
      y: 2080,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://fifteenseventythree.com/",
    socials: [
      {
        name: "instagram",
        url: "https://www.instagram.com/fifteenseventythree/",
      },
    ],
    content: `
    <div class="intro">
      <p>Housed in a Grade II-listed, 16th Century
      schoolhouse in the city centre, the kitchen prepares sizzling steaks, fresh seafood, a range of salads and vegetarian dishes. On warm evenings, enjoy the
      outdoor seating and views of Highcross.</p>
    </div>
    `,
    icon: "lunch.svg",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner","independant"],
    // brand: "in-logo-black.png",
    carousel: [
      // {
      //   type: "image",
      //   content: "lunch_dinner/chutney_ivy.jpg",
      // },
    ],
    link: {
      text: "Freeschool Lane, Leicester LE1 4FY",
      url: "http://maps.google.com/?q=fifteenseventythree Freeschool Lane, Leicester LE1 4FY",
    },
  },

  {
    name: "Herb",
    active: false,
    position: {
      x: 4280,
      y: 3180,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.herbrestaurant.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/herbrestaurant.co.uk/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/kayalsherb/",
      },
    ],
    content: `
    <div class="intro">
      <p>The Kerala Herbal Cuisine restaurant is exclusively vegetarian and vegan, serving aromatic spice-blended food from Kerala. Healthy dishes incorporating the healing properties of herbs and spices not only tingle taste buds but are also good for body and soul.</p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner","independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/herb.jpg",
      },
    ],
    link: {
      text: "96 Granby Street, Leicester LE1 1DJ",
      url: "http://maps.google.com/?q=Herb 96 Granby Street, Leicester LE1 1DJ",
    },
  },

  {
    name: "James’ Café Bistro",
    active: false,
    position: {
      x: 2858,
      y: 1740,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://jamescafebistro.wordpress.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/JamesCBistro",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/jamescafebistro/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/jamescafebistro",
      },
    ],
    content: `
    <div class="intro">
      <p>Looking for some great music on a Saturday night in a quirky, vintage setting? Check out James’ Cafe Bistro, a hidden gem just a few steps from the Clock Tower, which hosts vinyl nights every Saturday evening as well as live music acts throughout the year. This much-loved, family-friendly bistro makes food fresh to order, using locally-sourced ingredients with plenty of dishes to suit vegan and gluten-free diets.</p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner","independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/jcb1.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/jcb2.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/jcb3.jpg",
      },
    ],
    link: {
      text: "5 East Bond Street, Leicester LE1 4SX",
      url: "http://maps.google.com/?q=jamescafebistro 5 East Bond Street, Leicester LE1 4SX",
    },
  },

  {
    name: "Knight & Garter",
    active: false,
    position: {
      x: 2908,
      y: 2834,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.knightandgarter.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/KnightAndGarter/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/knightandgarter/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/knightandgarter/",
      },
    ],
    content: `
    <div class="intro">
      <p>Whether you’re looking for a pint and a snack, a long lunch whilst shopping, dinner or cocktails, the Knight & Garter has got you covered. The stylish bar area serves a range of boutique drinks – a roaring fire will keep you cosy all winter and the terrace is perfect for the warmer months. Through a hidden bookcase door you will gain entry to their contemporary restaurant. A fresh, seasonal menu includes four steak cuts as well as dishes inspired by all cuisines and served with a British twist.</p>
    </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, pubs & bars, independant",
    category: ["lunch", "dinner", "pubs bars", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/kg1.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/kg2.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/kg3.jpg",
      },
    ],
    link: {
      text: "14 Hotel Street, Leicester LE1 5AW",
      url: "http://maps.google.com/?q=KnightAndGarter 14 Hotel Street, Leicester LE1 5AW",
    },
  },

  {
    name: "Middletons Steakhouse & Grill",
    active: false,
    position: {
      x: 2736,
      y: 2720,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://middletons-shg.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/MiddletonsLeicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/middletons_shg/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/middletons_shg/",
      },
    ],
    content: `
    <div class="intro">
      <p>The iconic former NatWest bank building on St Martin’s is the stunning location of this steakhouse and grill, specialising in prime cuts of meat, burgers, fresh fish and homemade desserts. There’s also a great selection of wines and cocktails.
      </p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/middletons1.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/middletons2.jpg",
      },
    ],
    link: {
      text: "2 St Martin’s, Leicester LE1 5DB",
      url: "http://maps.google.com/?q=Middletons 2 St Martin’s, Leicester LE1 5DB",
    },
  },

  {
    name: "Peter Pizzeria",
    active: false,
    position: {
      x: 2971,
      y: 3530,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.peterpizzeria.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/PeterPizzeria ",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/peterpizzeria/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/PeterPizzeria/",
      },
    ],
    content: `
    <div class="intro">
      <p>Located in one of the city’s historic buildings, Peter Pizzeria is full of quirky decorative touches and, of course, delicious food. Watch a movie or play some ping pong while enjoying a delicious and authentic Italian pizza served in a homely and friendly environment.</p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/peterpizzeria.jpg",
      },
    ],
    link: {
      text: "9 Welford Place, Leicester LE1 6ZH",
      url: "http://maps.google.com/?q=PeterPizzeria 9 Welford Place, Leicester LE1 6ZH",
    },
  },

  {
    name: "Pho",
    active: false,
    position: {
      x: 2230,
      y: 2262,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.phocafe.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/PhoLeicester",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/phorestaurant/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/PhoRestaurant/",
      },
    ],
    content: `
    <div class="intro">
      <p>Situated close to Highcross Leicester, Pho offers healthy Vietnamese food, including pho noodle soups, curries, spring rolls, salads and amazing cocktails. Almost all of their menu is gluten-free and more than 40% is vegan. They even have a kids menu to keep all the family satisfied.</p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/pho1.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/pho2.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/pho3.jpg",
      },
    ],
    link: {
      text: "87-91 High Street, Leicester LE1 4JB",
      url: "http://maps.google.com/?q=Pho 87-91 High Street, Leicester LE1 4JB",
    },
  },

  {
    name: "San Carlo",
    active: false,
    position: {
      x: 3781,
      y: 2870,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://sancarlo.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/SanCarloLeicester",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/sancarlorestaurants/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/SanCarlo_Group/",
      },
    ],
    content: `
    <div class="intro">
      <p>Run by Italian directors and staff, San Carlo boasts not only an extensive menu using the freshest Italian ingredients but also a wood-burning oven for perfect pizzas. One of
      Leicester’s most popular restaurants, it guarantees a buzzing atmosphere in a stylish and contemporary setting.
      </p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/sancarlo.jpg",
      },
    ],
    link: {
      text: "38-40 Granby Street, Leicester LE1 1DE",
      url: "http://maps.google.com/?q=SanCarlo 38-40 Granby Street, Leicester LE1 1DE",
    },
  },

  // {
  //   name: "The White Peacock",
  //active: false,   
  //position: {
  //     x: 3341,
  //     y: 3650,
  //   },
  //   transform: {
  //     width: 50,
  //     height: 50,
  //   },
  //   // website: "https://sancarlo.co.uk/",
  //   socials: [
  //     {
  //       name: "facebook",
  //       url: "https://www.facebook.com/thewhitepeacock ",
  //     },
  //     {
  //       name: "instagram",
  //       url: "https://www.instagram.com/the_white_peacockuk/",
  //     },
  //     {
  //       name: "twitter",
  //       url: "https://twitter.com/WhitePeacockuk/",
  //     },
  //   ],
  //   content: `
  //   <div class="intro">
  //     <p>Located in a charming Grade II-listed building in the heart of the city, the White Peacock is the perfect romantic spot for a date night or meal out with friends and family. It specialises in modern European and international cuisine plus fine wines and cocktails.</p>
  //   </div>
  //   `,
  //   icon: "lunch.svg",
  //   brand: "in-logo-black.png",
  //   categoryname: "lunch, dinner",
  //   category: ["lunch", "dinner"],
  //   carousel: [
  //     // {
  //     //   type: "image",
  //     //   content: "lunch_dinner/pho1.jpg",
  //     // },
  //   ],
  //   link: {
  //     text: "14-16 King Street, Leicester LE1 6RJ",
  //     url: "http://maps.google.com/?q=TheWhitePeacock 14-16 King Street, Leicester LE1 6RJ",
  //   },
  // },

  {
    name: "Wygston’s House Bar & Restaurant",
    active: false,
    position: {
      x: 1971,
      y: 2660,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.wygstonshouse.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/Wygstonshouse",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/wygstonshouse/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/WygstonsHouse/",
      },
    ],
    content: `
    <div class="intro">
      <p>Set in the heart of Leicester’s historic Old Town and with a great ambiance, Wygston’s House is the perfect venue for everything from a few drinks to a special night out. Serving real ales, draught beers and delicious food every day of the week, with cosy interiors and a lit fireplace in winter and a large garden which catches the summer sunshine.
      </p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, pubs & bars, independant",
    category: ["lunch", "dinner", "pubs bars", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/wygstons_1.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/wygstons_2.jpg",
      },
    ],
    link: {
      text: "12 Applegate, Leicester LE1 5LD",
      url: "http://maps.google.com/?q=Wygston 12 Applegate, Leicester LE1 5LD",
    },
  },

  {
    name: "The Grand Union",
    active: false,
    position: {
      x: 3431,
      y: 3861,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.grandunionleicesterpub.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/grandunionLeicester",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/wygstonshouse/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/WygstonsHouse/",
      // },
    ],
    content: `
    <div class="intro">
      <p>Whether you are looking for a real ale or some really great pub grub, The Grand Union Pub is the place for you. Whether you’re planning to catch up with friends for a leisurely meal or a quick pint, the friendly staff look forward to meeting you.
      </p>
    </div>
    `,
    icon: "lunch.svg",
    // brand: "in-logo-black.png",
    categoryname: "lunch, dinner, pubs & bars",
    category: ["lunch", "dinner", "pubs bars"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/grandunion.jpg",
      },
      // {
      //   type: "image",
      //   content: "lunch_dinner/wygstons_2.jpg",
      // },
    ],
    link: {
      text: "24 King Street, Leicester LE1 6RL",
      url: "https://www.google.com/maps/place/The+Grand+Union/@52.631043,-1.1342332,17z/data=!3m1!4b1!4m5!3m4!1s0x487761c0b5b3fda3:0x2fb6cb2016b7391f!8m2!3d52.6310371!4d-1.1320865",
    },
  },

  {
    name: "Bodega",
    active: false,
    position: {
      x: 2692,
      y: 2573,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.bodegacantina.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/BodegaLeic/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/bodegaleic/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/BodegaCantinaUK/",
      },
    ],
    content: `
    <div class="intro">
      <p>A South American-inspired neighbourhood cantina, this colourful restaurant boasts low-level lighting, bunting and even plants hanging from the
      rafters - plus funky music. Choose from the varied menu of nibbles, street food, light bites and large plates - with plenty of options for vegetarians and vegans.</p>
      <p>Dishes include guacamole with fresh
      tortilla chips, blue corn tacos served with a good selection of fillings, quesadillas, plantain salad and Mexican cola wings plus the more exotic dishes of
      Quinoa Chifa and Xim Xim - a Brazilian classic. An impressive range of cocktails is served alongside beers, wines and spirits.
      </p>
    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/bodega.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/bodega_2.jpg",
      },
    ],
    link: {
      text: "6 St Martins Square, Leicester LE1 5DF",
      url: "http://maps.google.com/?q=Bodega 6 St Martins Square, Leicester LE1 5DF",
    },
  },

  {
    name: "The Globe",
    active: false,
    position: {
      x: 2539,
      y: 2356,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.theglobeleicester.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TheGlobeLeicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/the_globe_leicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/globeleicester",
      },
    ],
    content: `
    <div class="intro">
      <p>One of the oldest inns in Leicester, this old-school real ale pub dates back to 1720 and serves a menu of hearty, traditional British dishes. It’s also the perfect place to celebrate a special occasion -
      the snug and cave being available for bookings for smaller groups while a function room can comfortably seat 45 guests. Tasty pies sit on the menu alongside a huge selection of deluxe burgers,
      salads, baguettes and traditional puddings.
      </p>
      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, pubs and bars, independant",
    category: ["lunch", "dinner", "pubs bars", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/globe1.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/globe2.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/globe3.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/globe4.jpg",
      },
    ],
    link: {
      text: "43 Silver Street, Leicester LE1 5EU",
      url: "http://maps.google.com/?q=TheGlobe 43 Silver Street, Leicester LE1 5EU",
    },
  },

  {
    name: "Las Iguanas",
    active: false,
    position: {
      x: 3666,
      y: 3101,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.iguanas.co.uk/restaurants/leicester/menus",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/lasiguanasuk/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/lasiguanas/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/lasiguanas/",
      },
    ],
    content: `
    <div class="intro">
      <p>Las Iguanas restaurant is home to fresh and authentic Brazilian, Mexican and Latin American food and cocktails.
      </p>
      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, pubs and bars, independant",
    category: ["lunch", "dinner", "pubs bars", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/lasiguanas.jpg",
      },
    ],
    link: {
      text: "13-15 Belvoir Street, Leicester LE1 6SL",
      url: "https://www.google.com/maps/place/Las+Iguanas+-+Leicester/@52.6331949,-1.1330476,17z/data=!3m1!4b1!4m5!3m4!1s0x48776121e1186ae5:0xb40badd46dd6b13b!8m2!3d52.6331772!4d-1.1307655?shorturl=1",
    },
  },

  {
    name: "Crafty Burger",
    active: false,
    position: {
      x: 2821,
      y: 2470,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.craftyburger.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/craftystmartins/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/craftystmartins/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/globeleicester",
      // },
    ],
    content: `
    <div class="intro">
      <p>A quality menu of great locally-sourced burgers crafted on site, delicious loaded fries and quality drinks is what keeps food fans coming back for more and more at Crafty, which is housed inside the beautiful city centre coffee shop St Martin’s. Beef for the burgers is reared, butchered and dry-aged by March House Farm, in Great Dalby, while buns are baked daily by Milner’s Bakery of Blaby and cheese is from Country Fayre in Leicester’s market. And for a change from beef, how about a Bang Bang Kluck with fried buttermilk chicken, or enjoy a meat-free burger featuring a vegan patty complete with all the delicious toppings Crafty are famous for.</p>
      </div>
    `,
    icon: "dinner.svg",
    brand: "giftcard.png",
    categoryname: "dinner, independant",
    category: ["dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/crafty.jpg",
      },
    ],
    link: {
      text: "4-6 St Martins Walk, Leicester LE1 5DG",
      url: "http://maps.google.com/?q=CraftyStMartin 6 St Martins Walk, Leicester LE1 5DG",
    },
  },

  {
    name: "Bunk",
    active: false,
    position: {
      x: 3561,
      y: 2797,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.bunkwings.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/bunkwings/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/bunkwings/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/bunkwings/",
      },
    ],
    content: `
    <div class="intro">
      <p>Bunk are known for their combo of chicken wings and cocktails, but in reality you can enjoy casual food of all kinds! From traditional wings to boneless, nuggets, vegetarian and vegan to burger, hot dog and sides options, along with all sorts of sauces and seasonings, your bunk experience can be tailored to your taste.
      </p>
      </div>
    `,
    icon: "dinner.svg",
    // brand: "in-logo-black.png",
    categoryname: "dinner, pubs & bars, independant",
    category: ["dinner", "pubs bars","independant"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/bunk.jpg",
      },
    ],
    link: {
      text: "First Floor, 19 Granby Place, Leicester LE1 6EJ",
      url: "https://www.google.com/maps/place/Bunk+Leicester+Limited/@52.6341827,-1.1333917,17z/data=!3m1!4b1!4m5!3m4!1s0x487761b6981a502d:0xe31cf4603ae5e307!8m2!3d52.6341827!4d-1.131203?shorturl=1",
    },
  },

  {
    name: "Giggling Squid",
    zIndex: 999,
    active: false,
    position: {
      x: 2755,
      y: 2556,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.gigglingsquid.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/gigglingsquid/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/gigglingsquid/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/gigglingsquid/",
      },
    ],
    content: `
    <div class="intro">
      <p>Staggeringly good Thai food prepared by master Thai chefs. Serving a super popular tapas menu at lunch with an extensive menu in the evenings.</p>
      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/giggling_squid.jpg",
      },
    ],
    link: {
      text: "5 St Martins Square, Leicester LE1 5DF",
      url: "http://maps.google.com/?q=GigglingSquid 5 St Martins Square, Leicester LE1 5DF",
    },
  },

  {
    name: "Orton’s",
    active: false,
    position: {
      x: 4515,
      y: 2469,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://ortonsbrasserie.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/ortonsbrasserie/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/ortonsbrasserie/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/OrtonsBrasserie/",
      },
    ],
    content: `
    <div class="intro">
      <p>Orton's Brasserie is in the heart of Leicester's Cultural Quarter, serving classic British dishes, presented in a contemporary fashion. The menu changes seasonally, offering the freshest produce, lovingly prepared and expertly served. There is also an extensive cocktail menu to enjoy with a wonderful view over Orton Square and Curve Theatre.</p>
      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, pubs & bars, independant",
    category: ["lunch", "dinner", "pubs bars", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/orton_1.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/orton_2.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/orton_3.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/orton_4.jpg",
      },
    ],
    link: {
      text: "Orton Square, Leicester LE1 1RD",
      url: "http://maps.google.com/?q=OrtonsBrasserie Orton Square, Leicester LE1 1RD",
    },
  },

  {
    name: "Kokoro",
    active: false,
    position: {
      x: 3205,
      y: 2208,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.kokorouk.com/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/ortonsbrasserie/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/kokoroleicester/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/OrtonsBrasserie/",
      // },
    ],
    content: `
    <div class="intro">
      <p>Fancy a bit of a change? How about hand-made sushi at Kokoro? The healthy, freshly-made dishes are fast and delicious and there’s a choice between small and regular sized portions, with Kokoro’s friendly staff always happy to help you decide what to eat. As well as sushi and sashimi, diners can choose from a varied menu which includes Chicken Teriyaki, Sweet & Sour Chicken, Spicy Tofu or Pork, or Yakisoba - egg noodles seasoned with Kokoro’s secret sauce – plus soups and salads.</p>
      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/kokoro.jpg",
      },
    ],
    link: {
      text: "19 Gallowtree Gate, Leicester LE1 5AD",
      url: "http://maps.google.com/?q=Kokoro 19 Gallowtree Gate, Leicester LE1 5AD",
    },
  },

  {
    name: "Frankie & Benny’s",
    active: false,
    position: {
      x: 1965,
      y: 1845,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.frankieandbennys.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/FrankieBennys/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/frankienbennys/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/frankienbennys",
      },
    ],
    content: `
    <div class="intro">
    <p>Frankie & Benny’s brings together the best of New York and Italian cuisine to offer you big flavours and massive choice. Whether you fancy a juicy chicken burger or an Italian classic like pasta and succulent meatballs, they’ve got something for everyone!</p>

    <p>They cater for all needs with a monumental Gluten Free menu, an offering of vegetarian dishes and if you feel like a lighter bite, there are a bunch of smaller, healthier dishes available. </p>

      </div>
    `,
    icon: "lunch.svg",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/frankiebennies.jpg",
      },
    ],
    link: {
      text: "St Peter’s Square, Highcross Shopping Centre, Leicester LE1 4AN",
      url: "https://www.google.com/maps/place/Frankie+%26+Benny's/@52.6367533,-1.1409092,17z/data=!3m2!4b1!5s0x487760e1c6f1611f:0x3257ceb6f948730!4m5!3m4!1s0x487760e1a51f32c3:0xa8c5efb5bd457d2!8m2!3d52.6367533!4d-1.1387152",
    },
  },

  {
    name: "Fridays",
    active: false,
    position: {
      x: 2305,
      y: 1632,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.tgifridays.co.uk/locations/leicester/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/tgifridaysuk/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/tgifridaysuk/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/tgifridaysuk/",
      },
    ],
    content: `
    <div class="intro">
    <p>The first Fridays opened in New York, 1965 with the timeless promise ‘In here, it’s always Friday’. The Fridays team welcome everyone, whether you’re out for a date, girls night or a family dinner, Fridays will bring you that Fridays Feeling, no matter what day of the week or time of day.</p>
      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/fridays.jpg",
      },
    ],
    link: {
      text: "Bath House Lane, Leicester LE1 4SJ",
      url: "https://www.google.com/maps/place/Fridays/@52.637327,-1.1374437,20z/data=!3m1!5s0x487760e1c6f4ff5b:0xe724e0948c22f3c0!4m5!3m4!1s0x487760e1e66b58dd:0x16ead0fd91080ba0!8m2!3d52.637542!4d-1.137249?shorturl=1",
    },
  },

  {
    name: "Nando’s",
    active: false,
    position: {
      x: 1849,
      y: 1890,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.nandos.co.uk/restaurants/leicester-highcross/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/Nandos.UnitedKingdom/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/nandosuk/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/NandosUK/",
      },
    ],
    content: `
    <div class="intro">
    <p>Afro-Portuguese chain restaurant serving flame-grilled chicken in spicy chilli sauce. Nando’s specialise in combining the universal comfort food of chicken with their legendary PERi-PERi marinade, resulting in a taste to fire the senses and feed the soul.</p>
      </div>
    `,
    icon: "lunch.svg",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/nandos.jpg",
      },
    ],
    link: {
      text: "St Peter’s Square, Highcross, Shires Lane, Leicester LE1 4FP",
      url: "https://www.google.com/maps/place/Nando's+Leicester+-+Highcross/@52.6365246,-1.1389691,19.25z/data=!3m1!5s0x487760e1c6f1611f:0x3257ceb6f948730!4m5!3m4!1s0x4877611e30a24e39:0x3ed92bd2f23c10a!8m2!3d52.6367064!4d-1.1392229?shorturl=1",
    },
  },

  {
    name: "Nando’s",
    active: false,
    position: {
      x: 3971,
      y: 3046,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.nandos.co.uk/restaurants/leicester-granby-street",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/Nandos.UnitedKingdom/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/nandosuk/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/NandosUK/",
      },
    ],
    content: `
    <div class="intro">
    <p>Afro-Portuguese chain restaurant serving flame-grilled chicken in spicy chilli sauce. Nando’s specialise in combining the universal comfort food of chicken with their legendary PERi-PERi marinade, resulting in a taste to fire the senses and feed the soul.</p>
      </div>
    `,
    icon: "lunch.svg",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/nandos.jpg",
      },
    ],
    link: {
      text: "50 Granby Street, Leicester LE1 1DH",
      url: "https://www.google.com/maps/place/Nando's+Leicester+-+Granby+Street/@52.6333889,-1.131769,17z/data=!3m2!4b1!5s0x48776118aa291215:0xfae8ce4938f5d86a!4m5!3m4!1s0x48776122075a4eb9:0x1a4e21a472be5f1c!8m2!3d52.6333889!4d-1.129575",
    },
  },

  {
    name: "Pizza Express",
    active: false,
    position: {
      x: 1898,
      y: 2046,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.pizzaexpress.com/leicester-highcross/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/pizzaexpress/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/pizzaexpress/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/pizzaexpress/",
      },
    ],
    content: `
    <div class="intro">
    <p>Pizza Express’ passion for Italian food can be traced back to founder Peter Boizot and his love affair with Italy. Unhappy with the pizza in London, he shipped over an authentic oven, knocked down a wall to squeeze it in, and opened the very first restaurant. Now you can enjoy authentic Italian pizza made from fresh ingredients. Classic dishes include the American Hot, Leggera Pizza and Dough Balls.</p>
      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/pizza-express.jpg",
      },
    ],
    link: {
      text: "18 Bath House Lane, Leicester LE1 4SA",
      url: "https://www.google.com/maps/place/Pizza+Express/@52.6362595,-1.1411641,17z/data=!3m2!4b1!5s0x487760e1c6f1611f:0x3257ceb7c9f4330!4m5!3m4!1s0x48776121b6c1470f:0x577b346e167c448e!8m2!3d52.6362595!4d-1.1389701",
    },
  },

  {
    name: "Tamatanga",
    active: false,
    position: {
      x: 2264,
      y: 2126,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.tamatanga.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/tamatanga/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/tamatanga/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/tamatanga/",
      },
    ],
    content: `
    <div class="intro">
    <p>Enjoy delicious Indian food made from fresh, local ingredients. Tamatanga grind their own spices by hand and hire expertly trained chefs to cook using a clay oven. Each dish is made as the order comes through, so it’ll come out as and when it’s ready to guarantee it is fresh, fabulous and flavoursome.
    Don’t miss the Asian inspired cocktail menu!
    </p>
      </div>
    `,
    icon: "lunch.svg",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/tamatanga.jpg",
      },
    ],
    link: {
      text: "1 Shires Lane, Leicester LE1 4AN",
      url: "https://www.google.com/maps/place//data=!4m2!3m1!1s0x487760e1cd35e3a7:0xce13955abe8afa30?source=g.page.share",
    },
  },

  {
    name: "Tandem",
    active: false,
    position: {
      x: 1857,
      y: 1994,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://tandem.restaurant/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TandemRest/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/tandem_rest/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/tandemrest/",
      },
    ],
    content: `
    <div class="intro">
    <p>Imagine a place where a rich heritage meets artistic expression. And the predictable is transformed into the simply remarkable. Where flavours true to India enrich the mind, body and soul.</p>

    <p>Tandem promises a multi-sensory feast. Indian food is as varied as it is delicious and Tandem embrace the diversity of traditional India by exploring the culinary history and foreign influences from across the South West regions, whilst retaining the true purpose of Indian food – to nourish the body and mind.</p>

      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/tandem.jpg",
      },
    ],
    link: {
      text: "59 Highcross Street, Leicester LE1 4PG",
      url: "https://www.google.com/maps/place/Tandem+Restaurant+Leicester/@52.6360973,-1.138392,18.5z/data=!3m1!5s0x487760e1c6f4ff5b:0xe724e0948c22f3c0!4m5!3m4!1s0x0:0x5d8e12b05e98f6ab!8m2!3d52.6364404!4d-1.1393248?shorturl=1",
    },
  },

  {
    name: "Turtle Bay",
    active: false,
    position: {
      x: 2057,
      y: 2049,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.turtlebay.co.uk/restaurants/leicester/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TurtleBayRestaurants/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/turtlebayuk/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/Turtlebayuk",
      },
    ],
    content: `
    <div class="intro">
    <p>From vegan to the ultimate meat jerk pit feast, there is something for everyone at Turtle Bay!</p>

    <p>Enjoy Rum, Reggae, Jerk and island vibes at this Caribbean theme restaurant, famous for its colourful menu, bottomless brunch and happy hour cocktails.</p>

      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/turtlebay.jpg",
      },
    ],
    link: {
      text: "15 Bath House Lane, Leicester LE1 4SA",
      url: "https://www.google.com/maps/place/Turtle+Bay/@52.6356783,-1.1383427,18.5z/data=!3m1!5s0x487760e1c6f1611f:0x3257ceb1bd47730!4m5!3m4!1s0x0:0xf62cc7166e74d00c!8m2!3d52.6361928!4d-1.1385541?shorturl=1",
    },
  },

  {
    name: "Yo!",
    active: false,
    position: {
      x: 1927,
      y: 1982,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://yosushi.com/restaurants/leicester-highcross/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/YOSushi/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/yosushi/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/yosushi/",
      },
    ],
    content: `
    <div class="intro">
    <p>Enjoy Japanese dishes taken from the belt or ordered from the open kitchen at this colourful eatery.</p>
      </div>
    `,
    icon: "lunch.svg",
    // brand: "in-logo-black.png",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/yo.jpg",
      },
    ],
    link: {
      text: "4 Highcross Lane, Leicester LE1 4SD",
      url: "https://www.google.com/maps/place/YO!+Leicester+Highcross/@52.6361295,-1.1382441,18.5z/data=!3m1!5s0x487760e1c6f1611f:0x3257ceb7c9f4330!4m5!3m4!1s0x487760e19bc02da7:0x46b436249bc3a6a1!8m2!3d52.636486!4d-1.1389168?shorturl=1",
    },
  },

  {
    name: "Zizzi",
    active: false,
    position: {
      x: 2027,
      y: 1997,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.zizzi.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/wearezizzi",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/wearezizzi",
      },
      {
        name: "twitter",
        url: "https://twitter.com/WeAreZizzi/",
      },
    ],
    content: `
    <div class="intro">
    <p>Delicious Italian food with a twist! There is something to surprise and delight all tastes, from pizza, pasta and risotto to salads and indulgent desserts.</p>
      </div>
    `,
    icon: "lunch.svg",
    // brand: "in-logo-black.png",
    categoryname: "lunch, dinner",
    category: ["lunch", "dinner"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/zizzi.jpg",
      },
    ],
    link: {
      text: "10 Highcross Lane, Leicester LE1 4SD",
      url: "https://www.google.com/maps/place/Zizzi/@52.6362574,-1.1382638,19.75z/data=!3m1!5s0x487760e1c6f1611f:0x3257ceb1bd47730!4m5!3m4!1s0x0:0xfc365d7782bed23c!8m2!3d52.6364459!4d-1.138319?shorturl=1",
    },
  },

  {
    name: "Sonrisa",
    active: false,
    position: {
      x: 2600,
      y: 2751,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://sonrisa.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/SonrisaArgentina/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/Sonrisa_BarRest/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/Sonrisa_BarRest",
      },
    ],
    content: `
    <div class="intro">
    <p>Sonrisa is a modern café-bar haunt that makes the perfect spot for social gatherings — Buenos Aires-style. Kick back with a cocktail, meet friends old and new, or order a strong cup of coffee.</p>

    <p>Enjoy Latin dishes and delights that tempt, tease and leave you hungry for more. Arrive early for a light, lingering lunch, or stay late into the night, grazing on the colourful sights, musical sounds, and small tapas-style plates.</p>

    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/sonrisa.jpg",
      },
    ],
    link: {
      text: "4 St Martins, Leicester LE1 5DB",
      url: "https://www.google.com/maps/place/Sonrisa+Comida+Argentina/@52.6342552,-1.1358915,21z/data=!4m5!3m4!1s0x487761c69890e0f7:0xdbf7533927026879!8m2!3d52.6342806!4d-1.1358152?shorturl=1",
    },
  },

  {
    name: "Pai",
    active: false,
    position: {
      x: 2755,
      y: 2503,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://pai.pizza/",
    socials: [
      {
        name: "instagram",
        url: "https://www.instagram.com/eat.pai/",
      },
    ],
    content: `
    <div class="intro">
    <p>Pizza lovers won’t want to miss out on PAI in St Martin’s Square. Serving up a small menu of pizza perfection, you can enjoy everything from a classic cheese and tomato to the intriguing Mint Chutney and Paneer pizza.</p>

    </div>
    `,
    icon: "lunch.svg",
    // brand: "in-logo-black.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner", "independant"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/pai.jpg",
      },
    ],
    link: {
      text: "2-6 St Martins Walk, Leicester LE1 5DG",
      url: "https://www.google.com/maps/place/PAI/@52.6348608,-1.1373053,17z/data=!3m1!4b1!4m5!3m4!1s0x487761ba13fbf60d:0x2424588e2306e80b!8m2!3d52.6348608!4d-1.1351166",
    },
  },

  {
    name: "Wok Indo",
    active: false,
    position: {
      x: 2175,
      y: 2116,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.wokindo.co.uk",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/wok_indo",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/wokindo/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/wok_indo/",
      },
    ],
    content: `
    <div class="intro">
    <p>We are a Pan Asian fusion cuisine restaurant serving Indo-chinese food in a nice fresh modern setting. We offer 2 menus on site (a la carte and a layered wok-box menu for those on the go). 
    </p>

    </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/wokindo_1.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/wokindo_2.jpg",
      },
    ],
    link: {
      text: "Highcross 12, Shires Lane, LE1 4AN",
      url: "https://www.google.com/maps/place/Wok+indo+Highcross/@52.6360775,-1.139953,17z/data=!3m1!4b1!4m5!3m4!1s0x4877610f4fe6b8c1:0x8c0518e7219e17f4!8m2!3d52.6360775!4d-1.1377643",
    },
  },

  /**************************************/
  /**************************************/
  /********* Category: Cafes ***********/
  /**************************************/
  /**************************************/

  {
    name: "Gelato Village",
    active: false,
    position: {
      x: 2771,
      y: 2641,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.gelatovillage.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/GelatoVillageLeicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/gelatovillage/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/Gelato_Village/",
      },
    ],
    content: `
    <div class="intro">
      <p>Gelato Village has a huge number of fans in Leicester, and deservedly so. Opened in 2014 by two Italians, the gelataria offers authentic Italian gelato with a unique sense of place. Leicestershire produce used at
      Gelato Village includes milk from Vine Farm Dairy and honey from The Bee Farmer.
      Gelato varieties which give a nod to Leicester’s heritage include Alice Hawkins
      (lavender and white chocolate) and Richard III (fruits of the forest with homemade rose blossom essence).
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, sweet treat, independant",
    category: ["cafe", "sweet treat", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/gelato_village.jpg",
      },
    ],
    link: {
      text: "2 St Martins Square, Leicester LE1 5DF",
      url: "http://maps.google.com/?q=GelatoVillage 2 St Martins Square, Leicester LE1 5DF",
    },
  },

  {
    name: "St Martin’s Tea & Coffee Merchants",
    active: false,
    position: {
      x: 2808,
      y: 2550,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.stmartinscoffeeshop.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/stmartinscoffeeshop/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/stmartinscoffeeshop/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/Gelato_Village/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      The aroma of St Martin’s freshly roasted beans greets you as you enter this friendly café with its rustic furniture and homely touches. And it’s not just delicious coffee on the changing seasonal menu. Loose leaf tea, pancakes, sandwiches and freshly baked cakes are all hits with the café’s regulars, who range from professionals on their lunch break to students
      and families.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/stmartins_coffee.jpg",
      },
    ],
    link: {
      text: "6 St Martins Walk, Leicester LE1 5DG",
      url: "http://maps.google.com/?q= 6 St Martins Walk, Leicester LE1 5DG",
    },
  },

  {
    name: "Merchant of Venice",
    active: false,
    position: {
      x: 4544,
      y: 3528,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.merchantofvenice.co/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/merchantofveniceitalian/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/merchant_of_venice_italian/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/Gelato_Village/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      With a Shakespeare theme and an Italian light bites menu, The Merchant of Venice is one of the cosiest coffee spots in town. It’s prime location opposite the train station makes it a great stop on the way in to the city, and the swing seats in the windows are an Instagram must in the city.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    // brand: "in-logo-black.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant"],
    carousel: [
      {
        type: "image",
        content: "cafe/merchantofvenice.jpg",
      },
    ],
    link: {
      text: "173-175 Granby Street, Leicester LE1 6FE",
      url: "https://www.google.com/maps/place/Merchant+of+Venice/@52.6319514,-1.1289475,17z/data=!3m1!4b1!4m5!3m4!1s0x487761d130152f5d:0x9d2599e9091ed9fa!8m2!3d52.6319115!4d-1.1268094?shorturl=1",
    },
  },

  {
    name: "Prana Cafe",
    active: false,
    position: {
      x: 3397,
      y: 2780,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.pranacafe.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/pranacafeleicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/pranacafe.11/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/Gelato_Village/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Prana Café is a luxury plant-based coffee house offering organic wholefoods with vegan and gluten-free options. Diners can relax in an elegant Grade II-listed building with ornate high ceilings while choosing from sandwiches prepared on-site
      using artisan bread, salads packed with superfoods and gorgeous cakes made with
      organic ingredients and unrefined sugar.    
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/prana_1.jpg",
      },
      {
        type: "image",
        content: "cafe/prana_2.jpg",
      },
    ],
    link: {
      text: "10 Horsefair Street, Leicester LE1 5BN",
      url: "http://maps.google.com/?q=Prana 10 Horsefair Street, Leicester LE1 5BN",
    },
  },

  {
    name: "200 Degrees Coffee",
    active: false,
    position: {
      x: 3108,
      y: 3155,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://200degs.com/leicester-market-street/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/200Degs/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/200degs/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/200Degs/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Like its name suggests, the coffee experts at 200 Degrees Coffee believe in hand-roasting beans at 200 degrees centigrade for maximum flavour. As well as coffee, a deli-style menu offers delicious locally-sourced food, hand-prepared on the premises. 
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/200degs1.jpg",
      },
      {
        type: "image",
        content: "cafe/200degs2.jpg",
      },
    ],
    link: {
      text: "10-12 Market Street, Leicester LE1 6DP",
      url: "http://maps.google.com/?q=200Degrees 10-12 Market Street, Leicester LE1 6DP",
    },
  },

  {
    name: "Bread & Honey",
    active: false,
    position: {
      x: 3238,
      y: 3655,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://breadnhoneycoffee.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/breadandhoneyleicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/breadnhoneyle1/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/BreadnHoneyLE1/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Enjoy quality coffee and fresh food every day, including soups, sandwiches, cakes and sweet treats.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    // brand: "in-logo-black.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant"],
    carousel: [
      {
        type: "image",
        content: "cafe/breadhoney.jpg",
      },
      // {
      //   type: "image",
      //   content: "cafe/200degs2.jpg",
      // },
    ],
    link: {
      text: "15 King Street, Leicester LE1 6RN",
      url: "https://www.google.com/maps/place/Bread+%26+Honey/@52.6316025,-1.1348537,17z/data=!3m2!4b1!5s0x48776121b5d12143:0xd62ab7ee635edd7a!4m5!3m4!1s0x48776121b049b13d:0x5ca9f64fe6cb1437!8m2!3d52.6316025!4d-1.132665",
    },
  },

  {
    name: "The Coffee Counter",
    active: false,
    position: {
      x: 3202,
      y: 3175,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://thecoffeecounter.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TheCoffeeCounterLeicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/thecoffeecounteruk/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/coffeecounteruk",
      },
    ],
    content: `
    <div class="intro">
      <p>
      The Coffee Counter is a speciality coffee roastery based in the heart of Leicester, roasting far and wide for the wholesale and home user coffee community. Their café on Bowling Green Street is the perfect place to sample their selection, along with a selection of fresh food from morning pastries to grilled cheese and avocado on toast.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    // brand: "in-logo-black.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant"],
    carousel: [
      {
        type: "image",
        content: "cafe/coffeecounter.jpg",
      },
      // {
      //   type: "image",
      //   content: "cafe/200degs2.jpg",
      // },
    ],
    link: {
      text: "23 Bowling Green Street, Leicester LE1 6AS",
      url: "https://www.google.com/maps/place/The+Coffee+Counter+-+Leicester+Coffee+Roasters/@52.633004,-1.13509,17z/data=!3m1!4b1!4m5!3m4!1s0x48776121d4585a11:0x6fff37d5e2a8c6ff!8m2!3d52.6329964!4d-1.1329145?shorturl=1",
    },
  },

  {
    name: "Leicester Coffee House Company",
    active: false,
    position: {
      x: 4380,
      y: 3285,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.leicestercoffeehouse.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/LeicesterCoffeeHouse",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/leicestercoffee/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/leicestercoffee/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      <p>Leicester Coffee House Company combines good quality, ethically sourced, locally roasted coffee with supported learning opportunities for young people.</p>

      <p>Enjoy the finest beans, freshly baked cakes and treats – vegan options available – and don’t forget to say hi to the friendly resident dog, Tommo.</p>

      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, independant",
    category: ["cafe", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/coffeehousecompany.jpg",
      },
      // {
      //   type: "image",
      //   content: "cafe/200degs2.jpg",
      // },
    ],
    link: {
      text: "110 Granby Street, Leicester LE1 1DL",
      url: "https://www.google.com/maps/place/Leicester+Coffee+House+Company/@52.6325697,-1.1298071,17z/data=!3m1!4b1!4m5!3m4!1s0x48776138cf84c013:0x4fdffcac12639dd1!8m2!3d52.6325839!4d-1.1276039",
    },
  },

  {
    name: "Mrs Bridges Tearooms",
    active: false,
    position: {
      x: 2593,
      y: 2500,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.mrsbridgestearooms.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/bridgestea/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/mrsbridgesleicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/bridgestea/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Built in 1723 in the heart of Leicester’s historical Lanes area, the quaint Mrs Bridges is perfect for a cream tea after a busy day of shopping or visiting Leicester’s attractions. Stained glass windows and a lovely garden add to the charming atmosphere of this café with its varied menu of hearty sandwiches, afternoon tea, traditional breakfasts and
      hot lunches.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/mrsbridges_1.jpg",
      },
      {
        type: "image",
        content: "cafe/mrsbridges_2.jpg",
      },
    ],
    link: {
      text: "17 Loseby Lane, Leicester LE1 5DR",
      url: "http://maps.google.com/?q=Bridges 17 Loseby Lane, Leicester LE1 5DR",
    },
  },

  {
    name: "Kai",
    active: false,
    position: {
      x: 2703,
      y: 2505,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://kai.kitchen/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/bridgestea/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/eat.kai/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/bridgestea/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Offering classic brunch fare, coffee and cocktails in a cosy
      space with outdoor seating available, Kai also promises organic and free-range ingredients - and amazing
      pancakes. Start the day with a breakfast cocktail of Mimosa,
      Bloody Mary or Tia Maria Latte to accompany your Eggs
      Benedict, Royale or Florentine or tuck into a healthy and hearty lunch of a Curry Bowl, Kai Bowl or light bites including Gobi Manchurian.     
      </p>
      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, independant",
    category: ["lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/kai_1.jpg",
      },
      {
        type: "image",
        content: "cafe/kai_2.jpg",
      },
    ],
    link: {
      text: "4 St Martins Square, Leicester LE1 5DF",
      url: "http://maps.google.com/?q=Kai 4 St Martins Square, Leicester LE1 5DF",
    },
  },

  {
    name: "Grays Coffee Shop & Kitchen",
    active: false,
    position: {
      x: 4313,
      y: 2565,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://graysleicester.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/grayslcb/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/grays_lcbdepot/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/grays_lcbdepot",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Based in the city’s Cultural Quarter, Grays offers fresh
      choices, giving your pallet a chance to experiment. The
      chefs offer a choice of breakfast variations before their daily pilgrimage to Leicester Market to see what’s in season for the day’s menu. There’s plenty on offer for
      vegetarians and vegans as well as a range of quality  coffee and tea.          
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/grays1.jpg",
      },
      {
        type: "image",
        content: "cafe/grays2.jpg",
      },
      {
        type: "image",
        content: "cafe/grays3.jpg",
      },
    ],
    link: {
      text: "31 Rutland Street, Leicester LE1 1RE",
      url: "http://maps.google.com/?q=Grays 31 Rutland Street, Leicester LE1 1RE",
    },
  },

  {
    name: "Awesome Chips",
    active: false,
    position: {
      x: 3323,
      y: 1725,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://awesomechips.uk/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/grayslcb/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/awesomechips_uk/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/grays_lcbdepot",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Who doesn’t love chips? Especially when they’re freshly cut and twice fried, resulting in
      crispy outsides with fluffy insides. The Belgian-style fries and sweet potato chips come with original and Dutch sauces including Mango Chilli Lime, Blue
      Cheese, Vegan Cheese and Coronation Curry. There’s also a huge choice of tasty powders to make your chips even more
      Awesome.             
      </p>
      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, independant",
    category: ["lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/awsome_chips.jpg",
      },
    ],
    link: {
      text: "Haymarket, Leicester LE1 3GD",
      url: "http://maps.google.com/?q=AwesomeChips Haymarket, Leicester LE1 3GD",
    },
  },

  {
    name: "Chloe Gourmet",
    active: false,
    position: {
      x: 2923,
      y: 2519,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.chloegourmet.co.uk/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/grayslcb/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/chloegourmet/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/grays_lcbdepot",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Chloe Gourmet is an independent French patisserie and coffee shop. They import ingredients from France and follow traditional pastry practises to ensure their offering is as authentic as possible. The team also offer services of French catering for your weddings, receptions, baby showers, anniversaries and special occasions.        
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, independant",
    category: ["cafe", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/chloegourmet.jpg",
      },
    ],
    link: {
      text: "10-12 Cank St, Leicester LE1 5GW",
      url: "http://maps.google.com/?q=ChloeGourmet 10-12 Cank St, Leicester LE1 5GW",
    },
  },

  {
    name: "Detox Shack",
    active: false,
    position: {
      x: 3153,
      y: 3229,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.thedetoxshack.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/detoxshack/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/the_detox_shack/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/grays_lcbdepot",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Looking for something healthy and unique? The Detox Shack promises a menu of natural, fresh, locally-sourced ingredients. Raw juices, smoothies, liquid lunches and shakes are made with delicious and nutritious ingredients including beetroot, lemon, apple, spinach, cucumber, ginger, kale, passion fruit, avocado, berries and almond milk. Tasty salads, wraps and toasts are filling options while a banoffee or salted caramel flapjack could be just the pick-me-up you need.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/detoxshack.jpg",
      },
    ],
    link: {
      text: "26A Market Street, Leicester LE1 6DP",
      url: "http://maps.google.com/?q=DetoxShack 26A Market Street, Leicester LE1 6DP",
    },
  },

  {
    name: "La Foret Café",
    active: false,
    position: {
      x: 3253,
      y: 3599,
    },
    transform: {
      width: 50,
      height: 50,
    },
    // website: "https://www.thedetoxshack.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/laforetcafeuk/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/la_foretcafe/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/grays_lcbdepot",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      A fusion of patisserie classics inspired from around the world, including Mediterranean, Asian and European influences. The café has options for different tastes and diets, with vegan and halal options. Enjoy house salads like the Moroccan cous cous salad, speciality sandwiches like the harissa turkey baguette and, of course, delicious sweet and pastry treats.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/la_foret_cafe.jpg",
      },
      {
        type: "image",
        content: "cafe/la_foret_cafe_2.jpg",
      },
    ],
    link: {
      text: "11 King Street, Leicester LE1 6RH",
      url: "http://maps.google.com/?q=LaForetCafé 11 King Street, Leicester LE1 6RH",
    },
  },

  {
    name: "Just So Italian",
    active: false,
    position: {
      x: 2672,
      y: 2481,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.justsoitalian.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/justsoitalian/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/justsoitalian/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/grays_lcbdepot",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      For a true taste of Italy, come and visit Just So Italian deli in St Martin’s Square.
      Be transported to Italy as you step inside. It is the perfect place to relax with an Italian coffee, sample some wonderful freshly made pizza or focaccia or treat yourself to one of our delicious Italian pastries. One this is guaranteed: you'll be spoilt for choice.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/justsoitalian.jpg",
      },
      // {
      //   type: "image",
      //   content: "cafe/la_foret_cafe_2.jpg",
      // },
    ],
    link: {
      text: "14 St Martins Square, Leicester LE1 5DF",
      url: "https://www.google.com/maps/place/Just+so+Italian+Leicester/@52.6348582,-1.1353386,15z/data=!4m5!3m4!1s0x0:0x97f4576dbe05e4d8!8m2!3d52.6348582!4d-1.1353386?shorturl=1",
    },
  },

  {
    name: "Roma Café Bar and Restaurant",
    active: false,
    position: {
      x: 3773,
      y: 2639,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.romaleicester.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/romacafebar",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/la_foretcafe/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/grays_lcbdepot",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Roma cafe bar and restaurant opened in 1996. This family run cafe and restaurant serves everything from home made cakes, coffee and panini to pasta, pizza and a wide variety of traditional Italian dishes.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, dinner, independant",
    category: ["cafe", "lunch", "dinner" ,"independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/romacafe.jpg",
      },
    ],
    link: {
      text: "11 Halford Street, Leicester LE1 1JA",
      url: "http://maps.google.com/?q=RomaCafé 11 Halford St, Leicester LE1 1JA",
    },
  },

  {
    name: "Bryter Moon Deli",
    active: false,
    position: {
      x: 3043,
      y: 2332,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.brytermoon.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/brytermoonx/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/brytermoondeli/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/brytermoondeli/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Bryter Moon is a little cosy deli, tucked away in the silver arcade. From the mis-matched furniture to the bunting, fairy lights and vintage games console and board games for diners to enjoy – it is the little things that make Bryter Moon a welcoming place to relax. Enjoy a large range of cheeses and antipasti, delicious coffees and teas as well as a full fresh menu that includes vegan and vegetarian options. From hot and cold sandwiches to wraps, salads, specials and sweet treats, there’s plenty of choice for any appetite.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/brytermoondeli.jpg",
      },
    ],
    link: {
      text: "Unit 1 Silver Street, Leicester LE1 5FA",
      url: "http://maps.google.com/?q=BryterMoonDeli Unit 1 Silver Street, Leicester LE1 5FA",
    },
  },

  {
    name: "Café No. 7",
    active: false,
    position: {
      x: 2143,
      y: 2662,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://leicestercathedral.org/cafe/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/brytermoonx/",
      // },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/brytermoondeli/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/brytermoondeli/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Situated in the heart of Leicester’s historic quarter in St Martins House – next door to Leicester Cathedral, The Guildhall, and a minute’s walk from The King Richard III Visitor Centre – Café No. 7 is the go-to venue for light bites and refreshments, offering a wide range of delicious food and beverages from their position overlooking the Cathedral Gardens.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/whiterosecafe.jpg",
      },
    ],
    link: {
      text: "Peacock Lane, Leicester LE1 5LB",
      url: "http://maps.google.com/?q=WhiteRoseCafe Peacock Lane, Leicester LE1 5LB",
    },
  },

  {
    name: "Orso",
    active: false,
    position: {
      x: 2950,
      y: 2702,
    },
    transform: {
      width: 50,
      height: 50,
    },
    // website: "https://leicestercathedral.org/cafe/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/ORSOLeicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/orsoleicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/leicesterorso/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Pop along to this bright and homely café for a variety of coffees, flavoured teas and hot chocolate. If you’re after a spot of lunch, enjoy their menu of fresh food options and delicious cakes!
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/orso.jpg",
      },
    ],
    link: {
      text: "4 Market Place, Leicester LE1 5GF",
      url: "https://www.google.com/maps/place/ORSO+Leicester/@52.6342769,-1.1344273,15z/data=!4m5!3m4!1s0x0:0xb21636a8ddb314a8!8m2!3d52.6342725!4d-1.1344235?shorturl=1",
    },
  },

  {
    name: "The Olive Greek Street Food",
    active: false,
    position: {
      x: 3579,
      y: 3182,
    },
    transform: {
      width: 50,
      height: 50,
    },
    // website: "https://leicestercathedral.org/cafe/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/theolivegreekstreetfood/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/theolivegreekstreetfood/ ",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/leicesterorso/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Get an authentic taste of Greece at The Olive Greek Street Food. From street-food classic gyros, spanakopita and haloumi fries to more filling options like Moussaka, Falafel, Souvlaki and Keftedes, you’ll feel like you’re on holiday as you tuck in.  Don’t forget a side of olives and a baklava for desert for that real Mediterranean feel!
      </p>
      </div>
    `,
    icon: "cafe.svg",
    // brand: "in-logo-black.png",
    categoryname: "cafe, lunch, dinner, independant",
    category: ["cafe", "lunch", "dinner", "independant"],
    carousel: [
      {
        type: "image",
        content: "cafe/olivegreek.jpg",
      },
    ],
    link: {
      text: "29 Belvoir Street, Leicester LE1 6SL",
      url: "https://www.google.com/maps/place//data=!4m2!3m1!1s0x487761079c3b4305:0x93f86ec2e9697201?source=g.page.share",
    },
  },

  {
    name: "Esquires Coffee",
    active: false,
    position: {
      x: 3590,
      y: 4032,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://esquirescoffee.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/esquirescoffeeUK/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/esquirescoffee_uk/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/esquirescoffee/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Established in 1993, Esquires aims to be a leading ethical coffee franchise. The café serves up speciality Fairtrade and organic coffees and teas as well as delicious food for breakfast, brunch, lunch and afternoon tea.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/esquires.jpg",
      },
    ],
    link: {
      text: "The Circle, 5 New Walk, Leicester LE1 6RU",
      url: "https://www.google.com/maps/place/Esquires+Coffee/@52.6306661,-1.1333723,17z/data=!3m1!4b1!4m5!3m4!1s0x4877617577de53f7:0xef6e18966fb79cad!8m2!3d52.6306661!4d-1.1311836",
    },
  },

  {
    name: "Tegtat",
    active: false,
    position: {
      x: 3848,
      y: 1912,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.tegtat.com/en/Default.aspx",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/tegtatuk/",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/esquirescoffee_uk/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/esquirescoffee/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Delicious food and pastries inspired by authentic home cooking in Turkey. Enjoy traditional Turkish sweet and savoury pastries, sandwiches and desserts in a relaxed setting. Wash down these delicious treats with freshly ground Turkish coffee or a selection of Turkish teas.
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/tegtat.jpg",
      },
    ],
    link: {
      text: "40-42 Humberstone Gate, Leicester LE1 1WA",
      url: "https://www.google.com/maps/place/Tegtat/@52.6365299,-1.1321968,17z/data=!3m2!4b1!5s0x487761192d248817:0x653719a4e75655ad!4m5!3m4!1s0x4877611437a86a23:0xe64d2e1a1076ca4a!8m2!3d52.6365299!4d-1.1300081?shorturl=1",
    },
  },

  {
    name: "World Peace Café",
    active: false,
    position: {
      x: 2321,
      y: 2455,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://meditateinleicester.org/world-peace-cafe-2/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/worldpeacecafeleicester/",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/esquirescoffee_uk/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/esquirescoffee/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      A vegan and vegetarian café run by international volunteers. The café aims to offer a peaceful setting in which people can come to enjoy tasty vegan and vegetarian food, or just relax with a coffee and cake. Look out for their programme of special dinner events – Meal & Meditation – which offer a three course menu inspired by a world cuisine along with a meditation session. 
      </p>
      </div>
    `,
    icon: "cafe.svg",
    // brand: "in-logo-black.png",
    categoryname: "cafe, lunch, independant",
    category: ["cafe", "lunch", "independant"],
    carousel: [
      {
        type: "image",
        content: "cafe/worldpeacecafe.jpg",
      },
    ],
    link: {
      text: "17 Guildhall Lane, Leicester LE1 5FQ",
      url: "https://www.google.com/maps/place/World+Peace+Cafe/@52.6350187,-1.139179,17z/data=!3m1!4b1!4m5!3m4!1s0x487760e026b3cca3:0xc0159d9c5cfd07dc!8m2!3d52.6350187!4d-1.1369903",
    },
  },

  {
    name: "Almeida Coffee & Juice Bar",
    active: false,
    position: {
      x: 2425,
      y: 2427,
    },
    transform: {
      width: 50,
      height: 50,
    },
    // website: "https://meditateinleicester.org/world-peace-cafe-2/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/worldpeacecafeleicester/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/almeidacoffee/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/esquirescoffee/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Almeida is Leicester’s original juice bar, open since 1999. The café has an extensive food menu, all of which is made fresh to order with locally sourced ingredients where possible. From panini, wraps and bagels to couscous and sardines on toast, you’ll be spoilt for choice! 
      </p>
      </div>
    `,
    icon: "cafe.svg",
    brand: "giftcard.png",
    categoryname: "cafe, independant",
    category: ["cafe", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/almedia.jpg",
      },
    ],
    link: {
      text: "5 Guildhall Lane, Leicester LE1 5FQ",
      url: "https://www.google.com/maps/place/Almeida+Coffee+%26+Juice+Bar/@52.635081,-1.1387744,17z/data=!3m1!4b1!4m5!3m4!1s0x487760e026efc7f3:0x3cd478a24a5b12c8!8m2!3d52.635081!4d-1.1365857",
    },
  },

  {
    name: "That’s Amore – Italian Bar & Restaurant",
    active: false,
    position: {
      x: 3348,
      y: 3633,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://thatsamoreleicester.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/thatsamoreleicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/thatsamoreleicester",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/leicesterorso/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Enjoy authentic, home made Italian food in the heart of Leicester. From fresh bruschetta to classic pasta, risotto and secondi dishes full of Italian flavours, you’re sure to find something to delight your tastebuds. There’s a great selection of cocktails, wines and spirits and you can finish it all off with a delicious tiramisu. 
      </p>
      </div>
    `,
    icon: "lunch.svg",
    // brand: "in-logo-black.png",
    categoryname: "lunch, dinner, pubs & bars, independant",
    category: ["lunch", "dinner", "pubs bars", "independant"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/thats-amore.jpg",
      },
    ],
    link: {
      text: "2 King Street, Leicester LE1 6RH",
      url: "https://www.google.com/maps/place/That's+Amore+-+Italian+Bar+%26+Restaurant/@52.6317869,-1.1346576,17z/data=!3m1!4b1!4m5!3m4!1s0x48776157592b1087:0xc5e88e1910af33d!8m2!3d52.6317869!4d-1.1324689",
    },
  },

  {
    name: "The Parcel Yard & Club 180 Darts",
    active: false,
    position: {
      x: 4801,
      y: 3687,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://steamin-billy.co.uk/foodpubs/parcelyard/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/parcelyard/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/theparcelyardsb/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/theparcelyardsb/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      <p>Right next door to Leicester Train Station, The Parcel Yard’s name takes its inspiration from the building’s original purpose as a sorting office and parcel yard for British rail. Visit and enjoy relaxed surroundings, with a quality menu with something for everyone – all day brunch, coffees, fantastic lunches and evening meals.</p>

      <p>Whilst you’re there why not check out Club 180 Darts? Drink great craft beer, sip delicious cocktails and hit a few 180s with automated scoreboards, enough space for up to 12 players per oche and great food – this is darts, but not as you know it.</p>

      </p>
      </div>
    `,
    icon: "lunch.svg",
    brand: "giftcard.png",
    categoryname: "lunch, dinner, pubs & bars, activities, independant",
    category: ["lunch", "dinner", "pubs bars", "activities", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/parcelyard.jpg",
      },
    ],
    link: {
      text: "48A London Road, Leicester LE2 0QB",
      url: "https://www.google.com/maps/place/The+Parcel+Yard/@52.6315079,-1.1277061,17z/data=!3m2!4b1!5s0x48776122e9368c77:0x24d9a55ca13a83ce!4m5!3m4!1s0x48776122dc2b90ad:0x15ae422d97778786!8m2!3d52.6315079!4d-1.1255174?shorturl=1",
    },
  },

  {
    name: "Kayal",
    active: false,
    position: {
      x: 4463,
      y: 3459,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.kayalrestaurant.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/Kayal-Restaurants-142746732742345/",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/thatsamoreleicester",
      // },
      {
        name: "twitter",
        url: "https://twitter.com/kayalrestaurant/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Authentic Kerala seafood is served up at this family run restaurant with a passion for good food. From delicious dosas to fish, meat and vegetarian curries, there’s flavourful food for every taste. 
      </p>
      </div>
    `,
    icon: "lunch.svg",
    // brand: "in-logo-black.png",
    categoryname: "lunch, dinner, independant",
    category: ["lunch", "dinner", "independant"],
    carousel: [
      {
        type: "image",
        content: "lunch_dinner/kayal.jpg",
      },
    ],
    link: {
      text: "153 Granby Street, Leicester LE1 6FE",
      url: "https://www.google.com/maps/place/Kayal/@52.6321831,-1.1293143,17z/data=!4m12!1m6!3m5!1s0x487761226133dfb3:0x6f2c3b2732017fa3!2sKayal!8m2!3d52.6322222!4d-1.1272222!3m4!1s0x487761226133dfb3:0x6f2c3b2732017fa3!8m2!3d52.6322222!4d-1.1272222",
    },
  },

  /**************************************/
  /**************************************/
  /****** Category: Pubs & Bars ********/
  /**************************************/
  /**************************************/

  {
    name: "Brick & Beam",
    active: false,
    position: {
      x: 4863,
      y: 2400,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.brickandbeam.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/brickandbeamUK/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/brickandbeamuk/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/brickandbeamuk",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Brick & Beam is a unique upper floor bar with
      terrace, where you can relax with a cocktail. It has an ‘industrial chic’ style,
      with exposed brickwork and metal beams in the stunning apex ceiling and
      regular live music.                
      </p>
      </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "pubs & bars, entertainment, independant",
    category: ["pubs bars", "entertainment", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/brickbeam.jpg",
      },
    ],
    link: {
      text: "12 Queen Street, Leicester LE1 1QW",
      url: "http://maps.google.com/?q=BrickandBeam 12 Queen Street, Leicester LE1 1QW",
    },
  },

  {
    name: "The King’s Head",
    active: false,
    position: {
      x: 3492,
      y: 4020,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.blackcountryales.co.uk/pubs/the-kings-head",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/Thekingsleicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/kingsleicester/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/brickandbeamuk",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      A multi award-winning real ale pub in a beautiful Georgian building. Serving the finest cask, craft and ciders. Cosy round the fireplace in winter or enjoy the sun on one of Leicester’s little-know terraces. Catch the latest sporting action on large screens both inside and outside.              
      </p>
      </div>
    `,
    icon: "pubs_bars.svg",
    // brand: "in-logo-black.png",
    categoryname: "pubs & bars",
    category: ["pubs bars"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/kingshead.jpg",
      },
    ],
    link: {
      text: "36 King Street, Leicester LE1 6RL",
      url: "https://www.google.com/maps/place/The+King's+Head/@52.6331116,-1.2709403,11z/data=!4m5!3m4!1s0x48776121a76dcb57:0x16acc844c8440bb1!8m2!3d52.630641!4d-1.1318717",
    },
  },

  {
    name: "33 Cank Street",
    active: false,
    position: {
      x: 2782,
      y: 2595,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.bar33cankstreet.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/bar33cankstreet/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/bar33cankstreet/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/brickandbeamuk",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Leicester’s first and best table service cocktail bar. Serving up your personal favourites in the cosy heart of the old town. Boasting the biggest selection of classic cocktails in the midlands, accompanied by an ever changing collection of boutique in-house creations - along with regular live music evenings.
      </p>
      </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "pubs & bars",
    category: ["pubs bars", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/33cankstreet.jpg",
      },
    ],
    link: {
      text: "33 Cank Street, Leicester LE1 5GX",
      url: "https://www.google.com/maps/place/33+Cank+St,+Leicester+LE1+5GX/@52.6345611,-1.1352769,19.75z/data=!4m5!3m4!1s0x4877611fb088fdb5:0x6942796492fa9de4!8m2!3d52.6345974!4d-1.1350002?shorturl=1",
    },
  },

  {
    name: "The Turkey Café - 1901",
    active: false,
    position: {
      x: 3693,
      y: 2814,
    },
    transform: {
      width: 50,
      height: 50,
    },
    // website: "https://www.blackcountryales.co.uk/pubs/the-kings-head",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TurkeyCafe1901/",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/kingsleicester/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/brickandbeamuk",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Enjoy mouthwatering cocktails in this iconic Grade II listed Art Nouveau style building. It’s not only the drinks that keep customers coming back – look out for regular live music, open mic and comedy nights.              
      </p>
      </div>
    `,
    icon: "pubs_bars.svg",
    // brand: "in-logo-black.png",
    categoryname: "pubs & bars, independant",
    category: ["pubs bars", "independant"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/turkeycafe.jpg",
      },
    ],
    link: {
      text: "24 Granby Street, Leicester LE1 1DE",
      url: "https://www.google.com/maps/place/The+Turkey+Cafe+-+1901/@52.6339743,-1.1328726,17z/data=!3m2!4b1!5s0x4877611f4fe42e39:0x4c20d5fa630ddc04!4m5!3m4!1s0x4877611f4e535d87:0x9d041eb551a60303!8m2!3d52.6339743!4d-1.1306839?shorturl=1",
    },
  },

  {
    name: "The Ale Wagon",
    active: false,
    position: {
      x: 4133,
      y: 2764,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.alewagon.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/alewagon/",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/kingsleicester/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/brickandbeamuk",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      The Ale Wagon offers a diverse range of Real Ales. Owned by the Hoskins family who have been a part of Leicester beer for more than 100 years.              
      </p>
      </div>
    `,
    icon: "pubs_bars.svg",
    // brand: "in-logo-black.png",
    categoryname: "pubs & bars, independant",
    category: ["pubs bars","independant"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/alewagon.jpg",
      },
    ],
    link: {
      text: "27 Rutland Street, Leicester LE1 1RE",
      url: "https://www.google.com/maps/place/The+Ale+Wagon/@52.6342039,-1.1310073,17z/data=!3m2!4b1!5s0x48776118bc0b6bb1:0x11e3d906adb2f91c!4m5!3m4!1s0x48776118bc7f2c59:0xc21ed8e7d85238d4!8m2!3d52.6342039!4d-1.1288186?shorturl=1",
    },
  },

  {
    name: "The Gadabout",
    active: false,
    position: {
      x: 2413,
      y: 2520,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://thegadabout.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/thegadaboutbar/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/thegadaboutbar/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/brickandbeamuk",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      The cocktail menu at The Gadabout is inspired by world travel – intrepid explorers can circumnavigate the globe, savouring flavours from every corner and culture. If cocktails aren’t your thing you can enjoy a selection of beers, wines and spirits in one of the city’s oldest buildings.              
      </p>
      </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "pubs & bars, independant",
    category: ["pubs bars","independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/gadabout.jpg",
      },
    ],
    link: {
      text: "17 Guildhall Lane, Leicester LE1 5FQ",
      url: "https://www.google.com/maps/place/The+Gadabout/@52.6349345,-1.1388076,17z/data=!3m1!4b1!4m5!3m4!1s0x487760e02698b229:0xce0fcc1323e77b5b!8m2!3d52.6349345!4d-1.1366189",
    },
  },

  {
    name: "Manhattan 34",
    active: false,
    position: {
      x: 4243,
      y: 2520,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.manhattan34.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/Manhattan34/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/manhattan_34/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/manhattan_34/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Manhattan 34 is a
      1930s-themed cocktail bar, which offers an
      impressive cocktail menu, developed in-house by
      the bar’s expert team, as well as a range of premium
      spirits, wines and soft drinks. Look out for live comedy, live music and quiz nights, or hire their downstairs bar area for your own events.                  
      </p>
      </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "pubs & bars, independant",
    category: ["pubs bars","independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/manhattan.jpg",
      },
    ],
    link: {
      text: "34 Rutland Street, Leicester LE1 1RD",
      url: "http://maps.google.com/?q=Manhattan34 34 Rutland Street, Leicester LE1 1RD",
    },
  },

  {
    name: "The Exchange",
    active: false,
    position: {
      x: 4309,
      y: 2482,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://theexchangebar.co.uk/about/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/theexchangebarleics/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/theexchangebar_/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/manhattan_34/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Right opposite Curve, The Exchange is a
      popular place for a drink. It is open all hours - from breakfast to evening, serving coffees and a delicious food menu during the day through to an extensive drinks menu and nibbles to share in the evening. 
      </p>
      </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "pubs & bars, independant",
    category: ["pubs bars", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/exchangebar_1.jpg",
      },
      {
        type: "image",
        content: "pubs_bars/exchangebar_2.jpg",
      },
      {
        type: "image",
        content: "pubs_bars/exchangebar_3.jpg",
      },
      {
        type: "image",
        content: "pubs_bars/exchangebar_4.jpg",
      },
    ],
    link: {
      text: "50 Rutland Street, Leicester LE1 1RD",
      url: "http://maps.google.com/?q=TheExchange 50 Rutland Street, Leicester LE1 1RD",
    },
  },

  {
    name: "The Bottle Garden",
    active: false,
    position: {
      x: 2629,
      y: 2403,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.thebottlegardenbar.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/thebottlegardenbar/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/thebottlegardenbar/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/manhattan_34/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      You can feel like you’re sipping your drink al fresco with the warmth of being inside in this uber-cool bar that feels like you’re in a trendy urban garden. Sit amongst lush greenery, trees and herbs in pots and even plants hanging from the ceiling, all lit with funky lighting. 
      </p>
      <p>
      Drinks are tailored to the garden theme, focusing on cocktails, many having a botanical element such as herb infusions, edible flowers and plant-based garnishes. There’s also a great range of gins and other spirits plus draught lagers, wines and Champagne as well as good quality coffee. Light bites are served until 9pm and include olives, breads, baked camembert and sharing boards.
      </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "pubs & bars, independant",
    category: ["pubs bars", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/bottlegarden_1.jpg",
      },
      {
        type: "image",
        content: "pubs_bars/bottlegarden_2.jpg",
      },
      {
        type: "image",
        content: "pubs_bars/bottlegarden_3.jpg",
      },
      {
        type: "image",
        content: "pubs_bars/bottlegarden_4.jpg",
      },
      {
        type: "image",
        content: "pubs_bars/bottlegarden_5.jpg",
      },
    ],
    link: {
      text: "12-14 Silver Walk, Leicester LE1 5EW",
      url: "http://maps.google.com/?q=TheBottleGarden 12-14 Silver Walk, Leicester LE1 5EW",
    },
  },

  // {
  //   name: "Spirits Bar",
  //active: false,//   
  //position: {
  //     x: 2849,
  //     y: 2732,
  //   },
  //   transform: {
  //     width: 50,
  //     height: 50,
  //   },
  //   website: "https://www.escapeasylum.co.uk/spirits-bar/",
  //   socials: [
  //     {
  //       name: "facebook",
  //       url: "https://www.facebook.com/SpiritsBarUK/",
  //     },
  //     {
  //       name: "instagram",
  //       url: "https://www.instagram.com/spiritsbaruk/",
  //     },
  //     {
  //       name: "twitter",
  //       url: "https://twitter.com/spiritsbaruk/",
  //     },
  //   ],
  //   content: `
  //   <div class="intro">
  //     <p>
  //     A gothic bar with a modern twist, Spirits blends old and new elements including a statue of Icarus, a church window and a huge, colourful lightbox on the wall. Linked with Escape Asylum in the building’s basement, the bar’s lightbox and griffiti contains hidden Fibonacci numbers, tying in with the puzzle-solving theme. The bar focuses on cocktails, from classics to eye-catching concoctions involving dry ice and fire.
  //     </p>
  //      </div>
  //   `,
  //   icon: "pubs_bars.svg",
  //   brand: "in-logo-black.png",
  //   categoryname: "pubs & bars",
  //   category: ["pubs bars"],
  //   carousel: [
  //     // {
  //     //   type: "image",
  //     //   content: "pubs_bars/bottlegarden_1.jpg",
  //     // },
  //   ],
  //   link: {
  //     text: "6 Hotel Street, Leicester LE1 5AW",
  //     url: "http://maps.google.com/?q=spiritsbar 6 Hotel Street, Leicester LE1 5AW",
  //   },
  // },

  {
    name: "Brewdog",
    active: false,
    position: {
      x: 2795,
      y: 2992,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website:
      "https://www.brewdog.com/bars/uk/brewdog-leicester?utm_source=gmb&utm_medium=organic&utm_campaign=gmb-leicester",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/brewdogleicester/",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/tonnebar/",
      // },
      {
        name: "twitter",
        url: "https://twitter.com/brewdogleics/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Looking for a warm welcome and great beer? BrewDog Leicester is the place for you. It’s a no-nonsense sanctuary for lovers of craft beer, offering 20 curated taps of delicious brews and an integrated BottleDog with a choice of more than 100 take-away beers. There’s also an amazing food menu with a selection of grilled meats and a music and comedy venue upstairs.
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    // brand: "in-logo-black.png",
    categoryname: "pubs & bars",
    category: ["pubs bars", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/brewdog.jpg",
      },
    ],
    link: {
      text: "8 Friar Lane, Leicester LE1 5RA",
      url: "http://maps.google.com/?q=brewdog 8 Friar Lane, Leicester LE1 5RA",
    },
  },

  {
    name: "The Tree",
    active: false,
    position: {
      x: 2165,
      y: 2300,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.treeleicester.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/the.tree.leicester",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/the.tree.leicester/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/brewdogleics/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Following a recent make-over, this popular venue still retains its quirky charm but is now brighter and fresher with more space too. Relax with a drink in the soft seating areas or check out the chic new dining areas. The beer garden has been spruced up too with shelters decorated by local artists. A new vegan menu has been added to the extensive food choice.
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "pubs & bars, Lunch, Dinner",
    category: ["pubs bars", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/thetree_1.jpg",
      },
      {
        type: "image",
        content: "pubs_bars/thetree_2.jpg",
      },
    ],
    link: {
      text: "99 High Street, Leicester LE1 4JB",
      url: "http://maps.google.com/?q=thetree 99 High Street, Leicester LE1 4JB",
    },
  },

  {
    name: "Rutland & Derby Arms",
    active: false,
    position: {
      x: 2615,
      y: 3322,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.therutlandandderby.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/RutlandAndDerbyPub/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/rutlandandderby/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/RutlandAndDerby",
      },
    ],
    content: `
    <div class="intro">
      <p>
      A multi-award-winning pub in the heart of the city centre, the Rutland & Derby serves the very best in craft ales and ciders and locally-sourced food. Located within close walking distance to the cathedral and Highcross shopping centre, the vibrant venue also offers original cocktails using artisan spirits as well as a discerning variety of wine. There is also a spacious garden with roof terrace - perfect for soaking up long summer evenings.
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "pubs & bars, lunch, dinner, independant",
    category: ["pubs bars", "lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/rutlandderby.jpg",
      },
    ],
    link: {
      text: "21 Millstone Lane, Leicester LE1 5JN",
      url: "http://maps.google.com/?q=rutlandderbyarms 21 Millstone Lane, Leicester LE1 5JN",
    },
  },

  {
    name: "Queen of Bradgate",
    active: false,
    position: {
      x: 2095,
      y: 2313,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.thequeenofbradgate.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/qobpub/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/queenofbradgate/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/qobpub/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Part of the city’s craft beer scene, the Queen of Bradgate offers a large selection of English, Belgium and American craft beers as well as real ales, a wine list and cocktails. It’s spacious enough for large groups while cosy corners are perfect for more intimate nights out. Freshy cooked food, including delicious Sunday lunches, as well as live music, DJ sets and open mic nights help make this a perfect destination
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "pubs & bars, lunch, dinner, independant",
    category: ["pubs bars", "lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/queenofbradgate_1.jpg",
      },
      {
        type: "image",
        content: "pubs_bars/queenofbradgate_2.jpg",
      },
    ],
    link: {
      text: "93 High Street, Leicester LE1 4JB",
      url: "http://maps.google.com/?q=queenofbradgate 93 High Street, Leicester LE1 4JB",
    },
  },

  {
    name: "Veeno Italian Wine Cafe",
    active: false,
    position: {
      x: 2715,
      y: 2634,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.veenobars.com/leicester",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/veeno.uk/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/veeno_uk/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/veeno_uk",
      },
    ],
    content: `
    <div class="intro">
      <p>
      If you’re a fan of wine, then why not treat yourself to a wine tasting experience at Italian wine cafe Veeno? The St Martins venue serves wines from the owner’s family vineyard, Caruso and Minini, in Sicily. Pick a bottle to enjoy along with sputini – small Italian dishes, or opt for one of the experiences led by wine experts. Experiences include five glasses of wine, each paired with sputini on a sharing platter. You’ll also enjoy a sweet wine with Veeno’s homemade Italian tiramisu.
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: "pubs & bars, lunch, dinner, independant",
    category: ["pubs bars", "lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/veeno.jpg",
      },
    ],
    link: {
      text: "9 St Martins, Leicester LE1 5DE",
      url: "http://maps.google.com/?q=Veeno 9 St Martins, Leicester LE1 5DE",
    },
  },

  {
    name: "The Blue Boar",
    active: false,
    position: {
      x: 2731,
      y: 3131,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.blueboarleicester.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/The-Blue-Boar-1545401915756048/ ",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/veeno_uk/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/veeno_uk",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      A micro-pub selling a selection of real ales, fine ciders, fine wines and snacks. Stock vegan and gluten free beers and are dog-friendly.
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    categoryname: "pubs & bars, independant",
    // brand: "in-logo-black.png",
    category: ["pubs bars", "independant"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/blueboar.jpg",
      },
    ],
    link: {
      text: "16 Millstone Lane, Leicester LE1 5JN",
      url: "https://www.google.com/maps/place/The+Blue+Boar/@52.6334817,-1.1283951,15z/data=!4m5!3m4!1s0x0:0x99a72cfe4818ae7a!8m2!3d52.6331171!4d-1.1353045",
    },
  },

  {
    name: "The Two Tailed Lion",
    active: false,
    position: {
      x: 2651,
      y: 3178,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.thetwotailedlion.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TheTwoTailedLion/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/thetwotailedlion/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/TwoTailedLion/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      The Two-Tailed Lion is a traditional English free house with modernist flair, situated in a listed building within the historic Greyfriars area of Leicester. They pride themselves on their carefully curated selection of beers from around the world, but the Lion does not discriminate... from world-beating wine through knockout G&Ts and speciality spirits to distinctly less alcoholic but equally flavourful softies and luxurious, locally roasted coffee., everything they serve is poured with care, attention and a certain flare. Don’t miss pop up pizzas from Martin Bros Pizza co on  Friday nights.
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    categoryname: "pubs & bars, independant",
    // brand: "in-logo-black.png",
    category: ["pubs bars", "independant"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/twotailedlion.jpg",
      },
    ],
    link: {
      text: "22 Millstone Lane, Leicester LE1 5JN",
      url: "https://www.google.com/maps/place/The+Two-Tailed+Lion/@52.6329433,-1.1355513,15z/data=!4m5!3m4!1s0x0:0x52b3ef77cec031b4!8m2!3d52.6329595!4d-1.135567?sa=X&ved=2ahUKEwjqsd20v7LxAhVQeMAKHVrgCnMQ_BJ6BAhKEAU&shorturl=1",
    },
  },

  {
    name: "The Distillery",
    active: false,
    position: {
      x: 3071,
      y: 3333,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.craft-pubs.co.uk/thedistilleryleicester/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TheDistilleryLeicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/thedistilleryleicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/DistilleryLec/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Whether it is great beer, delicious cocktails, scrumptious food or a place to just kick back and watch the match; The Distillery is here for you. They aren’t kidding when they say they love sport; there are seven booths with their own screens for a private view of the match. There are also  three Sky boxes so you can watch whatever match you like!
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    categoryname: "pubs & bars",
    // brand: "in-logo-black.png",
    category: ["pubs bars"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/thedistillery.jpg",
      },
    ],
    link: {
      text: "27 Market Street, Leicester, LE1 6DP",
      url: "https://www.google.com/maps/place/The+Distillery/@52.6325485,-1.1335976,15z/data=!4m5!3m4!1s0x0:0x4c4a089d5f6489c3!8m2!3d52.6325485!4d-1.1335976?shorturl=1",
    },
  },

  {
    name: "Sophy",
    active: false,
    position: {
      x: 3396,
      y: 3745,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://sophyleicester.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TheShophybar/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/sophy.leicester/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/DistilleryLec/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      A chic cocktail bar with Instagrammable décor and delicious drinks.
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    categoryname: "pubs & bars, independant",
    // brand: "in-logo-black.png",
    category: ["pubs bars", "independant"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/sophy.jpg",
      },
    ],
    link: {
      text: "10 King Street, Leicester LE1 6RJ",
      url: "https://www.google.com/maps/place/Sophy./@52.6315168,-1.1345245,17z/data=!3m1!4b1!4m5!3m4!1s0x4877616d86b8739b:0xcadd87c036fa5b3!8m2!3d52.6315168!4d-1.1323358?shorturl=1",
    },
  },

  {
    name: "Audrey",
    active: false,
    position: {
      x: 1900,
      y: 2330,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.audreybar.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/Audrey-101736331984401/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/audreybar_/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/DistilleryLec/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      A cocktail bar for the day drinkers, the 9-5ers and the night time booty shakers, Audrey has it all. 
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    categoryname: "pubs & bars, independant",
    // brand: "in-logo-black.png",
    category: ["pubs bars", "independant"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/audrey.jpg",
      },
    ],
    link: {
      text: "19-21 St Nicholas Place, Leicester LE1 4LD",
      url: "https://www.google.com/maps/place/Audrey/@52.6351725,-1.1411361,17z/data=!3m1!4b1!4m5!3m4!1s0x487761259b81fe71:0xad371ab20cbb047f!8m2!3d52.6351725!4d-1.1389474",
    },
  },

  {
    name: "Locker Room",
    active: false,
    position: {
      x: 3729,
      y: 3043,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.thelockerroomleicester.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TheLockerRoomLeicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/thelockerroomleicester/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/DistilleryLec/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      The Locker Room is a sports bar providing great entertainment, food and drinks 7 days a week!  With the city's largest projector and over 25 screens you won't miss a second of the action. If playing sports is more your thing you can enjoy 5 American pool tables, 2 English pool tables and 2 Olympic grade ping pong tables – come along and get the bragging rights amongst your friends
      </p>
       </div>
    `,
    icon: "pubs_bars.svg",
    categoryname: "pubs & bars, independant",
    // brand: "in-logo-black.png",
    category: ["pubs bars", "independant"],
    carousel: [
      {
        type: "image",
        content: "activities_family/locker-room.jpg",
      },
    ],
    link: {
      text: "5 Belvoir Street, Leicester LE1 6SL",
      url: "https://www.google.com/maps/place/The+Locker+Room/@52.6333333,-1.1327443,17z/data=!3m1!4b1!4m5!3m4!1s0x48776121fd43cffd:0xd533be71f44e7776!8m2!3d52.6333333!4d-1.1305556",
    },
  },

  /**************************************/
  /**************************************/
  /****** Category: Entertainment *******/
  /**************************************/
  /**************************************/

  {
    name: "Curve Theatre",
    active: false,
    position: {
      x: 4285,
      y: 2364,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.curveonline.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/CURVEtheatreLeicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/curve_leicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/CurveLeicester",
      },
    ],
    content: `
    <div class="intro">
      <p>
      At the heart of the Cultural Quarter is the Curve theatre, which was designed by international architect Rafael Vinoly, and was officially opened by the Queen in 2008.</p>
      <p> Curve has a growing reputation as one of the country’s leading producing theatres and often hosts world premieres as well as shows direct from the West End.
      </p>
       </div>
    `,
    icon: "entertainment.svg",
    brand: "giftcard.png",
    categoryname: "entertainment, family, independant",
    category: ["entertainment", "family", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "entertainment/curvetheatre.jpg",
      },
    ],
    link: {
      text: "60 Rutland Street, Leicester LE1 1SB",
      url: "http://maps.google.com/?q=CurveTheatre 60 Rutland Street, Leicester LE1 1SB",
    },
  },

  {
    name: "Phoenix Cinema",
    active: false,
    position: {
      x: 4965,
      y: 2094,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.phoenix.org.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/PhoenixLeic/",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/curve_leicester/",
      // },
      {
        name: "twitter",
        url: "https://twitter.com/PhoenixLeic",
      },
    ],
    content: `
    <div class="intro">
      <p>Phoenix is Leicester’s centre for independent cinema, art and digital culture.
      Showing everything from micro-budget independent films to the latest Hollywood blockbusters in its modern cinema screens, Phoenix also regularly organises festivals and events.</p>

      <p>The on-site Phoenix Café is a hidden gem in the city; visit for fresh, seasonal food that is lovingly prepared, and look out for the great value dine and film offer.</p>
       </div>
    `,
    icon: "entertainment.svg",
    brand: "giftcard.png",
    categoryname: "entertainment, independant",
    category: ["entertainment", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "entertainment/phoenix_1.jpg",
      },
      {
        type: "image",
        content: "entertainment/phoenix_2.jpg",
      },
    ],
    link: {
      text: "4 Midland Street, Leicester LE1 1TG",
      url: "http://maps.google.com/?q=Phoenix Cinema and Art Centre 4 Midland Street, Leicester LE1 1TG",
    },
  },

  {
    name: "LCB Depot",
    active: false,
    position: {
      x: 4265,
      y: 2631,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://lcbdepot.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/lcbdepot/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/lcbdepot",
      },
      {
        name: "twitter",
        url: "https://twitter.com/lcbdepot",
      },
    ],
    content: `
    <div class="intro">
      <p>As well as being the base for many of the city’s creative businesses, Leicester Creative Business Depot (LCB) also holds regular events, including Last Friday - an exciting night of street food, art and music which, as
      the name suggests, takes place on the last Friday of each month.
      </p>
       </div>
    `,
    icon: "entertainment.svg",
    brand: "giftcard.png",
    categoryname: "entertainment, independant",
    category: ["entertainment","independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "entertainment/lcb_depot.jpg",
      },
    ],
    link: {
      text: "LCB Depot, 31 Rutland Street, Leicester LE1 1RE",
      url: "http://maps.google.com/?q=LCB Depot 31 Rutland Street, Leicester LE1 1RE",
    },
  },

  {
    name: "The Little Theatre",
    active: false,
    position: {
      x: 4035,
      y: 3444,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.thelittletheatre.net/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/thelittletheatreleicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/thelittleleics/",
      },
    ],
    content: `
    <div class="intro">
      <p>The Little Theatre is a predominantly
      volunteer-run theatre, welcoming more than 30,000 people a year to its
      350 seat auditorium. It is home to Leicester Drama Society and boasts patrons such as Sir Anthony Hopkins, Tim Pigott-Smith
      and Honorary Life President Lord Richard
      Attenborough. It also hosts other societies, touring shows, ballets and variety shows and is a regular venue during Leicester
      Comedy Festival.     
      </p>
       </div>
    `,
    icon: "entertainment.svg",
    brand: "giftcard.png",
    categoryname: "entertainment, family, independant",
    category: ["entertainment", "family", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "entertainment/thelittletheatre.jpg",
      },
    ],
    link: {
      text: "Dover Street, Leicester LE1 6PW",
      url: "http://maps.google.com/?q=TheLittleTheatre Dover Street, Leicester LE1 6PW",
    },
  },

  {
    name: "The Y",
    active: false,
    position: {
      x: 4525,
      y: 3524,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.leicesterymca.co.uk/theatre/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TheYTheatre/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/TheYTheatre",
      },
    ],
    content: `
    <div class="intro">
      <p>Leicester’s oldest theatre plays host to a programme of music, comedy, performance and spoken word with its flexible auditorium allowing for intimate candle-lit cabaret, rows or open dance floor. The theatre supports emerging and experienced local performers, writers and artists through developmental programmes and showcases.
      </p>
       </div>
    `,
    icon: "entertainment.svg",
    brand: "giftcard.png",
    categoryname: "entertainment, family, independant",
    category: ["entertainment", "family", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "entertainment/ytheatre_1.jpg",
      },
      {
        type: "image",
        content: "entertainment/ytheatre_2.jpg",
      },
    ],
    link: {
      text: "7 East Street, Leicester LE1 6EY",
      url: "http://maps.google.com/?q=TheYTheatre 7 East Street, Leicester LE1 6EY,",
    },
  },

  {
    name: "O2 Academy",
    active: false,
    position: {
      x: 4955,
      y: 7136,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://academymusicgroup.com/o2academyleicester/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/o2academyleicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/O2AcadLeicester/",
      },
      {
        name: "instagram",
        url: "https://instagram.com/o2academyleicester/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Originally built in 1957, this venue has seen a fantastic line-up of music icons over the years with the Rolling Stones, Led Zeppelin, The Smiths and The Jam all appearing. An 18-month major refurbishment transformed the original University of Leicester Students’ Union into an amazing new space with O2 Academy Leicester being added as part of the project. Both a live music and club space, 02 Academy is open to students, music-loving residents and visitors to Leicester with three rooms attracting all kinds of artists from major acts to small bands on the up.
      </p>
       </div>
    `,
    icon: "entertainment.svg",
    brand: "giftcard.png",
    categoryname: "entertainment",
    category: ["entertainment","gift card"],
    carousel: [
      {
        type: "image",
        content: "entertainment/02academy.jpg",
      },
    ],
    link: {
      text: "12 University Road, Leicester LE1 7RH",
      url: "http://maps.google.com/?q=O2Academy 12 University Road, Leicester LE1 7RH",
    },
  },

  {
    name: "De Montfort Hall",
    active: false,
    position: {
      x: 5625,
      y: 5905,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.demontforthall.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/demontforthall",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/demontforthall",
      },
      {
        name: "twitter",
        url: "https://twitter.com/demontforthall",
      },
    ],
    content: `
    <div class="intro">
      <p>
      For big name acts, Leicester’s largest entertainment venue, De Montfort Hall, is the place to go. Having served the city with quality live acts since 1913, it continues to boast a varied programme including stars from the worlds of music and entertainment, touring musicals, stand-up comedy, classical concerts and opera.
      </p>
       </div>
    `,
    icon: "entertainment.svg",
    brand: "giftcard.png",
    categoryname: "entertainment",
    category: ["entertainment", "gift card"],
    carousel: [
      {
        type: "image",
        content: "entertainment/demontforthall.jpg",
      },
    ],
    link: {
      text: "Granville Road, Leicester LE1 7RU",
      url: "http://maps.google.com/?q=DeMontfortHall Granville Road, Leicester LE1 7RU,",
    },
  },

  {
    name: "Genting Casino",
    active: false,
    position: {
      x: 2782,
      y: 1678,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.gentingcasino.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/GentingCasinos",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/gentingcasinosuk",
      },
      {
        name: "twitter",
        url: "https://twitter.com/GentingCasinoUK",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Found in the city centre, Genting Casino Leicester lets you catch both the casino and sports action in one place. Our casino offers both live-dealer and electronic casino games, and the games on offer include riveting Roulette wheels, brilliant Blackjack hands, and scorching-hot slots. Our late bar is open with a selection of food and drinks.      </p>
       </div>
    `,
    icon: "entertainment.svg",
    brand: "giftcard.png",
    categoryname: "entertainment",
    category: ["entertainment", "gift card"],
    carousel: [
      {
        type: "image",
        content: "entertainment/genting.jpg",
      },
    ],
    link: {
      text: "15-19 East Bond Street, LE1 4SU",
      url: "https://www.google.com/maps/place/Genting+Casino+Leicester/@52.6372146,-1.1372399,17z/data=!3m1!4b1!4m5!3m4!1s0x4877611e1444d829:0xa6e56d037a9fbd38!8m2!3d52.6372146!4d-1.1350512",
    },
  },



  /**************************************/
  /**************************************/
  /**** Category: Activities/Family ****/
  /**************************************/
  /**************************************/

  {
    name: "King Richard III Visitor Centre",
    active: false,
    position: {
      x: 2525,
      y: 2763,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://kriii.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/KRIIICentre/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/KRIIICentre/",
      },
    ],
    content: `
    <div class="intro">
      <p>King Richard III Visitor Centre opened
      its doors to the public in 2014.
      Using great storytelling, beautiful design
      and 21st century technology, the centre
      tells the fascinating and moving story of
      the king’s life and death, and reveals one
      of the greatest archaeological detective
      stories ever told.</p> 
      
      <p>Events take place throughout the year,
      including bird of prey demonstrations,
      indoor archery and knight school.
      Outside the visitor centre, you can see
      the magnificent statue of King Richard III
      in Cathedral Gardens.</p>      
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family",
    category: ["activities", "family", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/kingrichard_1.jpg",
      },
      {
        type: "image",
        content: "activities_family/kingrichard_2.jpg",
      },
    ],
    link: {
      text: "4A St Martins, Leicester LE1 5DB",
      url: "http://maps.google.com/?q=KRIII 4A St Martins, Leicester LE1 5DB,",
    },
  },

  {
    name: "The Space to Breath Collective",
    active: false,
    position: {
      x: 2835,
      y: 2743,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://spacetobreathecollective.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/SpaceToBreatheCollective",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/space_to_breathe_collective",
      },
    ],
    content: `
    <div class="intro">
      <p>The collective works by providing a physical place to bring people together to be able to find activities and resources which contribute to people’s wellbeing - mainly yoga classes.</p> 
    
       </div>
    `,
    icon: "activities.svg",
    // brand: "in-logo-black.png",
    categoryname: "activities",
    category: ["activities"],
    carousel: [
      {
        type: "image",
        content: "activities_family/space-breathe.jpg",
      },
    ],
    link: {
      text: "Suites 1 & 2, Clock Chambers, 6 Hotel Street, Leicester LE1 5AW",
      url: "https://www.google.com/maps/place/The+Space+to+Breathe+Collective/@52.6342496,-1.1346919,15z/data=!4m5!3m4!1s0x0:0xd0cb9c21f829b24e!8m2!3d52.6342496!4d-1.1346919?sa=X&ved=2ahUKEwjwhMC5rPnxAhWSN8AKHS0YB8MQ_BIwFXoECFgQBQ&shorturl=1",
    },
  },

  {
    name: "Leicester Museum",
    active: false,
    position: {
      x: 4335,
      y: 4533,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.leicestermuseums.org/leicester-museum-art-gallery/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/leicestermuseums/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/leicestermuseum/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/leicestermuseums/",
      },
    ],
    content: `
    <div class="intro">
      <p>Take the kids to meet George – a 15-metre
      dinosaur skeleton who has towered over visitors at New Walk Museum for more than 30 years. The colossal fossilised Cetiosaurus skeleton – also known as the Rutland Dinosaur - was discovered in
      June 1968 and has taken pride of place in the popular dinosaur gallery since 1985. And if coming face-to-face with monsters has the kids crying for mummy - there are four Egyptian mummies at the
      museum just dying to meet them.</p> 
      
      <p>The museum also houses one of the country’s best collections of German Expressionist Art, a Victorian Art Gallery, the Picasso Ceramics Gallery and a varied temporary exhibition and events programme.
      </p>      
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family",
    category: ["activities", "family", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/leicestermuseum_1.jpg",
      },
      {
        type: "image",
        content: "activities_family/leicestermuseum_2.jpg",
      },
      {
        type: "image",
        content: "activities_family/leicestermuseum_3.jpg",
      },
    ],
    link: {
      text: "53 New Walk, Leicester LE1 7EA",
      url: "http://maps.google.com/?q=LeicesterMuseum 453 New Walk, Leicester LE1 7EA",
    },
  },

  {
    name: "Newarke Houses Museum",
    active: false,
    position: {
      x: 1915,
      y: 3623,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.leicestermuseums.org/newarke-houses/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/leicestermuseums/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/leicestermuseum/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/leicestermuseums/",
      },
    ],
    content: `
    <div class="intro">
      <p>Newarke Houses is a museum made up of two historic houses - Wygston’s Chantry House and Skeffington House. It tells the social history of Leicester, and enables
      you to discover the history of the Regiment, including a recreation of a First World War trench with sound and lighting. The museum displays include a cinema experience, a collection of toys
      from Tudor to present day and a play area for children to try various games. </p>     
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family",
    category: ["activities", "family", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/newarkehousesmuseum.jpg",
      },
    ],
    link: {
      text: "The Newarke, Leicester LE2 7BY",
      url: "http://maps.google.com/?q=NewarkeHouses The Newarke, Leicester LE2 7BY",
    },
  },

  {
    name: "Leicester Print Workshop",
    active: false,
    position: {
      x: 4935,
      y: 2403,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.leicesterprintworkshop.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/leicesterprintworkshop/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/leicesterprint ",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/leicestermuseums/",
      // },
    ],
    content: `
    <div class="intro">
      <p>Leicester Print Workshop is the Midlands centre for fine art printmaking and home to a large open access studio housing equipment for silkscreen, stone
      and plate lithography, etching, engraving and letterpress. Free-to-view exhibitions are regularly held as well as activities including
      artist talks, demonstrations and drop-in sessions.
       </p>     
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family, independant",
    category: ["activities", "family", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/leicesterprintworkshop.jpg",
      },
    ],
    link: {
      text: "50 St George Street, Leicester LE1 1QG",
      url: "http://maps.google.com/?q=LeicesterPrintWorkshop 50 St George Street, Leicester LE1 1QG",
    },
  },

  {
    name: "Bring The Paint: Street Art",
    active: false,
    position: {
      x: 4475,
      y: 2532,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.bringthepaint.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/bringthepaintfest/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/leicesterprint ",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/bring_the_paint/",
      },
    ],
    content: `
    <div class="intro">
    <p>The whole city is an art gallery thanks to Bring the Paint Festival which takes place in the city every two years. Keep an eye out - especially in the Cultural Quarter - for works by internationally renowned street artists. Download the LoyalFree app to follow a trail of Street Art in the city.</p>
       </div>
    `,
    icon: "activities.svg",
    // brand: "in-logo-black.png",
    categoryname: "activities",
    category: ["activities"],
    carousel: [
      {
        type: "image",
        content: "activities_family/bringthepaint.jpg",
      },
    ],
    link: {
      text: "Cultural Quarter, Leicester",
      url: "http://maps.google.com/?q= Exchange Buildings, 69 Halford St, Leicester LE1 1TR",
    },
  },

  {
    name: "Boost Trampoline Park",
    active: false,
    position: {
      x: 5085,
      y: 2653,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.boosttrampolineparks.co.uk/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/bringthepaintfest/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/leicesterprint ",
      // // },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/bring_the_paint/",
      // },
    ],
    content: `
    <div class="intro">
      <p>Give the kids a boost and let
      them release some energy at a
      trampoline park packed with
      fun activities. Children will love
      playing on the battle beam and
      diving into the soft and fluffy
      foam pits as well as bouncing
      on the more than 40
      trampolines.   
       </p>     
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family",
    category: ["activities", "family", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/boosttrampoline.jpg",
      },
    ],
    link: {
      text: "11 St George Street, Leicester LE1 1QG",
      url: "http://maps.google.com/?q=BoostTrampoline 11 St George Street, Leicester LE1 1QG",
    },
  },

  {
    name: "Bean Gaming",
    active: false,
    position: {
      x: 2835,
      y: 2313,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.bean-gaming.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/beangaminguk/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/leicesterprint ",
      // // },
      {
        name: "instagram",
        url: "https://www.instagram.com/bean_gaming_uk/",
      },
    ],
    content: `
    <div class="intro">
      <p>A cafe where they have board games as well as scrumptious cakes, hot and cold food including build-your-own pizzas, and ethically-sourced coffee on the menu. The family-friendly venue aims to offer a place to ditch the screen and enjoy one another’s company with hundreds of board games promising something for everyone, whatever their ability or interests.
       </p>     
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family, lunch, dinner, independant",
    category: ["activities", "family", "lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/beangaming.jpg",
      },
    ],
    link: {
      text: "22 Silver Street, Leicester LE1 5ET",
      url: "http://maps.google.com/?q=BeanGaming 22 Silver Street, Leicester LE1 5ET",
    },
  },

  {
    name: "Club 180 Darts",
    active: false,
    position: {
      x: 4855,
      y: 3673,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://club180darts.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/club180darts/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/club180darts/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/club180darts/",
      },
    ],
    content: `
    <div class="intro">
      <p>Drink great craft beer, sip delicious cocktails and hit a few 180s at
      this darts club with a difference. With automatic scoreboards,
      enough space for up to 12 players per oche and great food, it’s
      darts but not as you know it. Ideal for dates, a night out with your
      mates and work colleagues, birthdays or hen and stag nights, it’s
      the perfect venue for bringing people together. All food and drink
      can be pre-ordered in advance to be at your oche on arrival.     
       </p>     
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities",
    category: ["activities", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/club180darts.jpg",
      },
    ],
    link: {
      text: "48A London Road, Leicester LE2 0QB",
      url: "http://maps.google.com/?q=Club180Darts 48A London Road, Leicester LE2 0QB",
    },
  },

  {
    name: "Showcase Cinema De Lux",
    active: false,
    position: {
      x: 1935,
      y: 1873,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website:
      "https://www.showcasecinemas.co.uk/cinema-info/showcase-cinema-de-lux-leicester",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/ShowcaseCinemasUK/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/showcasecinemas",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/club180darts/",
      // },
    ],
    content: `
    <div class="intro">
      <p>The 12-screen Cinema de Lux brings a combination of cutting-edge digital technology, interactive media, and a broad range of programming. Facilities include the exclusive Director’s Halls premium auditoria, featuring leather rocking recliner
      seats and Studio One, where hot food, alcohol and appetizers are served in the relaxed atmosphere of a comfortable
      seated area.        
       </p>     
       </div>
    `,
    icon: "entertainment.svg",
    brand: "giftcard.png",
    categoryname: "entertainment, family",
    category: ["entertainment", "family", "gift card"],
    carousel: [
      {
        type: "image",
        content: "entertainment/showcasecinema.jpg",
      },
    ],
    link: {
      text: "7 Highcross Lane, Leicester LE1 4SD",
      url: "http://maps.google.com/?q=ShowcaseCinema 7 Highcross Lane, Leicester LE1 4SD",
    },
  },

  {
    name: "Treetop Adventure Golf",
    active: false,
    position: {
      x: 2245,
      y: 2053,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://adventuregolf.com/leicester/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/treetopgolfleicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/TreetopGolf/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/treetop_golf/",
      },
    ],
    content: `
    <div class="intro">
      <p>Here’s your chance to enjoy a rainforest adventure, without the worry of getting wet. The indoor golf attraction features two 18-hole courses – the Tropical Trail, complete with a mighty Twisted Fig
      tree and singing barbershop frogs, and the Ancient Explorer, with The Sacred Mask and The Sleepy Head who will be waiting to welcome you to the Cloud Village. All players will get to play the bonus
      19th hole for the chance to win a free round. You can also relax in The Market, with a cup of coffee fresh from the Amazon, a local beer, or a tropical cocktail.
          
       </p>     
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family",
    category: ["activities", "family", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/treetopgolf.jpg",
      },
    ],
    link: {
      text: "3 Shires Lane, Leicester LE1 4AN",
      url: "http://maps.google.com/?q=TreetopAdventureGolf 3 Shires Lane, Leicester LE1 4AN",
    },
  },

  {
    name: "Caddyshackers",
    active: false,
    position: {
      x: 4365,
      y: 3553,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://caddyshackers.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/CaddyshackersCrazyGolf/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/CaddyshackersCG",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/caddyshackerscg/",
      },
    ],
    content: `
    <div class="intro">
      <p>If you love crazy golf and you’re partial to a
      cocktail or two, then this is the place for
      you. The 18+ crazy golf bar features an
      18-hole course, with quirky themes including an old pub, a beach, a bathroom and a construction site. There’s even a couple of cars incorporated into the course!
      You can also get brilliantly-named
      cocktails (£6.50) including the Bunker Bug,
      Tokyo Ice Tee, Tequila Drive and Tiger
      Woods.</p>
      <p>
      Guests are welcome to just pop in for a
      drink in the bar, and enjoy the games room,
      complete with pool tables and table tennis.  
       </p>     
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, independant",
    category: ["activities", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/caddyshakers.jpg",
      },
    ],
    link: {
      text: "40 Calais Hill, Leicester LE1 6AR",
      url: "http://maps.google.com/?q=Caddyshackers 40 Calais Hill, Leicester LE1 6AR",
    },
  },

  {
    name: "East Street Lanes",
    active: false,
    position: {
      x: 4385,
      y: 3783,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://eaststreetlanes.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/eaststreetlanes/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/EastStreetLanes/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/eaststreetlanes/",
      },
    ],
    content: `
    <div class="intro">
      <p>This £1 million bowling alley is Leicester’s first city centre bowling alley for almost 14 years. East Street Lanes features eight Brunswick lanes, available for teams of six per lane. There’s a gaming arcade with lots of retro classics, such as Pac-Man Basketball and Space Invaders Frenzy, plus there’s pool tables and air hockey. There’s also a lovely restaurant area and a sleek bar to enjoy some post-game refreshments. Families are welcome during the day but the venue is adults-only after 7pm.</p>
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family, independant",
    category: ["activities", "family", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/eaststreetlanes.jpg",
      },
    ],
    link: {
      text: "East Street, Leicester LE1 6NB",
      url: "http://maps.google.com/?q=EastStreetLanes East Street, Leicester LE1 6NB",
    },
  },

  {
    name: "Social Climbing",
    active: false,
    position: {
      x: 2805,
      y: 1883,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.social-climbing.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/socialclimbingcentres/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/EastStreetLanes/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/socialclimbing_leicester/",
      },
    ],
    content: `
    <div class="intro">
      <p>If you like climbing and colourful 1970s wallpaper, then you’re going to love Leicester’s indoor bouldering centre. For those who don’t know, bouldering is climbing without ropes… but don’t worry - there are plenty of crash mats! There’s more than 700m2 of climbing surface which can be explored through a variety of different routes, according to your ability. There’s also a living room area with leather sofas, a workout area upstairs, showers, changing rooms and coffee. The Social Climbing brand and interior design is a homage to the 1970s rebel climbers - the Stonemasters.</p>
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family, independant",
    category: ["activities", "family", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/social_climbing.jpg",
      },
    ],
    link: {
      text: "MSU12, 24-28 East Bond Street, Leicester LE1 4SX",
      url: "http://maps.google.com/?q=SocialClimbingCentres MSU12, 24-28 East Bond Street, Leicester LE1 4SX",
    },
  },

  {
    name: "Escape Leicester",
    active: false,
    position: {
      x: 3338,
      y: 2705,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.escape-leicester.co.uk/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/socialclimbingcentres/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/EastStreetLanes/",
      // },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/socialclimbing_leicester/",
      // },
    ],
    content: `
    <div class="intro">
      <p>If you fancy taking on a challenge against
      the clock, then why not pay a visit to one of
      Leicester’s escape rooms? You’ll be locked
      in a mysterious room with 60 minutes to
      get out! Games including finding hidden treasure in an abandoned cabin, retrieving a lost sword to claim the throne or escaping from a mad professor and more! All rooms are suitable for 12+ years and can host groups up to six people. 
      </p>
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family, independant",
    category: ["activities", "family", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/escape.jpg",
      },
    ],
    link: {
      text: "3rd Floor, Horsefair House, Horsefair Street, Leicester LE1 5BP",
      url: "http://maps.google.com/?q=EscapeLeicester 3rd Floor, Horsefair House, Horsefair Street, Leicester LE1 5BP",
    },
  },

  {
    name: "The Guildhall",
    active: false,
    position: {
      x: 2148,
      y: 2535,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.leicestermuseums.org/leicester-guildhall/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/leicestermuseums/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/leicestermuseum/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/leicestermuseums",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Leicester Guildhall is a historic building and the oldest building still in use in the city. It was Leicester’s first police station, with The Great Hall being built in about 1390 as a meeting place for the Guild of Corpus Christi. It is even believed that Shakespeare’s players performed here during the Tudor period. The building's many uses have included housing one of the oldest public libraries and serving as the Town Hall, but since 1926 it has been open as a public museum and performance venue, housing the Medieval Leicester galleries.
      </p>
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities, family, independant",
    category: ["activities", "family", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/theguildhall.jpg",
      },
    ],
    link: {
      text: "Guildhall Lane, Leicester LE1 5FQ",
      url: "https://www.google.com/maps/place/The+Guildhall+Museum/@52.6348773,-1.1376507,19.25z/data=!4m5!3m4!1s0x487760e026b3cca3:0x1ffd6721f9a07f26!8m2!3d52.6347565!4d-1.137738?shorturl=1",
    },
  },

  {
    name: "Escapologic",
    active: false,
    position: {
      x: 2680,
      y: 2730,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.escapologic.com/leicester-escape-rooms/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/escapologic/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/escapologic/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/escapologic/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Thrills, suspense, mystery and horror await in four incredible escape rooms. Will you choose to piece together the mystery of the abandoned laboratory, or battle supernatural puzzles in the crypt? With movie-quality special effects and completely realistic set and sound design, every locked door in Escapologic opens on another world.
      </p>
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities",
    category: ["activities", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/escapologic.jpg",
      },
    ],
    link: {
      text: "2B St Martins, Leicester LE1 5DB",
      url: "http://maps.google.com/?q=Escapologic 2B St Martins, Leicester LE1 5DB",
    },
  },

  {
    name: "Clue HQ",
    active: false,
    position: {
      x: 1874,
      y: 2232,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://cluehq.co.uk/leicester/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/escapologic/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/escapologic/",
      // },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/escapologic/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      The perfect exhilarating, exciting, entertaining, team building, escape the room experience! Chose from six rooms including a nuclear bunker, prison and Egyptian tomb experience.
      </p>
       </div>
    `,
    icon: "activities.svg",
    brand: "giftcard.png",
    categoryname: "activities",
    category: ["activities", "gift card"],
    carousel: [
      {
        type: "image",
        content: "activities_family/cluehq.jpg",
      },
    ],
    link: {
      text: "First Floor, 62-68 Highcross Street, Leicester LE1 4NN",
      url: "http://maps.google.com/?q=ClueHQ 62-68 Highcross Street, Leicester LE1 4NN",
    },
  },

  {
    name: "Leicester Simulations",
    active: false,
    position: {
      x: 2885,
      y: 2572,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://leicestersimulations.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/LeicesterSimulations/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/escapologic/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/leicestersimulations/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      A simulation centre in the heart of Leicester where you can experience Formula One Racing, play a round of golf or even fly a WW2 Spitfire. You can race against each other to see who the better driver is, play nearest the pin on the golf simulator or see who can shoot down the most bombers. Visit for a unique, fun experience – you won't regret it!
      </p>
       </div>
    `,
    icon: "activities.svg",
    // brand: "in-logo-black.png",
    categoryname: "activities, independant",
    category: ["activities", "independant"],
    carousel: [
      {
        type: "image",
        content: "activities_family/leicester-simulations.jpg",
      },
    ],
    link: {
      text: "44-46 Cank Street, Leicester LE1 5GW",
      url: "https://www.google.com/maps/place//data=!4m2!3m1!1s0x48776168e3088d3d:0x60bec6ca12dfad8e?source=g.page.share",
    },
  },

  {
    name: "Leicester City Football Club",
    active: false,
    position: {
      x: 1208,
      y: 7539,
    },
    transform: {
      width: 70,
      height: 70,
    },
    website: "https://www.lcfc.com/king-power-stadium",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/lcfc/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/lcfc/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/lcfc/",
      },
    ],
    content: `
    <div class="intro">
    <p>Leicester City Football Club wowed the
    footballing world when they became English Premier League champions in 2016. The title win was described as the greatest sporting shock ever, due to the fact that at the start of the season they were favourites to face
    relegation. 
    As well as winning the Premier League title,
    the Foxes have been FA Cup finalists four
    times. </p>

    <p>The Foxes now play at the King Power Stadium in the Premier League. You’ll have to be quick to get tickets for matches, but you may well be able to join one of the stadium tours, where you’ll go behind the scenes at the King Power Stadium and see
    the home of Leicester City Football Club
    through the eyes of the first team squad.
    Each tour includes the chance to see club
    memorabilia, visit the players’ entrance, used by the first team squad on Premier League matchdays, see inside the away and home changing rooms, and even walk pitch side before gracing the home dugout.</p>

       </div>
    `,
    icon: "activities.svg",
    // brand: "in-logo-black.png",
    categoryname: "activities, Family",
    category: ["activities", "family"],
    carousel: [
      {
        type: "image",
        content: "activities_family/lcfc.jpg",
      },
    ],
    link: {
      text: "King Power Stadium, Filbert Way, Leicester LE2 7FL",
      url: "https://www.google.com/maps/place/King+Power+Stadium/@52.6203662,-1.1509442,15z/data=!4m5!3m4!1s0x487760d73d562bcb:0xcd89e0170e2399aa!8m2!3d52.6203662!4d-1.1421895",
    },
  },

  {
    name: "Leicester Tigers",
    active: false,
    position: {
      x: 3264,
      y: 6164,
    },
    transform: {
      width: 70,
      height: 70,
    },
    website: "https://www.leicestertigers.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/leicestertigers/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/leicestertigers/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/leicestertigers/",
      },
    ],
    content: `
    <div class="intro">
    
    <p>English Premiership team Leicester Tigers are one of the world’s most famous rugby union clubs.</p>

    <p>Tigers, who play their home fixtures at Welford Road,
    have never been relegated from the top division. Leicester Tigers have won 20 major titles. They were European Champions twice - in 2001 and 2002, and
    have won 10 English Championships, and eight AngloWelsh Cups.</p>

    <p>The club dates back to 1880, when Leicester Football Club (which is still the club’s official full name) was
    formed </p>

    <p>Match tickets sell quickly, so it’s best to book as early
    as possible. If you’re unable to get tickets, you could
    join a stadium tour, and get a behind the scenes experience of Welford Road.
    You’ll visit areas including the changing rooms, hospitality suites and press
    box, and even walk down the steps towards the pitch.</p>

       </div>
    `,
    icon: "activities.svg",
    // brand: "in-logo-black.png",
    categoryname: "activities, Family",
    category: ["activities", "family"],
    carousel: [
      {
        type: "image",
        content: "activities_family/leicestertigers.jpg",
      },
    ],
    link: {
      text: "Welford Road Stadium, Aylestone Road, Leicester LE2 7TR",
      url: "https://www.google.com/maps/place/Mattioli+Woods+Welford+Road+Stadium/@52.6241667,-1.1352443,17z/data=!3m1!4b1!4m5!3m4!1s0x48776127b5b6a5b3:0x97bcd1c5bcb394f4!8m2!3d52.6241667!4d-1.1330556?shorturl=1",
    },
  },

  {
    name: "Leicester Cathedral",
    active: false,
    position: {
      x: 2308,
      y: 2592,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://leicestercathedral.org/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/LeicesterCathedral/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/leicestertigers/",
      // },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/leicestertigers/",
      // },
    ],
    content: `
    <div class="intro">
    
    <p>The remains of King Richard III were
    reinterred at Leicester Cathedral in 2015,
    and visitors are able to go and see the
    King’s sealed tomb.
    The two-tonne block of Swaledale fossil
    limestone has a deeply incised cross and
    the darker plinth features his name, dates,
    motto and coat of arms.</p>    

       </div>
    `,
    icon: "activities.svg",
    // brand: "in-logo-black.png",
    categoryname: "activities",
    category: ["activities"],
    carousel: [
      {
        type: "image",
        content: "activities_family/leicestercathederal.jpg",
      },
    ],
    link: {
      text: "Peacock Lane, Leicester LE1 5PZ",
      url: "https://www.google.com/maps/place/Leicester+Cathedral/@52.6346498,-1.1393377,17z/data=!3m2!4b1!5s0x487760e03d17c3a5:0xa3138c86462230fb!4m5!3m4!1s0x487760e01e3e2d2f:0x404b36289c096a45!8m2!3d52.6346498!4d-1.137149?shorturl=1",
    },
  },

  // {
  //   name: "Escape Asylum",
  //active: false,//   
  //position: {
  //     x: 2904,
  //     y: 2712,
  //   },
  //   transform: {
  //     width: 50,
  //     height: 50,
  //   },
  //   website: "https://www.escapeasylum.co.uk/#EscapeRooms",
  //   socials: [
  //     // {
  //     //   name: "facebook",
  //     //   url: "https://www.facebook.com/escapologic/",
  //     // },
  //     // {
  //     //   name: "twitter",
  //     //   url: "https://twitter.com/escapologic/",
  //     // },
  //     // {
  //     //   name: "instagram",
  //     //   url: "https://www.instagram.com/escapologic/",
  //     // },
  //   ],
  //   content: `
  //   <div class="intro">
  //     <p>
  //     Located beneath Spirits Bar, bring your trusted team mates and try to escape from these four fiendish escape scenarios – including a King Richard III themed escape. Players enjoy special offers at Spirits Bar, the perfect way to toast a daring escape!
  //     </p>
  //      </div>
  //   `,
  //   icon: "activities.svg",
  //   // brand: "in-logo-black.png",
  //   categoryname: "activities",
  //   category: ["activities"],
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "activities_family/escape_asylum.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "6a Hotel Street, Leicester LE1 5AW",
  //     url: "http://maps.google.com/?q=EscapeAsylum 6a Hotel Street, Leicester LE1 5AW",
  //   },
  // },

  /**************************************/
  /**************************************/
  /******* Category: Sweet Treat ********/
  /**************************************/
  /**************************************/

  {
    name: "Cocoa Amore",
    active: false,
    position: {
      x: 2674,
      y: 2385,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://cocoa-amore.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/CocoaAmoreuk/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/CocoaAmore/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/cocoaamore/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Cocoa Amore has been the city’s go-to place for delicious handmade chocolate since it was opened in 2013. The shop sells an array of Cocoa Amore’s beautiful handmade chocolates as well as high quality chocolate from brands including Casa Luker and Willie’s Cacao. There’s also a cafe area, where you can indulge in a pretty special hot chocolate - made with milk, white or dark chocolate. You can even create your own chocolates, with the help of the experts, at one of Cocoa Amore’s workshops.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "Shopping, sweet treat, independant",
    category: ["shopping", "sweet treat", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/cocoa_1.jpg",
      },
      {
        type: "image",
        content: "shopping/cocoa_2.jpg",
      },
      {
        type: "image",
        content: "shopping/cocoa_3.jpg",
      },
    ],
    link: {
      text: "34 Silver Street, Leicester LE1 5ET",
      url: "http://maps.google.com/?q=CocoaAmore 34 Silver Street, Leicester LE1 5ET",
    },
  },

  {
    name: "Ye Olde Sweet Shoppe",
    active: false,
    position: {
      x: 2795,
      y: 2691,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.yeolde-sweetshoppe.com/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/YeOldeSweetShoppe4/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/CocoaAmore/",
      // },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/cocoaamore/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Take a trip down memory lane with a visit to this lovely Leicester shop. You’ll be spoiled for choice by the rows of sweet tubs, featuring modern and traditional sweets, such as rhubarb and custard pips, fizzy fish and floral gums, as well as a range of fudges and toffees
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "Shopping, sweet treat, independant",
    category: ["shopping", "sweet treat", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/sweet_shoppe.jpg",
      },
    ],
    link: {
      text: "4 Hotel Street, Leicester LE1 5AW",
      url: "http://maps.google.com/?q=YeOldeSweetShoppe 4 Hotel Street, Leicester LE1 5AW",
    },
  },

  {
    name: "Creams Café",
    active: false,
    position: {
      x: 3645,
      y: 2725,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.creamscafe.com/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://facebook.com/YeOldeSweetShoppe4/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/CocoaAmore/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/creamscafeuk/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Creams is known for its flamboyant desserts, including sundaes (such as the hot’n’cold chocolate fudge volcano) and crepes (including the Lotus Biscoff). There’s an array of waffles, including bubble pop waffles, which are exclusive to Creams, and available with toppings such as the Banoffle Crunch. Creams also serves gelato and sorbet, and puddings such as Cookies and Cream Cheesecake or raspberry jam slice.
      </p>
       </div>
    `,
    icon: "sweet_treat.svg",
    brand: "giftcard.png",
    categoryname: "sweet treat, independant",
    category: ["sweet treat", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/creams.jpg",
      },
    ],
    link: {
      text: "4-8 Granby Place, Leicester LE1 1JA",
      url: "http://maps.google.com/?q=CreamsCafe 4-8 Granby Place, Leicester LE1 1JA",
    },
  },

  {
    name: "Doughnotts",
    active: false,
    position: {
      x: 2890,
      y: 2266,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.doughnottsofficial.com/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/Doughnotts/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/doughnotts/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/doughnotts_official/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      This independent doughnut company serves up amazing doughnuts, which are made fresh early each morning. Standard doughnuts include Death by Chocolate, Jammy Devil and Salted Caramel, while premium doughnuts include Thicka Than a Snicka, Cherry Bakewell and Smarty Pants.
      </p>
       </div>
    `,
    icon: "sweet_treat.svg",
    brand: "giftcard.png",
    categoryname: "sweet treat, independant",
    category: ["sweet treat", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/doughnotts.jpg",
      },
    ],
    link: {
      text: "3 Silver Arcade, Leicester LE1 5FA",
      url: "http://maps.google.com/?q=Doughnotts 3 Silver Arcade, Leicester LE1 5FA",
    },
  },

  {
    name: "Haute Dolci",
    active: false,
    position: {
      x: 4230,
      y: 3165,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://hautedolci.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/hautedolci/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/doughnotts/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/hautedolci/ ",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Dessert restaurant Haute Dolci aims to provide an exclusive restaurant specialising in lovingly-crafted luxury desserts. There is an open-plan dessert and drinks bar - complete with ice cream and patisserie display cabinets, and chocolate taps. Haute Dolci offers the likes of brownies, crepes, fruit sundaes and golden waffles, as well as creamy shakes, sparkling fruit sorbet floats and mocktails.
      </p>
       </div>
    `,
    icon: "sweet_treat.svg",
    brand: "giftcard.png",
    categoryname: "sweet treat",
    category: ["sweet treat", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/dolci.jpg",
      },
    ],
    link: {
      text: "84-86 Granby Street, Leicester LE1 1DJ",
      url: "http://maps.google.com/?q=HauteDolci 84-86 Granby Street, Leicester LE1 1DJ",
    },
  },

  {
    name: "Rossas Doughnuts",
    active: false,
    position: {
      x: 3101,
      y: 2366,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://hautedolci.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/Rossas-Doughnuts-813572115654116/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/doughnotts/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/rossasdoughnuts/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      This kiosk by Leicester Market serves up hot fresh ring doughnuts, premium serve ice cream, groundnut fried Belgian frites, Frankfurter hotdogs, Angus burgers along with slushies and shakes – the perfect pit stop to fuel you city centre shopping.
      </p>
       </div>
    `,
    icon: "sweet_treat.svg",
    brand: "giftcard.png",
    categoryname: "sweet treat, independant",
    category: ["sweet treat", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/rossas_doughnuts.jpg",
      },
    ],
    link: {
      text: "Market Place, Leicester LE1 5EN",
      url: "http://maps.google.com/?q=RossasDoughnuts Market Place, Leicester LE1 5EN",
    },
  },

  {
    name: "Waffle & Scoop",
    active: false,
    position: {
      x: 3761,
      y: 3146,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.waffleandscoop.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/waffleandscoop/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/doughnotts/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/waffleandscoop/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Enjoy delicious, sweet and chewy Belgian Liège waffles – made with a secret recipe that dates back to the 1950s. But it’s not all waffles! Pop in for a fresh, healthy brunch or proper milkshakes at any time of day.
      </p>
       </div>
    `,
    icon: "sweet_treat.svg",
    // brand: "in-logo-black.png",
    categoryname: "sweet treat, independant",
    category: ["sweet treat", "independant"],
    carousel: [
      {
        type: "image",
        content: "cafe/wafflescoop.jpg",
      },
    ],
    link: {
      text: "18 Belvoir Street, Leicester LE1 6QH",
      url: "https://www.google.com/maps/place/Waffle+%26+Scoop/@52.6330396,-1.1328969,17z/data=!3m1!4b1!4m5!3m4!1s0x48776121fcbc862d:0x9c1039413f285d9c!8m2!3d52.6330396!4d-1.1307082",
    },
  },

  {
    name: "CUPP Bubble Tea",
    active: false,
    position: {
      x: 3104,
      y: 2156,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "www.cuppbubbletea.co.uk",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/waffleandscoop/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/doughnotts/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/cuppbubbletea",
      },
    ],
    content: `
    <div class="intro">
      <p>
      CUPP is a premium Bubble Tea brand who make bubble tea in a
      unique way. Made using quality loose leaf tea, fresh fruit puree and fresh milk (or dairy substitutes) to make the tastiest bubble teas. Choose from an array of fresh flavours and combinations like mango green tea with passionfruit popping bubbles to warm classic oolong milk tea with coconut jelly, or our famous premium brown sugar milk with tapioca pearls. There’s a CUPP flavour for everyone.
      </p>
       </div>
    `,
    icon: "sweet_treat.svg",
    brand: "giftcard.png",
    categoryname: "sweet treat",
    category: ["sweet treat", "gift card"],
    carousel: [
      {
        type: "image",
        content: "cafe/cupp_1.jpg",
      },
      {
        type: "image",
        content: "cafe/cupp_2.jpg",
      },
    ],
    link: {
      text: "5 East Gates, Leicester, LE1 5YA",
      url: "https://www.google.com/maps/place/Waffle+%26+Scoop/@52.6330396,-1.1328969,17z/data=!3m1!4b1!4m5!3m4!1s0x48776121fcbc862d:0x9c1039413f285d9c!8m2!3d52.6330396!4d-1.1307082",
    },
  },

  /**************************************/
  /**************************************/
  /******** Category: Shopping **********/
  /**************************************/
  /**************************************/

  {
    name: "Highcross Leicester",
    active: false,
    position: {
      x: 2368,
      y: 2205,
    },
    transform: {
      width: 70,
      height: 70,
    },
    website: "https://www.highcrossleicester.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/highcross/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/Highcross/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/highcross/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      If you’re after a spot of retail therapy head to the Highcross shopping centre, where you’ll find more than 100 shops, including many clothing and jewellery stores - so you’re bound to go home with a new outfit. Stores include the likes of John Lewis, Apple, Build-a-Bear, Zara, Jo Malone, Reiss and Tiger. The shopping centre is also home to a 12-screen cinema, where you could catch one of the latest film releases, as well as a number of well-known cafes and restaurants, where you could grab a bite to eat.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping",
    category: ["shopping", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/highcross.jpg",
      },
      {
        type: "image",
        content: "lunch_dinner/1573.jpg",
      },
    ],
    link: {
      text: "High Street, Leicester, LE1 4JB",
      url: "https://www.google.com/maps/place/Highcross/@52.6356647,-1.1389129,16z/data=!4m5!3m4!1s0x4877611e30a24e39:0x2cdfaba4990e5c4f!8m2!3d52.6366196!4d-1.1369967",
    },
  },

  {
    name: "Just Fair Trade",
    active: false,
    position: {
      x: 2731,
      y: 2367,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://justfairtrade.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/justfairtrade",
      },
      {
        name: "twitter",
        url: "https://twitter.com/JustFairtrade/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/the_just_shop/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      A workers’ co-operative social enterprise, Just Fair Trade is trading for a better world, selling a range of fair trade ethical and sustainable products that help people in the world’s poorest countries to work their way out of poverty. Friendly staff can help you find what you are looking for and tell you the story behind each product. Browse beautiful jewellery, crafts, gifts, clothes, food and soft furnishings.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/justfairtrade_1.jpg",
      },
      {
        type: "image",
        content: "shopping/justfairtrade_2.jpg",
      },
    ],
    link: {
      text: "36 Silver Street, Leicester LE1 5ET",
      url: "http://maps.google.com/?q=JustFairTrade 36 Silver Street, Leicester LE1 5ET",
    },
  },

  {
    name: "Currant Affairs",
    active: false,
    position: {
      x: 2621,
      y: 2587,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.currantaffairs.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/CurrantAffairsLeicester/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/JustFairtrade/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/currantaffairs/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Currant Affairs sell a range of vegan treats and essential supplies along with our home made bakes and takeaway.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    // brand: "in-logo-black.png",
    categoryname: "shopping, lunch",
    category: ["shopping", "lunch"],
    carousel: [
      {
        type: "image",
        content: "shopping/current-affairs.jpg",
      },
    ],
    link: {
      text: "9A Loseby Lane, Leicester LE1 5DR",
      url: "https://www.google.com/maps/place/Currant+Affairs/@52.634689,-1.1379547,17z/data=!3m1!4b1!4m5!3m4!1s0x4877611fdc6ac059:0xccbb4c2739b7fe15!8m2!3d52.634689!4d-1.135766",
    },
  },
  {
    name: "Harriman & Co",
    active: false,
    position: {
      x: 2658,
      y: 2635,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://harrimanandco.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/harrimanandco/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/harrimanandco/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/harrimanandco/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Harriman & Co offer a beautiful and eclectic range of household items and furniture for “the considered home”. Working with many talented makers and producers from around the world, as well as creating items in their Leicestershire workshop, Harriman offers stylish furniture, lighting, mirrors, art, home fragrances, bath and body products and household utensils.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/harriman_1.jpg",
      },
      {
        type: "image",
        content: "shopping/harriman_2.jpg",
      },
      {
        type: "image",
        content: "shopping/harriman_3.jpg",
      },
    ],
    link: {
      text: "11 St Martins, Leicester LE1 5DE",
      url: "http://maps.google.com/?q=Harriman 11 St Martins, Leicester LE1 5DE",
    },
  },

  {
    name: "Harlequin Interiors",
    active: false,
    position: {
      x: 2627,
      y: 2532,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.harlequininteriors.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/harlequininteriordesign/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/harrimanandco/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/harlequininteriordesign/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Full of imaginative, contemporary furniture and the latest ideas in interior design, Harlequin Interiors might surprise you at how affordable top quality design can be. Customers can browse the latest fabric and wallpaper collections, find inspiration in the store’s extensive showrooms, seek advice from helpful, informed staff and discover the perfect solution to home furnishing dreams.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/harlequin_1.jpg",
      },
      {
        type: "image",
        content: "shopping/harlequin_2.jpg",
      },
      {
        type: "image",
        content: "shopping/harlequin_3.jpg",
      },
    ],
    link: {
      text: "11 Loseby Lane, Leicester LE1 5DR",
      url: "http://maps.google.com/?q=HarlequinInteriors 11 Loseby Lane, Leicester LE1 5DR",
    },
  },

  {
    name: "Lanes Fine Jewellery",
    active: false,
    position: {
      x: 2578,
      y: 2455,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.lanesjewellers.com/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/lanesfinejewellery/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/jewellerlanes/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/lanesfinejewellery/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Offering exquisite fine diamond jewellery, Lanes stocks GIA and IGI-certificated diamond engagement rings along with natural and coloured diamonds, as well as diamond tennis bracelets, line necklaces, studs and pendants. With a background in Bond Street and Hatton Garden, Lanes has connections to sources of the world’s rarest and most precious diamonds. And if you’re looking to create your own engagement or wedding ring, Lanes can help you to design something exclusive to you in their relaxed showroom. It will then be produced by the best goldsmiths and diamond setters who will ensure a perfect result.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/lanes-fine-jewelry.jpg",
      },
    ],
    link: {
      text: "25 Loseby Lane, Leicester LE1 5DR",
      url: "http://maps.google.com/?q=LanesFineJewellery 25 Loseby Lane, Leicester LE1 5DR",
    },
  },

  {
    name: "NADA Zero Waste",
    active: false,
    position: {
      x: 2801,
      y: 2500,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://zerowasteleicester.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/zerowasteleicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/zerowasteleics/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/zerowasteleicester/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Leicester’s first and currently only zero waste, plastic-free bulk store, NADA sells a huge range of sustainable food items including seeds, flours, pasta, nuts, vegan chocolate, cereals and spices. All stock is sourced as locally as possible to reduce travel emissions. They also stock a huge range of cosmetics, feminine products and water bottles as well as cleaning products and detergents.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant" ,"gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/nada.jpg",
      },
    ],
    link: {
      text: "5 St Martins Walk, Leicester LE1 5DG",
      url: "http://maps.google.com/?q=NADA 5 St Martins Walk, Leicester LE1 5DG",
    },
  },

  {
    name: "Pomponette",
    active: false,
    position: {
      x: 2528,
      y: 2575,
    },
    transform: {
      width: 50,
      height: 50,
    },
    // website: "https://zerowasteleicester.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/Pomponette/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/Pomponette10",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/pomponettehome/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      With a passion for modern, rustic interiors, Pomponette are purveyors of timeless yet quirky and unusual furniture and homeware. Unique products are carefully selected to create seasonal, eclectic and inspiring collections that are bang on trend for modern city and country homes. Among their products are vintage, hand-painted furniture using Chalk Paint by Annie Sloan and bespoke, hand-crafted furniture painted in any Farrow & Ball colour. You’ll also find cards, clocks, gifts, home and kitchenware, lighting, mirrors, prints, framed art, home fragrances, rugs, candles and soft furnishings.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/pomponette.jpg",
      },
    ],
    link: {
      text: "10 Loseby Lane, Leicester LE1 5DR",
      url: "http://maps.google.com/?q=Pomponette 10 Loseby Lane, Leicester LE1 5DR",
    },
  },

  {
    name: "Maynard & Bradley Bookshop",
    active: false,
    position: {
      x: 2637,
      y: 2311,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.secondhandbooksleicester.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/MaynardandBradley/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/Pomponette10",
      // },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/pomponettehome/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Discover a fabulous world of second hand books, sports autographs, memorabilia, maps, prints and posters as well as onsite picture framing at Maynard & Bradley. They also buy books you are looking to sell. They can frame almost anything - nothing is too small - and have more than 100 matting and framing options. You’ll also find limited edition prints by artist Todd White.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/maynard_bradley.jpg",
      },
    ],
    link: {
      text: "1 Royal Arcade Silver St, Leicester LE1 5YW",
      url: "http://maps.google.com/?q=MaynardBradley 1 Royal Arcade Silver St, Leicester LE1 5YW",
    },
  },

  {
    name: "Flowerworks",
    active: false,
    position: {
      x: 2558,
      y: 2635,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.flowerworks.co.uk/",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://facebook.com/MaynardandBradley/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/Pomponette10",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/flowerworks.uk/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      With an interest in nature deep-rooted from an early age and heavily influenced by her Indian farming parents, Flowerworks founder Parveen Rana has gathered a wealth of experience and knowledge of floristry during a career of organising large exhibitions and events, and has worked on many collaborations and projects with well-known European and UK floral designers. From her pretty shop in Loseby Lane, customers can buy an abundance of beautiful blooms, whether they be flower arrangements for someone special, bridal bouquets, wreaths, floral tributes or house plants.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/flowerworks.jpg",
      },
    ],
    link: {
      text: "2 Loseby Lane, Leicester LE1 5DR",
      url: "http://maps.google.com/?q=Flowerworks 2 Loseby Lane, Leicester LE1 5DR",
    },
  },

  {
    name: "The Wardrobe",
    active: false,
    position: {
      x: 2699,
      y: 2293,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website:
      "https://www.thewardrobeclothing.com/store/c1/Featured_Products.html/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/TheWardrobeNow/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/thewardrobenow/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/thewardrobeleic/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Get your groove on at The Wardrobe, Leicester’s longest-running vintage clothing shop, offering a selection of vintage, new and retro clothing. Products range from cute Moomin toys, purses and flasks to classic Eastpack, Adidas and Nike trainers. Clothing varies for each season and includes Levi denim jackets and shorts, classic Fred Perry polos, 1960s dresses, 80s/90s shirts, USA college sweatshirts and much more with prices starting at around £10.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/thewardrobe.jpg",
      },
    ],
    link: {
      text: "6 Royal Arcade, Silver Street, Leicester LE1 5YW",
      url: "http://maps.google.com/?q=TheWardrobe 6 Royal Arcade, Silver Street, Leicester LE1 5YW",
    },
  },

  {
    name: "Rockaboom",
    active: false,
    position: {
      x: 2839,
      y: 2382,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://rockaboom.edan.io/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/Rockaboom-145717505477293/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/thewardrobenow/",
      // },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/thewardrobenow/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Do you love your vinyl? Then you’ll adore Rockaboom, Leicester’s local, independent music store where you’ll find new and secondhand records covering everything from alternative, reggae, punk and metal to folk, jazz, d&b, rock and pop
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/rockaboom.jpg",
      },
    ],
    link: {
      text: "18 Malcolm Arcade, Leicester LE1 5FT",
      url: "http://maps.google.com/?q=Rockaboom 18 Malcolm Arcade, Leicester LE1 5FT",
    },
  },

  {
    name: "Tinfish Shoes",
    active: false,
    position: {
      x: 2589,
      y: 2265,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.tinfishshoes.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/tinfishshoesleicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/tinfishshoes/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/tinfishshoes/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      The story of Tinfish starts in 1991 when the shop was set up after its owner felt that, despite the Midlands being the centre of UK shoe manufacturing at the time, there was nowhere to buy stylish and design-led footwear in the city. They have since introduced to Leicester brands such as Loake, Fly London, Birkenstock, Vagabond, Yokono, Camper, Irregular Choice, Ruby Shoo 10 and many more and are always on the lookout for exciting and distinctive new designs.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/tinfish.jpg",
      },
    ],
    link: {
      text: "Royal Arcade, Leicester LE1 5YW",
      url: "http://maps.google.com/?q=TinfishShoes Royal Arcade, Leicester LE1 5YW",
    },
  },

  {
    name: "The Very Bazaar",
    active: false,
    position: {
      x: 2585,
      y: 2325,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://theverybazaar.bigcartel.com/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/theverybazaarleicester",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/tinfishshoes/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/theverybazaarleicester/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Perfect for those with a passion for all things quirky, magical and new age, if it’s a
      bit bizarre, The Very Bazaar will have it. The shop specialises in gifts and cards as well as crystals, jewellery, fashion accessories, incense, soft furnishings, ornaments and smoking paraphernalia.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/very_bazaar.jpg",
      },
    ],
    link: {
      text: "7-41, Silver Street, Leicester LE1 5EU",
      url: "http://maps.google.com/?q=TheVeryBazaar R7-41, Silver Street, Leicester LE1 5EU",
    },
  },

  {
    name: "Wellgosh",
    active: false,
    position: {
      x: 2731,
      y: 2234,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://wellgosh.com/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/wellgosh/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/wellgosh/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/wellgosh/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      A designer clothing store, Wellgosh stocks the top brands in men’s footwear, accessories and clothing, including Adidas Originals, Fred Perry, The North Face, Orslow and Nike.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/wellgosh.jpg",
      },
    ],
    link: {
      text: "34 High Street, Leicester LE1 5YN",
      url: "http://maps.google.com/?q=WellGosh 34 High Street, Leicester LE1 5YN",
    },
  },

  {
    name: "Set",
    active: false,
    position: {
      x: 2715,
      y: 2155,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.myset.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://facebook.com/SetLeicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/SetLeicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/setleicester/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Set is your gift solution, with its eclectic range of trendy designer gifts including watches from Ice, Fossil, Diesel and DKNY, trinkets and toys as well as greeting cards. Other brands in stock include Ted Baker, CK, Orla Kiely, Sass & Bell, Tutti & Co an Alessi.
      </p>
       </div>
    `,
    icon: "shopping.svg",
    // brand: "in-logo-black.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/set.jpg",
      },
    ],
    link: {
      text: "49-51 High Street, Leicester LE1 4FP",
      url: "http://maps.google.com/?q=Set 49-51 High Street, Leicester LE1 4FP",
    },
  },

  {
    name: "Lush",
    active: false,
    position: {
      x: 3165,
      y: 2145,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://uk.lush.com/shop/leicester",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://facebook.com/SetLeicester/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/SetLeicester/",
      // },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/wellgosh/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Inventors of the bath bomb and home of bath art, Lush is famous for its handmade vegetarian, vegan and cruelty-free cosmetics. Haircare and skincare are all catered for and the company strives to use recycled plastics and no plastic at all.      </p>
       </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/lush.jpg",
      },
    ],
    link: {
      text: "11 Gallowtree Gate, Leicester LE1 5AD",
      url: "http://maps.google.com/?q=Lush 11 Gallowtree Gate, Leicester LE1 5AD",
    },
  },

  {
    name: "Flannels",
    active: false,
    position: {
      x: 3568,
      y: 2590,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.flannels.com/leicesterfl-store-1781/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/flannels/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/FLANNELSFASHION/",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/wellgosh/",
      // },
    ],
    content: `
    <div class="intro">
      <p>
      Shop men’s, women’s and kids’ luxury clothing, shoes and accessories from the most coveted designer brands. With a brand portfolio including Gucci, Prada, Chloe, Burberry, Off White, Stone Island, Moncler and CP Company, Flannels promises first class customer service in a smart setting.
      </p>
    </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping",
    category: ["shopping", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/flannels.jpg",
      },
    ],
    link: {
      text: "56 Gallowtree Gate, Leicester LE1 1DA",
      url: "http://maps.google.com/?q=Flannels 56 Gallowtree Gate, Leicester LE1 1DA",
    },
  },

  {
    name: "Lumbers",
    active: false,
    position: {
      x: 2415,
      y: 2346,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.lumbers.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/lumbersjeweller/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/lumbersjeweller/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/lumbersjeweller/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      Established in 1881, Lumbers has been at the heart of Leicester’s success for more than a century, offering expert service and knowledge to create a luxury and friendly jewellery-buying experience. Specialising in diamonds, rings and pre-owned and vintage items, Lumbers also stocks top watch brands including Gucci, Omega, Hublot, Tissot and Breitling.
      </p>
    </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/lumbers.jpg",
      },
    ],
    link: {
      text: "62-66 High Street, Leicester LE1 5YP",
      url: "http://maps.google.com/?q=Lumbers 62-66 High Street, Leicester LE1 5YP",
    },
  },

  {
    name: "23 Wine & Whiskey",
    active: false,
    position: {
      x: 4073,
      y: 3065,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://23wineandwhiskey.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/23wineandwhiskey/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/23wandw",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/23wineandwhiskey/",
      },
    ],
    content: `
    <div class="intro">
      <p>
      The number 23 represents the 23rd letter of the alphabet - W, without which you couldn’t complete the words, water, wood, weather or wheat - which all go into making fine wine, whiskey and other spirits. 23 Wine & Whiskey is a specialist independent retailer of fine wine and whiskey sourced locally and from around the world.      </p>
    </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/23_winewhiskey.jpg",
      },
    ],
    link: {
      text: "64-66 Granby Street, Leicester LE1 1DH",
      url: "http://maps.google.com/?q=23WineWhiskey 64-66 Granby Street, Leicester LE1 1DH",
    },
  },

  {
    name: "Haymarket Shopping Centre",
    active: false,
    position: {
      x: 3661,
      y: 1859,
    },
    transform: {
      width: 70,
      height: 70,
    },
    website: "https://haymarketshoppingcentre.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/HaymarketShopping/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/23wandw",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/HaymarketLeics/",
      },
    ],
    content: `
    <div class="intro">
    <p>In the heart of the city centre, the Haymarket Shopping Centre has more than 60 shops and food outlets. There are plenty of big name fashion retailers, such as Primark, which offers value for money fashion basics and homeware, and TKMaxx, where you can get designer labels and famous brands for up to 60% less - with a wide range of men’s, women’s and children’s clothing, shoes and accessories, homeware and giftware. Peacocks offers good quality, fashionable clothes for women, men and children at great prices.</p>

    <p>Other fashion outlets include Bon marché, Matalan, Top Girl, and Shoes 4 U, while jewellery stores include Bijal Jewellers, Warren James and RockC Jewellers. The centre is home to a number of health and beauty shops, including The Fragrance Shop, American Nails and Perfect Eyebrows. Food options include Caffe Nero, Costa coffee shop, Greggs, and Magic Corn - which serves whole non-cut kernels of sweetcorn.</p>

    </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping",
    category: ["shopping", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/haymarket_1.jpg",
      },
      {
        type: "image",
        content: "shopping/haymarket_2.jpg",
      },
      {
        type: "image",
        content: "shopping/haymarket_3.jpg",
      },
      {
        type: "image",
        content: "shopping/haymarket_4.jpg",
      },
    ],
    link: {
      text: "1 Kildare Street, Leicester LE1 3YH",
      url: "http://maps.google.com/?q=HaymarketShoppingCentre 1 Kildare Street, Leicester LE1 3YH",
    },
  },

  {
    name: "Fox Books",
    active: false,
    position: {
      x: 2652,
      y: 2441,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.foxbooksltd.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/FoxBooksLtd",
      },
      {
        name: "twitter",
        url: "https://twitter.com/FoxBooksLtd",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/FoxBooksLtd",
      },
    ],
    content: `
    <div class="intro">
    <p>A friendly independent bookshop stocking a wide range of fiction, non-fiction and children's titles.</p>
    </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping, independant",
    category: ["shopping", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/foxbooks.jpg",
      },
      // {
      //   type: "image",
      //   content: "shopping/haymarket_2.jpg",
      // },
      // {
      //   type: "image",
      //   content: "shopping/haymarket_3.jpg",
      // },
      // {
      //   type: "image",
      //   content: "shopping/haymarket_4.jpg",
      // },
    ],
    link: {
      text: "St Martin's Square, 9 Silver Walk, Leicester LE1 5EW",
      url: "https://www.google.com/maps/place/Fox+Books/@52.6350685,-1.1355263,15z/data=!4m5!3m4!1s0x0:0xb3c8d56ee857759b!8m2!3d52.6350685!4d-1.1355263?shorturl=1",
    },
  },

  {
    name: "Suit Direct",
    active: false,
    position: {
      x: 2220,
      y: 1770,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.suitdirect.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/SuitDirect",
      },
      {
        name: "twitter",
        url: "https://twitter.com/SuitDirect",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/suitdirectuk",
      },
    ],
    content: `
    <div class="intro">
    <p>Suit Direct is one of the fastest growing 
    menswear retailers in the UK, offering a fantastic range of suiting and accessories from leading brands including Ted Baker, Marc Darcy and Ben Sherman. With superb customer service and a constantly evolving range of products in-store you are guaranteed to find something to make you feel good and look great.</p>
    </div>
    `,
    icon: "shopping.svg",
    brand: "giftcard.png",
    categoryname: "shopping",
    category: ["shopping", "gift card"],
    carousel: [
      {
        type: "image",
        content: "shopping/suit_direct.jpg",
      },
      // {
      //   type: "image",
      //   content: "shopping/haymarket_2.jpg",
      // },
      // {
      //   type: "image",
      //   content: "shopping/haymarket_3.jpg",
      // },
      // {
      //   type: "image",
      //   content: "shopping/haymarket_4.jpg",
      // },
    ],
    link: {
      text: "UNIT LL91, Lower Mall, HighCross Shopping Centre, LE1 4FS",
      url: "https://www.google.com/maps/place/Suit+Direct+Leicester/@52.6370333,-1.1396844,17z/data=!3m1!4b1!4m5!3m4!1s0x4877616c9af122b5:0xc3d561938ec530a!8m2!3d52.6370333!4d-1.1374957",
    },
  },

  /**************************************/
  /**************************************/
  /*** Category: Pubs & Bars/Nightclubs **/
  /**************************************/
  /**************************************/

  {
    name: "Bruxelles Leicester",
    active: false,
    position: {
      x: 2212,
      y: 2385,
    },
    transform: {
      width: 50,
      height: 50,
    },
    // website: "https://haymarketshoppingcentre.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/bruxellesleic/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/23wandw",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/bruxellesleicester/",
      },
    ],
    content: `
    <div class="intro">
    <p>
    Specialising in Belgian beers, Bruxelles Leicester also serves cocktails, spirits and wine in a low-lit bistro with ornate, domed ceiling and armchairs. The venue, which has been at the heart of Leicester’s night life for a quarter of a century, also boasts a seating area at the front of the building and a fabulous refubished Hendricks Gin Garden at the rear.
    </p>

    </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: ["pubs & bars, nightclubs, independant"],
    category: ["pubs bars", "nightclubs", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/bruxelles.jpg",
      },
    ],
    link: {
      text: "90-92 High Street, Leicester LE1 5YP",
      url: "http://maps.google.com/?q=Bruxelles Leicester 90-92 High Street, Leicester LE1 5YP",
    },
  },

  {
    name: "Fan Club",
    active: false,
    position: {
      x: 3282,
      y: 845,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.fanclubnightclub.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/FanClubNightclub/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/fanleicester",
      },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/fan_leicester",
      // },
    ],
    content: `
    <div class="intro">
    <p>
    The legendary home of 80s, 90s and indie club nights, this popular nightclub was opened in 1985 by Brian Selby of Selectadisc and launched the career of top House DJ Graeme Park. It has since undergone several expansion projects, with its last major refurbishment in 2017, and remains Leicester’s go-to destination for clubbers - whether they’re looking for DJ-led nights or live music.
    </p>
    </div>
    `,
    icon: "nightclubs.svg",
    brand: "giftcard.png",
    categoryname: ["nightclubs, independant"],
    category: ["nightclubs", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/clubbing.jpg",
      },
    ],
    link: {
      text: "41 Abbey Street, Leicester LE1 3TE",
      url: "http://maps.google.com/?q=TheFanClub 41 Abbey Street, Leicester LE1 3TE",
    },
  },

  {
    name: "Mosh",
    active: false,
    position: {
      x: 1762,
      y: 2406,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.moshleicester.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/moshleicester/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/23wandw",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/moshleicester/",
      },
    ],
    content: `
    <div class="intro">
    <p>
    Leicester’s only three-floor Alternative nightclub, Mosh hosts five great nights of amazing alternative and commercial music each week. Proud to offer all genres of music, there’s no dress code, just an invitation to have a great night out in a safe, fun atmosphere. Open until 4am at the weekend.
    </p>

    </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: ["pubs & bars, nightclubs, independant"],
    category: ["pubs bars", "nightclubs", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/clubbing.jpg",
      },
    ],
    link: {
      text: "37 St Nicholas Place, Leicester LE1 4LD",
      url: "http://maps.google.com/?q=Mosh 37 St Nicholas Place, Leicester LE1 4LD",
    },
  },

  {
    name: "The Terrace",
    active: false,
    position: {
      x: 3473,
      y: 3400,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.theterrace-leicester.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/TheTerraceChampagneandCocktailBar/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/23wandw",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/terracebarleicester/",
      },
    ],
    content: `
    <div class="intro">
    <p>
    One of Leicester’s premium champagne and cocktail bars, The Terrace is a bustling venue hosting live music and weekend DJs. On the last Thursday of the month, a full band plays all the best party and feel-good tunes. Open until 2am at the weekend.
    </p>
    </div>
    `,
    icon: "nightclubs.svg",
    brand: "giftcard.png",
    categoryname: ["nightclubs, independant"],
    category: ["nightclubs", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/terracebar.jpg",
      },
    ],
    link: {
      text: "1 Albion Street, Leicester LE1 6GD",
      url: "http://maps.google.com/?q=TerraceBar 1 Albion Street, Leicester LE1 6GD",
    },
  },

  {
    name: "The Basement",
    active: false,
    position: {
      x: 3402,
      y: 3684,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "http://www.thebasementbar.co.uk/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/thebasementleic/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/23wandw",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/thebasementleic/",
      },
    ],
    content: `
    <div class="intro">
    <p>
    Exposed brick basement with armchairs, wooden tables and neon, The Basement is the venue in Leicester for soul, funk, disco, indie, hip hop and rare groove. Cocktails are on the menu (happy hour is 10pm until 1am every day) and you can dance the night away until 6am at weekends.
    </p>
    </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    categoryname: ["pubs & bars, nightclubs, independant"],
    category: ["pubs bars", "nightclubs", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/thebasement.jpg",
      },
    ],
    link: {
      text: "1 Wellington Street, Leicester LE1 6HH",
      url: "http://maps.google.com/?q=TheBasement 1 Wellington Street, Leicester LE1 6HH",
    },
  },

  {
    name: "Club Republic",
    active: false,
    position: {
      x: 3063,
      y: 967,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.club-republic.com/",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/clubrepublic/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/23wandw",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/clubrepublic/",
      },
    ],
    content: `
    <div class="intro">
    <p>
    Enter this boudoir red multi-room nightclub and be greeted by a stateof-the art sound system, cocktail booths and a VIP bar - as well as Leicester’s biggest and loudest Saturday night party every week. Open until 4am at the weekend.
    </p>
    </div>
    `,
    icon: "nightclubs.svg",
    brand: "giftcard.png",
    categoryname: ["nightclubs, independant"],
    category: ["nightclubs", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/clubbing.jpg",
      },
    ],
    link: {
      text: "Gravel Street, Leicester LE1 3AG",
      url: "http://maps.google.com/?q=ClubRepublic Gravel Street, Leicester LE1 3AG",
    },
  },

  {
    name: "Revolution Leicester",
    active: false,
    position: {
      x: 3607,
      y: 3860,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.revolution-bars.co.uk/bar/leicester/",
    categoryname: ["pubs & bars, nightclubs"],
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/revolutionbars/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/revolutionbars",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/revolutionbars/",
      },
    ],
    content: `
    <div class="intro">
    <p>
    A bustling cocktail bar, restaurant and party venue, Revolution Leicester brings you premium drinks, delicious food and unforgettable party nights all in the heart of the city. The bar boasts a great combination of modern style and added flair, featuring high ceilings, an impressive mezzanine floor and trendy exposed brick interior. It’s perfect for a business lunch during the day or partying until the small hours with friends.
    </p>
    </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    category: ["pubs bars", "nightclubs", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/revolution.jpg",
      },
    ],
    link: {
      text: "6b New Walk, Leicester LE1 6TF",
      url: "http://maps.google.com/?q=Revolution 6b New Walk, Leicester LE1 6TF",
    },
  },

  {
    name: "Fat Cat Café Bar",
    active: false,
    position: {
      x: 3517,
      y: 3230,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.fatcatleicester.co.uk/",
    categoryname: ["pubs & bars, nightclubs, independant"],
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/FatCatLeicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/FatCatLeicester/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/fatcatleicester/",
      },
    ],
    content: `
    <div class="intro">
    <p>
    A contemporary cafe/bar furnished in leather and plush velvet, Fat Cat has recently undergone a £300,000 refurbishment making it the smart choice for enjoying cocktails until the small hours in a relaxed atmosphere. A floral decor, with plants hanging from the ceiling, a wall of flowers and soft lighting all add to the experience. Open until 4am at the weekend.
    </p>
    </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    category: ["pubs bars", "nightclubs", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/fatcat.jpg",
      },
    ],
    link: {
      text: "41 Belvoir Street, Leicester LE1 6SL",
      url: "http://maps.google.com/?q=FatCat 41 Belvoir Street, Leicester LE1 6SL",
    },
  },

  {
    name: "Apres Lounge",
    active: false,
    position: {
      x: 2148,
      y: 2395,
    },
    transform: {
      width: 50,
      height: 50,
    },
    // website: "https://www.fatcatleicester.co.uk/",
    categoryname: ["pubs & bars, nightclubs, independant"],
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/apresloungeleicester/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/FatCatLeicester/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/theapreslounge/",
      },
    ],
    content: `
    <div class="intro">
    <p>Après Lounge is an après ski bar in the style of an Alpine Lodge. Complete with a cosy roaring fire and wood panelling. The bar offers a range of drinks including a cocktail menu and even has student offers in the week.
    </p>
    </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    category: ["pubs bars", "nightclubs", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/apres.jpg",
      },
    ],
    link: {
      text: "94 High Street, Leicester LE1 5YP",
      url: "https://www.google.com/maps/place/Apr%C3%A8s+Lounge/@52.6351889,-1.1379678,18.25z/data=!3m1!5s0x487760e04f4e010f:0x8d8155b8613ad730!4m5!3m4!1s0x487760e030bd5d05:0xa7493dd078fe1f15!8m2!3d52.6352632!4d-1.1379034?shorturl=1",
    },
  },

  /**************************************/
  /**************************************/
  /* Category: Pubs & Bars/Entertainemnt */
  /**************************************/
  /**************************************/

  {
    name: "Firebug",
    active: false,
    position: {
      x: 2897,
      y: 3160,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://firebugbar.net/",
    categoryname: "Pubs & Bars, Nightclubs, Entertainment, Lunch, Dinner, independant",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/FirebugBar/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/FirebugBar/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/firebugbarleic/",
      },
    ],
    content: `
    <div class="intro">
    <p>
    Cool and spacious real ale and cocktail hangout, Firebug has fast become an essential part of Leicester’s night life, boasting a extensive drinks range, a carefully selected cocktail menu and a great selection of home-cooked food available every day. The late night venue’s relaxed dress code and music policy attracts clubbers from all walks of life and has a national reputation as an essential stopping point for visitors to the city. Live acts over the past few years have included Foals, Dutch Uncles and Frank Turner while the club often hosts stand-up comedians including Stewart Lee, Russell Howard and Josie Long.
    </p>
    </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    category: ["pubs bars", "nightclubs", "entertainment", "lunch", "dinner", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/firebug_1.jpg",
      },
      {
        type: "image",
        content: "pubsbars_nightclubs/firebug_2.jpg",
      },
      {
        type: "image",
        content: "pubsbars_nightclubs/firebug_3.jpg",
      },
      {
        type: "image",
        content: "pubsbars_nightclubs/firebug_4.jpg",
      },
    ],
    link: {
      text: "1 Millstone Lane, Leicester LE1 5JN",
      url: "http://maps.google.com/?q=Firebug 1 Millstone Lane, Leicester LE1 5JN",
    },
  },

  {
    name: "The Soundhouse",
    active: false,
    position: {
      x: 5007,
      y: 2305,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://www.thesoundhouseleicester.co.uk/",
    categoryname: "Pubs & Bars, Entertainment, independant",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/thesoundhouseleicester/",
      },
      {
        name: "twitter",
        url: "https://twitter.com/the_sound_house/",
      },
      {
        name: "instagram",
        url: "https://www.instagram.com/the_sound_house/",
      },
    ],
    content: `
    <div class="intro">
    <p>
    Located in St George’s Cultural Quarter, The Soundhouse is an independent music venue welcoming a mixture of local and touring artists covering a range of genres for all musical tastes, as well as hosting one of the best-loved open mic nights in Leicester. Look out for the fantastic art work on the actual building and in the beer garden. Highly-experienced technicians provide a professional sound and lighting experience in the venue which has an informal, friendly atmosphere. There’s also a good sized dance floor area.
    </p>
    </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    category: ["pubs bars", "entertainment", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/thesoundhouse.jpg",
      },
    ],
    link: {
      text: "28 Southampton Street, Leicester LE1 1SJ",
      url: "http://maps.google.com/?q=TheSoundhouse 28 Southampton Street, Leicester LE1 1SJ",
    },
  },

  {
    name: "The Shed",
    active: false,
    position: {
      x: 3997,
      y: 2055,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website: "https://getintheshed.co.uk/",
    categoryname: "Pubs & Bars, Entertainment, independant",
    socials: [
      {
        name: "facebook",
        url: "https://www.facebook.com/getintheshed94/",
      },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/the_sound_house/",
      // },
      {
        name: "instagram",
        url: "https://www.instagram.com/getintheshed94/",
      },
    ],
    content: `
    <div class="intro">
    <p>
    The Shed was originally opened in 1994 and ran for more than 20 years before new owners bought the building and, after an extensive renovation, re-opened it in 2017. Located in Leicester’s Cultural Quarter, it’s now run by a group of music-lovers who aim to make The Shed a welcoming place for people from all walks of life. It’s open from noon, six days a week, boasts outstanding coffee and very fast wifi, so it’s the perfect place to get some work done or host a casual meeting. It offers something different every night to suit all tastes and budgets, from open-mic nights, to jazz, comedy and games as well as an extensive itinerary of live music, for which it has become so famous.
    </p>
    </div>
    `,
    icon: "pubs_bars.svg",
    brand: "giftcard.png",
    category: ["pubs bars", "entertainment", "independant", "gift card"],
    carousel: [
      {
        type: "image",
        content: "pubsbars_nightclubs/theshed.jpg",
      },
    ],
    link: {
      text: "5 Yeoman Street, Leicester LE1 1UT",
      url: "http://maps.google.com/?q=TheShed 5 Yeoman Street, Leicester LE1 1UT",
    },
  },

  {
    name: "The High Cross",
    active: false,
    position: {
      x: 2024,
      y: 2325,
    },
    transform: {
      width: 50,
      height: 50,
    },
    website:
      "https://www.jdwetherspoon.com/pub-histories/england/leicestershire/the-high-cross-leicester",
    categoryname: "Pubs & Bars, Lunch, Dinner, independant",
    socials: [
      // {
      //   name: "facebook",
      //   url: "https://www.facebook.com/getintheshed94/",
      // },
      // {
      //   name: "twitter",
      //   url: "https://twitter.com/the_sound_house/",
      // // },
      // {
      //   name: "instagram",
      //   url: "https://www.instagram.com/getintheshed94/",
      // },
    ],
    content: `
    <div class="intro">
    <p>A cosy Wetherspoons pub, known for a wide selection of drinks and pub food classics.</p>

    <p>The pub is named after the High Cross, which stood just outside the building at the point where Highcross Street meets High Street, marking the centre of medieval Leicester. </p>
    
    </div>
    `,
    icon: "pubs_bars.svg",
    // brand: "in-logo-black.png",
    category: ["pubs bars", "lunch", "dinner", "independant"],
    carousel: [
      {
        type: "image",
        content: "pubs_bars/thehighcross.jpg",
      },
    ],
    link: {
      text: "103-105 High Street, Leicester LE1 4JB",
      url: "http://maps.google.com/?q=TheHighCross 103-105 High Street, Leicester LE1 4JB",
    },
  },

  /**************************************/
  /**************************************/
  //**********/ Category: EVENTS *********/
  /**************************************/
  /**************************************/

  // {
  //   name: "Rocket Round Leicester",
  //active: false,//   
  //position: {
  //     x: 3204,
  //     y: 2015,
  //   },
  //   transform: {
  //     width: 50,
  //     height: 50,
  //   },
  //   website: "https://rocketroundleicester.co.uk/",
  //   categoryname: "events",
  //   socials: [
  //     {
  //       name: "facebook",
  //       url: "https://www.facebook.com/RocketRoundLeicester/",
  //     },
  //     {
  //       name: "twitter",
  //       url: "https://twitter.com/rocketroundleic/",
  //     },
  //     {
  //       name: "instagram",
  //       url: "https://www.instagram.com/rocketroundleicester/",
  //     },
  //   ],
  //   content: `
  //   <div class="intro">
  //   <p><strong>19 July – 26 September</strong></p>  
    
  //   <p>Rocket Round Leicester is a spectacular public art event that weaves its way across the city in summer 2021. Giant Rocket sculptures, each one individually designed by an artist and sponsored by a business, form a free, fun, family-friendly trail of discovery for local people to explore and enjoy. At the end of the event the amazing sculptures will be auctioned to raise vital funds for LOROS Hospice. BID Leicester is a sponsor of the event, which has been created by Wild in Art together with LOROS Hospice.</p>
  //   <p>Photo credit: Beth Walsh</p>
     
  //   </div>
  //   `,
  //   icon: "events.svg",
  //   // brand: "in-logo-black.png",
  //   category: ["events"],
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "events/rocketround.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "Sculpture Trail around Leicester",
  //     url: "https://www.google.com/maps/place/The+Clock+Tower/@52.6356309,-1.1325653,17z/data=!4m5!3m4!1s0x4877611eff380d5d:0x1c9e48f6568026d7!8m2!3d52.6362283!4d-1.1330562",
  //   },
  // },

  // {
  //   name: "Architects of Air Luminarium",
  //active: false,//   
  //position: {
  //     x: 3024,
  //     y: 2705,
  //   },
  //   transform: {
  //     width: 50,
  //     height: 50,
  //   },
  //   website: "https://bidleicester.co.uk/luminarium/",
  //   categoryname: "events",
  //   socials: [
  //     {
  //       name: "facebook",
  //       url: "https://www.facebook.com/BIDLeicester1/",
  //     },
  //     {
  //       name: "twitter",
  //       url: "https://twitter.com/BIDLeicester",
  //     },
  //     {
  //       name: "instagram",
  //       url: "https://www.instagram.com/bidleicester/",
  //     },
  //   ],
  //   content: `
  //   <div class="intro">
  //   <p><strong>21- 25 July</strong></p>

  //   <p>Lose yourself in a labyrinth of colour, shapes, and winding tunnels! The Katena Luminarium is a mesmerising experience for children and adults alike. With a stunning kaleidoscope of colours, shapes and subtle sounds, be prepared for an awe-inspiring experience like no other.</p>
     
  //   </div>
  //   `,
  //   icon: "events.svg",
  //   // brand: "in-logo-black.png",
  //   category: ["events"],
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "events/luminarium.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "Green Dragon Square, Leicester LE1 5HB",
  //     url: "https://www.google.com/maps/place/52%C2%B038%2703.6%22N+1%C2%B008%2701.8%22W/@52.6343368,-1.1343732,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d52.6343356!4d-1.1338262",
  //   },
  // },

  // {
  //   name: "Brew Beat: Leicester Craft Beer Festival",
  //active: false,//   
  //position: {
  //     x: 2991,
  //     y: 2776,
  //   },
  //   transform: {
  //     width: 50,
  //     height: 50,
  //   },
  //   website: "https://bidleicester.co.uk/blog/events-schedule/",
  //   categoryname: "events",
  //   socials: [
  //     {
  //       name: "facebook",
  //       url: "https://www.facebook.com/BIDLeicester1/",
  //     },
  //     {
  //       name: "twitter",
  //       url: "https://twitter.com/BIDLeicester",
  //     },
  //     {
  //       name: "instagram",
  //       url: "https://www.instagram.com/bidleicester/",
  //     },
  //   ],
  //   content: `
  //   <div class="intro">
  //   <p><strong>28-30 August</strong></p>

  //   <p>A brand new event for the city celebrating Craft Beer! Relax in the main festival area and enjoy the music programme or explore a range of satellite venues to discover great offers in some of the city’s best loved pubs and bars.</p>
        
  //   </div>
  //   `,
  //   icon: "events.svg",
  //   // brand: "in-logo-black.png",
  //   category: ["events"],
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "events/brewbeat.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "Green Dragon Square, Leicester LE1 5HB",
  //     url: "https://www.google.com/maps/place/52%C2%B038%2703.6%22N+1%C2%B008%2701.8%22W/@52.6343368,-1.1343732,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d52.6343356!4d-1.1338262",
  //   },
  // },

  // {
  //   name: "Where There is Light",
  //active: false,//   
  //position: {
  //     x: 2378,
  //     y: 2592,
  //   },
  //   transform: {
  //     width: 50,
  //     height: 50,
  //   },
  //   website: "https://bidleicester.co.uk/blog/events-schedule/",
  //   categoryname: "events",
  //   socials: [
  //     {
  //       name: "facebook",
  //       url: "https://www.facebook.com/BIDLeicester1/",
  //     },
  //     {
  //       name: "twitter",
  //       url: "https://twitter.com/BIDLeicester",
  //     },
  //     {
  //       name: "instagram",
  //       url: "https://www.instagram.com/bidleicester/",
  //     },
  //   ],
  //   content: `
  //   <div class="intro">
  //   <p><strong>October half term</strong></p>
    
  //   <p>Immerse yourself in this ground-breaking, illuminated installation created by internationally-acclaimed digital arts group, Squidsoup. The installation provides a truly poignant, sensory experience for visitors, raising awareness of crucial work to support asylum seekers and refugees.</p>
          
  //   </div>
  //   `,
  //   icon: "events.svg",
  //   // brand: "in-logo-black.png",
  //   category: ["events"],
  //   carousel: [
  //     {
  //       type: "image",
  //       content: "events/wherethereislight.jpg",
  //     },
  //   ],
  //   link: {
  //     text: "Leicester Cathedral",
  //     url: "https://www.google.com/maps/place/Leicester+Cathedral/@52.6346498,-1.1393377,17z/data=!3m2!4b1!5s0x487760e03d17c3a5:0xa3138c86462230fb!4m5!3m4!1s0x487760e01e3e2d2f:0x404b36289c096a45!8m2!3d52.6346498!4d-1.137149?shorturl=1",
  //   },
  // },

//   {
//     name: "Rhizome",
//active: false,//     
//position: {
//       x: 4477,
//       y: 2403,
//     },
//     transform: {
//       width: 50,
//       height: 50,
//     },
//     website: "https://bidleicester.co.uk/blog/events-schedule/",
//     categoryname: "events",
//     socials: [
//       {
//         name: "facebook",
//         url: "https://www.facebook.com/BIDLeicester1/",
//       },
//       {
//         name: "twitter",
//         url: "https://twitter.com/BIDLeicester",
//       },
//       {
//         name: "instagram",
//         url: "https://www.instagram.com/bidleicester/",
//       },
//     ],
//     content: `
//     <div class="intro">
//     <p><strong>18 November - 5 January</strong></p>

//     <p>The winter skies around the Cultural Quarter will be lit up thanks to Rhizome: a glowing series of tall structures connected by gentle colour changing neon ropes, probing for unexpected connections, in search of the boundaries between nature and technology.</p>
              
//     </div>
//     `,
//     icon: "events.svg",
//     // brand: "in-logo-black.png",
//     category: ["events"],
//     carousel: [
//       {
//         type: "image",
//         content: "events/rhizome.jpg",
//       },
//     ],
//     link: {
//       text: "Orton Square",
//       url: "https://www.google.com/maps/place/Orton+Square,+Leicester/@52.6346003,-1.1276792,18.63z/data=!4m5!3m4!1s0x487761186136c667:0x717a4098c153d8b4!8m2!3d52.6341652!4d-1.1266143",
//     },
//   },


];
