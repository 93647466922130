const theme = {
  colours: {
    primary: "#e5007d",
    secondary: "#ffe604",
    text: "white",
    background: "#C7D5D6",
  },
  sizing: {
    fontSize: "16px",
    gap: "16px",
  },
  font: "brother-1816-printed, sans-serif",
};

export default theme;
