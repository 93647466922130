import styled from "styled-components";

const ModalStyles = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  overflow: visible;
  background-color: #e5007d;

  @media only screen and (max-width: 600px) {
    padding: 15px 20px 10px 20px;
  }

  .body {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100% - 50px);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;

    @media only screen and (max-width: 600px) {
      margin-top: 20px;
      height: calc(100% - 10px);
    }

    h1 {
      font-family: ${(props) => props.theme.font};
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
      /* color: ${(props) => props.theme.colours.primary}; */
      color: white;
      text-align: center;
      padding-bottom: 10px;
      font-size: 2.4em;
      letter-spacing: 2px;
      line-height: 1;

      @media only screen and (max-width: 600px) {
        font-size: 26px;
        padding-bottom: 8px;
      }
    }

    @media only screen and (max-width: 600px) {
      .header {
        margin-top: 20px;
      }
    }

    .address {
      margin-bottom: 5px;
      text-align: center;
      a {
        font-family: brother-1816-printed, sans-serif;
        color: white;
        font-size: 18px;
        text-decoration: none;
        transition-duration: 0.4s;
        :hover {
          color: yellow;
        }
        @media only screen and (max-width: 600px) {
          font-size: 18px;
        }
      }
    }
    .categoryname {
      font-size: 11px;
      font-family: brother-1816-printed, sans-serif;
      color: #ffe604;
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 10px;
      @media only screen and (max-width: 600px) {
        h2 {
          line-height: 20px;
          font-size: 14px;
        }
      }
    }

    .intro {
      font-family: ${(props) => props.theme.font};
      color: grey;
      text-align: center;
      a {
        color: white;
        font-weight: 600px;
        text-decoration: none;
      }
    }
    @media only screen and (max-width: 600px) {
      p {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }

    .date {
      font-weight: 600;
      text-transform: uppercase;
    }

    button.visit_website {
      font-weight: 500;
      padding: 10px 30px;
      margin-top: 20px;
      margin-bottom: 20px;
      background: #e5007d;
      color: #ffffff;
      border: 2px solid #ffffff;
      border-radius: 5px;
      -webkit-transition-duration: 0.4s;
      transition-duration: 0.4s;
      outline: none;
      cursor: pointer;

      :hover {
        background-color: #ffe604;
        color: #e5007d;
        border: none;
      }
    }

    .iconify {
      padding: 0 3px;
      color: white;
      width: 24px;
      height: 24px;
      transition-duration: 0.4s;
      :hover {
        color: #ffe604;
      }
      @media only screen and (max-width: 600px) {
        margin-top: 10px;
      }

      &.facebook {
        width: 21px;
        height: 23px;
      }
    }

    .modal_underline {
      border-bottom: 3px solid white;
      margin-bottom: 20px;
      font-size: 1.2em;
      @media only screen and (max-width: 600px) {
        border-bottom: 2px solid white;
        margin-bottom: 15px;
      }
    }

    .carousel-root .slide {
      & > div {
        height: 100%;
        /* @media only screen and (max-width: 600px) {
          height: 280px;
        } */

        & > img {
          height: 100%;
          object-fit: cover;
          @media only screen and (max-width: 600px) {
            height: 280px;
          }
        }
      }
    }
    .slider-wrapper {
      width: 100%;
    }

    .singleImage img {
      max-height: 340px;
      width: 100%;
      object-fit: cover;
      /* padding-top: 25px; */
      padding-bottom: 25px;

      @media only screen and (max-width: 600px) {
        height: 280px;
        padding-top: 10px;
        padding-bottom: 0px;
      }
    }
    .carousel.carousel-slider {
      height: 340px;
      @media only screen and (max-width: 600px) {
        height: 280px;
      }
    }

    .carousel {
      margin: 0px 0px 25px;

      @media only screen and (max-width: 600px) {
        margin: 0px 0px 15px;
      }
    }

    .dot {
      background-color: yellow;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 80);
    }
    .dot:hover {
      opacity: 1;
      background: ${(props) => props.theme.colours.primary};
    }
    .dot:focus {
      outline: none;
    }

    .carousel-status {
      display: none;
    }

    .thumbs-wrapper {
      display: none;
    }
  }
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #ffe604;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #ffe604;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: rgba(229, 0, 125, 0.5);
  }

  .close_button {
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 5px;

    @media only screen and (max-width: 600px) {
      top: 10px;
    }
  }

  .close_button:hover {
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -ms-transition: 0.7s;
    -o-transition: 0.7s;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: 50% 50%;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }

  .material-icons {
    background-color: transparent;
    font-size: 26px;
    color: white;
  }

  .brand {
    position: absolute;
    width: 160px;
    height: 160px;
    top: -30px;
    left: -30px;
    transform: rotateZ(-10deg);
    z-index: 20;

    @media only screen and (max-width: 600px) {
      top: -30px;
      left: -10px;
      img {
        /* width: 100px; */
        height: 100px;
      }
    }
  }

  .inner_body {
    height: 100%;
    width: 90%;
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  p.businessList {
    text-align:center;
    font-family: ${(props) => props.theme.font};
    font-size: 19px;
    line-height:23px;
  }

  .body::-webkit-scrollbar {
    width: 7px;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .body::-webkit-scrollbar-track {
    background: #e5e5df;
    border-radius: 7px;
  }
  .body::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colours.secondary};
    border-radius: 7px;
  }
`;

export default ModalStyles;
