import styled from "styled-components";

const CategorySelectorStyles = styled.div`
  margin-top: 200px;
  height: calc(100vh - 180px);
  height: 70vh;
  overflow-y: auto;
  @media screen and (max-height: 880px) and (min-width: 600px) {
    height: 66vh;
    overflow-y: scroll;
  }

  @media screen and (max-height: 745px) and (min-width: 600px) {
    height: 60vh;
    overflow-y: scroll;
  }
  @media screen and (max-height: 630px) and (min-width: 600px) {
    height: 50vh;
    overflow-y: scroll;
  }


  
  @media only screen and (max-width: 600px) {
    margin-top: 80px;
    margin-left: 10px;
    // height: 435px;

    overflow-y: scroll;
  }
   @media screen and (max-height: 736px) {
    // height: 480px;
    // height: calc(100vh - 80px);
  }

  @media screen and (max-width: 600px) and (max-height: 736px) {
    // height: 480px;
    height: calc(100vh - 180px);
  }
  @media screen and (max-height: 568px) {
    // height: 380px;
    // height: calc(100vh - 80px);
  } 

  h2 {
    font-family: ${(props) => props.theme.font};
    font-size: 16px;
    color: white;
    margin-left: 10px;
    /* text-align: center; */
  }
  button.selectall {
    margin-top: 10px;
    margin-left: 10px;
    padding: 10px;
    margin-bottom: 20px;
    background: rgba(255, 230, 4, 1);
    // background: rgba(255, 230, 4, 0.6);
    color: #e5007d;
    border: 2px solid #e5007d;
    border-radius: 5px;
    transition-duration: 0.4s;
    outline: none;
    :hover {
      background-color: white;
    }
    :focus {
      // background: rgba(255, 230, 4, 1);
    }
  }
  button.selectallOff {
    margin-top: 10px;
    margin-left: 10px;
    padding: 10px;
    margin-bottom: 20px;
    background: rgba(255, 230, 4, 0.6);
    // background: rgba(255, 230, 4, 0.6);
    color: #e5007d;
    border: 2px solid #e5007d;
    border-radius: 5px;
    transition-duration: 0.4s;
    outline: none;
    :hover {
      background-color: white;
    }
    :focus {
      // background: rgba(255, 230, 4, 1);
    }
  }
  p {
    font-family: ${(props) => props.theme.font};
    font-size: 12px;
    padding-left: 7px;
    text-transform: uppercase;
  }
  img {
    padding-left: 10px;
    padding-bottom: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px; 
}

::-webkit-scrollbar-track {
    background: #e5007d;
}

::-webkit-scrollbar-thumb {
    margin-right: 7px;
    border-radius: 7px;
    background: #ffe604;
}
`;

export default CategorySelectorStyles;
