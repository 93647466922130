import React, { useRef, useState, useEffect } from "react";
import PanZoom from "react-easy-panzoom";
import ZoomControllerUI from "./ZoomControllerUI";
import PadControllerUI from "./PadControllerUI";
import ResetControllerUI from "./ResetControllerUI";
import Header from "../Header";
import CategorySelector from "./CategorySelector";
import BIDLogo from "../../images/bid-icon-white.png";
import List from "../List";
import MapStyles from "../../styles/MapStyles"
// import RotationControllerUI from './/RotationControllerUI'

const PanZoomControlUI = (props) => {

  useEffect(() => {
    center();
    // window.addEventListener('resize', handleWindowSizeChange);
    // return () => {
    //     window.removeEventListener('resize', handleWindowSizeChange);
    // }
}, []);

  const { zoomInSpeed, zoomOutSpeed, ...rest } = props;
  const panZoom = useRef(null);

  const preventPan = (e, x, y) => {
    if (e.target.classList[0] === "mappoint") return true;
    return false;
  };

  function onZoomIn() {
    panZoom.current && panZoom.current.zoomIn(zoomInSpeed);
  }

  function onZoomOut() {
    panZoom.current && panZoom.current.zoomOut(zoomOutSpeed);
  }

  function moveByRatio(x, y) {
    panZoom.current && panZoom.current.moveByRatio(x, y);
  }

  function center() {
    panZoom.current && panZoom.current.autoCenter();
  }

  function centerMove(x,y) {

    //fully zoom out
    for (let i=0; i<=20; i++) {
      onZoomOut();
    }

    //centre on map
    center();

    //zoom in a bit to highlight selection
    for (let j=0; j<=6; j++) {
      onZoomIn()
    }
    const zoomLevel = panZoom.current.state.scale;
    const origWidth = props.width;
    const origHeight = props.height;
    const zoomedOutwidth = zoomLevel * origWidth;
    const zoomedOutHeight = zoomLevel * origHeight;
    const xRatio = zoomedOutwidth/origWidth;
    const yRatio = zoomedOutHeight/origHeight;

    const newXCenter = (window.innerWidth/2) - (x * xRatio);
    const newYCenter = (window.innerHeight/2) - (y * yRatio);

    // onZoomIn();
    // onZoomIn();
    // onZoomIn();
    setTimeout(() => {
      panZoom.current.moveBy((zoomedOutwidth/2) - (x * xRatio), (zoomedOutHeight/2) - (y * yRatio));       panZoom.current.state.x = (newXCenter);
      panZoom.current.state.y = (newYCenter);

    }, 10)  }

  function reset() {
    panZoom.current && panZoom.current.reset();
  }

  // function rotateClockwise() {
  //   panZoom.current && panZoom.current.rotate(prevAngle => prevAngle + 10)
  // }

  // function rotateCounterClockwise() {
  //   panZoom.current && panZoom.current.rotate(prevAngle => prevAngle - 10)
  // }

  return (
    <div style={{ position: "relative", height: "100%" }} className="panZoomMap">
      <PanZoom
        ref={panZoom}
        style={{ height: "100%", overflow: "hidden" }}
        minZoom={0.25}
        maxZoom={1}
        autoCenter
        preventPan={preventPan}
        realPinch={true}
      >
        {props.children}
      </PanZoom>

      <Header>
        <div style={{ position: "absolute", left: 10, top: 20, zIndex: 1 }}>
          <ZoomControllerUI onZoomIn={onZoomIn} onZoomOut={onZoomOut} />
        </div>

        <div style={{ position: "absolute", left: 10, top: 120 }}>
          <ResetControllerUI reset={reset} center={center} />
        </div>
        <CategorySelector
          categories={props.categories}
          activeCategories={props.activeCategories}
          setActiveCategories={props.setActiveCategories}
          defaults={props.defaultCats}
        />
      </Header>
      <List 
        businessList={props.businessList} 
        centerMove={centerMove} 
        setActiveCategories={props.setActiveCategories} 
        setActivePin={props.setActivePin}
        defaultCats={props.defaultCats}
      />
      {/* <div style={{ position: "absolute", left: 20, bottom: 20 }}>
        <PadControllerUI moveByRatio={moveByRatio} />
      </div> */}
      <div style={{ position: "absolute", left: 20, top: 0 }}>
      <img className="BIDLogo" src={BIDLogo} alt="BIDLogo" />
      {/* <h1>test</h1> */}
      </div>
    </div>
  );
};

export default PanZoomControlUI;
