import React, { useState, useEffect } from "react";
import PanZoom from "./panzoom-controls/Controls";
import MapStyles from "../styles/MapStyles";
import Point from "./Point";
import Modal from "./modal-controls/Modal";
import MapImage from "../images/map_reduced_colour_extended_feather_compressed.jpg";
import points from "../utils/mapPoints";
import MapContainer from "../styles/MapContainerStyles";
import List from "../components/List";
import ReactTooltip from "react-tooltip";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const allCats = points
  .map((p) => {
    if (typeof p.category !== "string" && p.category !== null) {
      return [...p.category];
    }
    return p.category;
  })
  .flat()
  .filter((p) => p !== null)
  .filter(onlyUnique);

  const listBusinesses = points
  .map((p) => {
    if (typeof p.name !== "string" && p.name !== null) {
      return [...p.name];
    }
    if (typeof p.position.x !== "number" && p.position.x !== null) {
      return [...p.position.x];
    }
    if (typeof p.position.y !== "number" && p.position.y !== null) {
      return [...p.position.y];
    }
    return {
      name: p.name,
      x: p.position.x,
      y: p.position.y,
      category: p.category,
      active: false
    };
  })
  .flat();

  let cleansedBusinesses = listBusinesses.splice(13, listBusinesses.length)
  .sort((a,b) => {
    let x = a.name.toLowerCase(),
    y = b.name.toLowerCase();

    if (x <  y) { return -1; }
    if (y <  x) { return 1; }
    return 0;
  });

const defaultSelectedCategories = [
"cafe", "lunch", "dinner", "pubs bars"
];

const Map = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContents, setModalContents] = useState({});
  const [activeCategories, setActiveCategories] = useState(defaultSelectedCategories);
  const [activePin, setActivePin] = useState();

  const mapWidth = 6620;
  const mapHeight = 8082;

  useEffect(() => {
    setModalContents({
      title: points[0].name,
      content: points[0].content,
      brand: points[0].brand,
      carouselContents: points[0].carousel,
    });
    setModalOpen(true);
  }, []);

  useEffect(() => {
    const resetPin = points.map(p => {
      p.active = false;
    })
    const pin = points.find(p => p.name.toLowerCase() === activePin);
    if (typeof pin !== 'undefined'){
      pin.active = true;
      console.log(pin)
    }
  });

  return (
    <MapContainer>
      <PanZoom
        boundaryRatioVertical={0.8}
        boundaryRatioHorizontal={0.8}
        maxZoom={2.25}
        minZoom={0.1}
        autoCenterZoomLevel={0.1}
        enableBoundingBox
        categories={allCats}
        activeCategories={activeCategories}
        setActiveCategories={setActiveCategories}
        defaultCats={defaultSelectedCategories}
        openModal={setModalOpen}
        modalContents={setModalContents}
        businessList={cleansedBusinesses}
        setActivePin={setActivePin}
        width={mapWidth} 
        height={mapHeight}
      >
        <MapStyles bgImg={MapImage}  width={mapWidth} height={mapHeight}>
          {points.map((point) => (
            <Point
              key={point.name}
              openModal={setModalOpen}
              modalContents={setModalContents}
              activeCategories={activeCategories}
              {...point}
              zIndex={point.zIndex}
            />
          ))}
        </MapStyles>
      </PanZoom>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        html={modalContents}
        modalWidth={750}
        modalHeight={500}
      />
    </MapContainer>
  );
};

export default Map;
