import React, { useEffect, useState } from "react";

import PointStyles from "../styles/PointStyles";

const Point = (props) => {
  const [display, setDisplay] = useState(false);
  const [activeIco, setActiveIco] = useState("");

  const imgUrl = require(`../images/mapIcons/${
    activeIco !== "" ? activeIco + ".svg" : props.icon
  }`);

  const pinZIndex = props.zIndex;

  let activePin = null;

  useEffect(() => {
    if (props.category === null) {
      setDisplay(true);
      return;
    }
    if (typeof props.category !== "string") {
      const checkIsInCat = props.activeCategories.filter((active) => {
        return props.category.indexOf(active) !== -1;
      });

      if (checkIsInCat.length > 0) {
        setDisplay(true);
        setActiveIco(checkIsInCat[0].replace(/ /g, "_"));
        return;
      }
    }
    if (props.activeCategories.indexOf(props.category) === -1) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [props.activeCategories, props.category]);

  const clickHandler = () => {
    props.modalContents({
      title: props.name,
      text: props.categoryname,
      content: props.content,
      brand: props.brand,
      carouselContents: props.carousel,
      button: props.link,
      website: props.website,
      socials: props.socials,
    });
    props.openModal(true);
    window.dataLayer.push({
      event: "pin_clicked",
      pinType: `${props.categoryname}`,
      pinName: `${props.name}`,
    });
  };

  return (
    <PointStyles
      stTransform={props.transform}
      stTranslate={props.position}
      shouldDisplay={display}      
      onClick={clickHandler}
      active={props.active}
      style={{zIndex: pinZIndex}}
    >
      <img
        className="mappoint"
        src={imgUrl}
        alt={props.name}
        draggable="false"
        data-tip="test"
      />
      <div></div>
    </PointStyles>
  );
};

export default Point;
