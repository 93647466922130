import styled from "styled-components";

const MapStyles = styled.div`
width: ${(props) => props.width}px;
height: ${(props) => props.height}px;
  background-image: url("${(props) => props.bgImg}");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  -webkit-user-drag: none;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

export default MapStyles;
