import React, { useEffect, useState } from "react";
import points from "../utils/mapPoints";
import ListStyles from "../styles/ListStyles";
import searchIcon from "../images/search.png";
import searchCloseIcon from "../images/search-close.png";

const List = (props) => {
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([allData]);
  const [searchField, setSearchField] = useState("");

  let busListLowerCase = [];

  props.businessList.map((bus) => {
    let busLowerCase = bus.name.toLowerCase();

    busListLowerCase.push({
      name: busLowerCase,
      x: bus.x,
      y: bus.y,
      category: bus.category,
      active: false,
    });
  });

  useEffect(() => {
    setAllData(busListLowerCase);
    setFilteredData(busListLowerCase);
  }, []);

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    setSearchField(value);
    let result = [];
    result = allData.filter((data) => {
      return data.name.search(value) != -1;
    });
    setFilteredData(result);
  };

  const location = props.centerMove;



  const list = busListLowerCase
    .map((bus) => {
      return bus.name;
    })
    .join("");

  const handleClick = (name, x, y, cat) => {
    props.setActivePin(name);
	toggleSearchPanel();
    setTimeout(() => {
      props.setActiveCategories(cat);
      props.centerMove(x, y);
    }, 10);
  };

  const handleClear = () => {
    props.setActivePin(null);
    setTimeout(() => {
      props.setActiveCategories(props.defaultCats);
    }, 10);
  };

  const toggleSearchPanel = () => {
	const openButton = document.getElementById("searchOpen");
	console.log(openButton);
	openButton.parentNode.classList.toggle("closed");

  }

  return (
    <ListStyles className="closed" id="searchPanel">
      <div className="search">
        <label htmlFor="search">Search:</label>
        <div
          className="clear-button"
          onClick={() => {
            handleClear();
            setFilteredData(allData);
            setSearchField("");
          }}
        >
          Clear
        </div>
      </div>
      <div className="search-bar">
        <input
          type="text"
          id="search"
          value={searchField}
          onChange={(event) => handleSearch(event)}

        />
      </div>

	  <div className="filtered-list">
        {filteredData.map((value, index) => {
          return (
            <div key={index}>
              <div className="business-name">
                <span
                  
                  onClick={() =>
                    handleClick(value.name, value.x, value.y, value.category)
                  }
                >
                  {value.name}
                </span>
              </div>
            </div>
          );
        })}
      </div>
	  <div className="search-tab-open" id="searchOpen" onClick={toggleSearchPanel}>
        <div>Search <img className="search-icon" src={searchIcon} alt="Search" /></div>
      </div>
	  <div className="search-tab-close" id="searchclose" onClick={toggleSearchPanel}>
        <div><img className="search-close-icon" src={searchCloseIcon} alt="Search" /></div>
      </div>
    </ListStyles>
  );
};

export default List;