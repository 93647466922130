import React, { useState } from "react";
import HeaderStyles from "../styles/HeaderStyles";
import Logo from "../images/logo.png";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleHamburgerInteract = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderStyles isHamburgerOpen={isOpen}>
      <img className="logo" src={Logo} alt="logo" />
      <button className="mobileMenu" onClick={handleHamburgerInteract}>
        {isOpen ? (
          <span class="material-icons">close</span>
        ) : (
          <span className="material-icons">menu</span>
        )}
      </button>
      <div>{props.children}</div>
    </HeaderStyles>
  );
};

export default Header;
