import React, { useEffect } from "react";
import Map from "./components/Map";
import PageStyles from "./styles/PageStyles";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import GlobalStyle from "./styles/GlobalStyles";

function App() {
  useEffect(() => {
    const modal = document.getElementsByClassName('ReactModalPortal');
    const map = document.getElementsByClassName('panZoomMap');
    const filter = document.getElementById('cat-filter');
    const search = document.getElementById('searchPanel');
    console.log(modal)

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      map[0].addEventListener(
        "touchmove",
        ev => {
          ev.preventDefault();
        },
        { passive: false }
      );
    }

    document.addEventListener(
      "touchmove",
      function (event) {
        if (event.scale !== 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );

    modal[0].addEventListener(
      "touchmove",
      function (event) {
        // if (event.scale !== 1) {
        //   event.preventDefault();
        // }
        event.stopPropagation();
      }
    );
    filter.addEventListener(
      "touchmove",
      function (event) {
        // if (event.scale !== 1) {
        //   event.preventDefault();
        // }
        event.stopPropagation();
      }
    );

    search.addEventListener(
      "touchmove",
      function (event) {
        // if (event.scale !== 1) {
        //   event.preventDefault();
        // }
        event.stopPropagation();
      }
    );
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <PageStyles>
        <Map />
      </PageStyles>
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
