import styled from "styled-components";

const PointStyles = styled.div`
  position: absolute;
  top: ${(props) => props.stTranslate.y}px;
  left: ${(props) => props.stTranslate.x}px;
  width: ${(props) => props.stTransform.width}px;
  height: ${(props) => props.stTransform.height}px;
  display: ${(props) => (props.shouldDisplay ? "block" : "none")};
  transform-origin: center;
  transform: scale(0.8);
  z-index: ${(props) => (props.active ? "10" : "0")};

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.4s;
    border: ${(props) => (props.active ? "4px solid #e5007d" : "none")};
    position:absolute;
    top: ${(props) => (props.active ? "-4px" : "0")};
    left: ${(props) => (props.active ? "-4px" : "0")};
    border-radius: 15px;
    transform: ${(props) => (props.active ? "scale(1.4)" : "scale(1)")};
  }

  img:hover {
    transform: ${(props) => (props.active ? "scale(1.7)" : "scale(1.3)")};
    transition: transform 0.6s;
    cursor: pointer;
  }

  div {
    position: relative;
  }
`;

export default PointStyles;
