import * as React from "react";
import Button from "./Button";
import theme from "../../styles/theme";

const ZoomControllerUI = ({ onZoomIn, onZoomOut }) => {
  return (
    <div
      className="zoomButtons"
      style={{
        borderRadius: 4,
        overflow: "hidden",
        backgroundColor: "#FFFFFF",
        color: "#E5007D",
      }}
    >
      <Button
        className="zoomButton"
        onClick={onZoomIn}
        style={{
          transition: ".4s",
          // border: `1px solid ${theme.colours.primary}`,
        }}
      >
        <i className="material-icons">add</i>
      </Button>

      <Button
        className="zoomButton"
        onClick={onZoomOut}
        style={{
          transition: ".4s",
          // border: `1px solid ${theme.colours.primary}`,
        }}
      >
        <i className="material-icons">remove</i>
      </Button>
    </div>
  );
};

export default ZoomControllerUI;
