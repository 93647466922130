import styled from 'styled-components';

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overscroll-behavior: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #bababa;
`

;

export default MapContainer;